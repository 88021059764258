import React from 'react'
import './TextareaInput.scss'


function TextareaInput({
  label,
  value,
  placeholder,
  error,
  onChange,
  maxCharacters
}) {

  return (
    <div className="textarea-input">
      <div className="input-label">{label}</div>
      <textarea
        className={error ? 'red-border': ''}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={e => {
          if (maxCharacters) {
            e.target.value.length <= maxCharacters && onChange(e)
          } else {
            onChange(e)
          }
        }}
      />
      <div className="bottom-area">
        <div className="input-error">{error}</div>
        { maxCharacters && <div className="textarea-counter">{value.length}/{maxCharacters}</div> }
      </div>
    </div>
  )
}

export default TextareaInput