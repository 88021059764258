import React from 'react'
import './DiscountInput.scss'
import { useTranslation } from 'react-i18next'


function DiscountInput({
  value,
  placeholder,
  error,
  disabled,
  onChange,
  onButtonClick,
}) {
  const { t } = useTranslation()

  return (
    <div className={`discount-input ${disabled ? 'input-disabled' : ''}`}>
      <input
        className={error ? 'red-border': ''}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        maxLength={16}
      />
      <button className="input-button" onClick={onButtonClick}>{t('cartPage.couponButton')}</button>
      <div className="input-error">{error}</div>
    </div>
  )
}

export default DiscountInput