import React, { useState, useEffect } from 'react'
import './ShopNavbar.scss'
import starsBackground from '../../../../assets/coupon_stars.svg'
import hamburgerIcon from '../../../../assets/hamburger_icon.svg'
import closeMenuIcon from '../../../../assets/close_menu_icon.svg'
import { Link, withRouter } from 'react-router-dom'
import PageVersionSelect from '../../../commons/PageVersionSelect/PageVersionSelect'
import CartButton from './CartButton/CartButton'
import ProfileMenu from './ProfileMenu/ProfileMenu'
import { default as axios } from '../../../../axiosSettings'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


function ShopNavbar(props) {
  const [isError404Page, setIsError404Page] = useState(false)
  const [wwwCoupon, setWwwCoupon] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    axios.get(`api/coupons?couponType=1`)
      .then(response => {
        const wwwCoupons = response.data['hydra:member']
        setWwwCoupon(wwwCoupons[0])
      })
  }, [])

  useEffect(() => {
    setIsError404Page(document.querySelector('.error-404-page'))
  }, [props.location])

  useEffect(() => {
    setIsAdmin(window.location.pathname.includes('admin'))
  }, [props.location])

  const NavbarLink = ({ to, text, underline }) => {
    const isActive = underline && (window.location.pathname===to || (window.location.pathname.startsWith(to) && to!=="/"));
    return (
      <Link
        to={to}
        className={`medium-and-big-screens ${isActive ? 'link-active' : ''}`}>
        {text}
      </Link>
    )
  }

  const formatCouponValue = (value, isPercent) => {
    if (value % 100 === 0) return value/100
    else if (value % 10 === 0 && isPercent) return (parseFloat(value)/100).toFixed(1)
    else return (parseFloat(value)/100).toFixed(2)
  }

  const renderCouponBar = () => {
    const now = moment().format('YYYY-MM-DD')
    const validFrom = moment(wwwCoupon.validFrom || -8640000000000000).format('YYYY-MM-DD')
    const validTo = moment(wwwCoupon.validTo || 8640000000000000).format('YYYY-MM-DD')
    if (now < validFrom || now > validTo) return

    return <div className="coupon-bar">
      <div className="coupon-background" style={{backgroundImage: `url(${starsBackground})`}} />
      <span className="coupon-value">
        { wwwCoupon.valuePercent ? formatCouponValue(wwwCoupon.valuePercent, true) + '%' : '' }
        { !wwwCoupon.valuePercent && i18n.language === 'pl' ? formatCouponValue(wwwCoupon.valuePln, false) + 'zł' : '' } 
        { !wwwCoupon.valuePercent && i18n.language !== 'pl' ? formatCouponValue(wwwCoupon.valueEur, false) + '€' : '' } 
      </span>
      { i18n.language === 'pl' && <span className="coupon-banner-text">{wwwCoupon.bannerTextPl}</span> }
      { i18n.language === 'es' && <span className="coupon-banner-text">{wwwCoupon.bannerTextEs}</span> }
      { i18n.language === 'en' && <span className="coupon-banner-text">{wwwCoupon.bannerTextEn}</span> }
      { i18n.language === 'pl' && <span className="coupon-text">{wwwCoupon.textPl}</span> }
      { i18n.language === 'es' && <span className="coupon-text">{wwwCoupon.textEs}</span> }
      { i18n.language === 'en' && <span className="coupon-text">{wwwCoupon.textEn}</span> }
    </div>
  }

  if (isError404Page) return null
  if (isAdmin) return null

  const renderNavbarContent = () => <div className="navbar-inner">
    <div className="left">
      <div className="menu-icon-wrapper">
        <img src={isMenuOpen ? closeMenuIcon : hamburgerIcon} alt="" className="menu-icon small-screens" onClick={() => setIsMenuOpen(!isMenuOpen)} />
      </div>
      {/*<Link to="/"><img src={edibaLogo} alt="ediba logo" className="company-logo" onClick={() => setIsMenuOpen(false)} /></Link>*/}
      <Link to="/"><div className="edibaLogo" onClick={() => setIsMenuOpen(false)} /></Link>
      <NavbarLink to="/" text={t('shopNavbar.personalizedBooks')} underline />
      <NavbarLink to="/shop" text={t('shopNavbar.standardBooks')} underline />
      <NavbarLink to="/contact" text={t('shopNavbar.contact')} underline />
    </div>
    <div className="right">
      {/*<div className="language-select-wrapper">*/}
      {/*  <PageVersionSelect closeMenuHandler={() => setIsMenuOpen(false)} />*/}
      {/*</div>*/}
      <div className="icon-wrapper">
        <ProfileMenu closeMenuHandler={() => setIsMenuOpen(false)} />
      </div>
      <div className="icon-wrapper">
        <CartButton closeMenuHandler={() => setIsMenuOpen(false)} />
      </div>
    </div>
  </div>

  return (
    <div className="shop-navbar">
      { wwwCoupon && renderCouponBar() }
      { renderNavbarContent () }

      { isMenuOpen && <div className="mobile-menu">
        <div className="shadow-wrapper">
          { renderNavbarContent () }
        </div>
        <div className="links">
          <Link to='/' onClick={() => setIsMenuOpen(false)}>{t('shopNavbar.personalizedBooks')}</Link>
          <Link to='/shop' onClick={() => setIsMenuOpen(false)}>{t('shopNavbar.standardBooks')}</Link>
          <Link to='/faq' onClick={() => setIsMenuOpen(false)}>{t('shopNavbar.faq')}</Link>
          <Link to='contact/' onClick={() => setIsMenuOpen(false)}>{t('shopNavbar.contact')}</Link>
          <Link to='/' onClick={() => setIsMenuOpen(false)}>{t('shopNavbar.privacyPolicy')}</Link>
        </div>
      </div> }
    </div>
  )
}

export default withRouter(ShopNavbar)
