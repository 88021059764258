import React from 'react'
import './SaveDraftModal.scss'
import closeIcon from '../../../../../../assets/close_icon.svg'
import { useStateValue } from '../../../../../../state/state'
import { default as axios } from '../../../../../../axiosSettings'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../../helper/storage/Storage";


function SaveDraftModal(props) {
  const { closeModalHandler } = props
  const [{ bookData }, dispatch] = useStateValue()
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const handleSaveDraft = () => {
    const requestBody = {
      title: bookData.title,
      description: bookData.description,
      isbn: bookData.isbn,
      status: 0,
      isCustomizable: true,
      bookMainImage: '/api/book_main_images/' + bookData.mainImage.id,
      coverFont: bookData.converFont,
      innerFont: bookData.innerFont,
      printingHouse: '/api/users/' + bookData.printingHouse,
      market: '/api/markets/' + bookData.market,
      pages: bookData.pages.map(page => { 
        const pageData = {
          number: page.number,
          type: page.type,
          clientPhotoPosition: page.clientPhotoPosition || 0,
          textPosition: page.textPosition || 0,
          boysText: page.boysText || '',
          girlsText: page.girlsText || '',
          pageBackgroundImage: page.uploadedImage ? '/api/page_background_images/' + page.uploadedImage.id : null
        }
        if (bookData.savedPages.find(savedPage => savedPage.number === page.number)) {
          pageData.id = `/api/pages/${bookData.savedPages.find(savedPage => savedPage.number === page.number).id}`
        }
        return pageData
      }),
    }

    if (bookData.bookId) {
      axios.patch(`api/books/${bookData.bookId}`, requestBody, { 
        headers: { 
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        }
      })
        .then(() => {
          closeModalHandler()
        })
    } else {
      axios.post(`api/books`, requestBody, { 
        headers: { 
          Authorization: `bearer ${token}`,
        } 
      })
        .then(response => {
          dispatch({ type: 'setBookData', field: 'bookId', data: response.data.id })
          dispatch({ type: 'setBookData', field: 'savedPages', data: response.data.pages })
          closeModalHandler()
          props.history.push('/admin/products')
        })
    }
  }
 
  return (
    <div className="save-draft-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('pagesEditor.saveModalTitle')}</h2>

        <p className="text mt24">{t('pagesEditor.saveModalText1')} <strong>{t('pagesEditor.saveModalText2')}</strong></p>

        <div className="button-wrapper mt64">
          <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('pagesEditor.saveModalContinueButton')}</button>
          <button className="button auto-width" onClick={handleSaveDraft}>{t('pagesEditor.saveModalSaveButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(SaveDraftModal)
