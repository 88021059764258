import React from 'react'
import './CartButton.scss'
import { Link } from 'react-router-dom'
import shoppingCartIcon from '../../../../../assets/shopping_cart_icon.svg'
import trashIconRed from '../../../../../assets/trash_icon_red.svg'
import { useStateValue } from '../../../../../state/state'
import { serverURL } from '../../../../../config'
import { useTranslation } from 'react-i18next'
 

function CartButton(props) {
  const { closeMenuHandler } = props
  const [{ cart }, dispatch] = useStateValue()
  const { i18n, t } = useTranslation()

  const getTotalPrice = () => {
    let totalPrice = 0
    cart.forEach(cartItem => {
      totalPrice += cartItem.count * cartItem.productData.price
    })
    return `${(parseFloat(totalPrice)/100).toFixed(2)} ${i18n.language === 'pl' ? 'zł' : '€'}`
  }

  const getItemNumbers = () => {
    let itemsCount = 0
    cart.forEach(cartItem => {
      itemsCount += cartItem.count
    })
    return itemsCount
  }

  const removeCartItem = (index) => {
    const newCart = cart.filter((item, i) => i !== index)
    dispatch({ type: 'setData', field: 'cart', data: newCart })
    sessionStorage.setItem('cart', JSON.stringify(newCart))
  }

  const renderCartItems = () => <div className="cart-preview">
    <div className="cart-items">
      {
        cart.map((item, i) => <div key={i} className="cart-item">
          { item.productData.bookMainImage && <img src={serverURL + item.productData.bookMainImage.filePath} alt="cover" /> }
          <div className="cart-item-data">
            <div>
              <div className="cart-item-title">{item.productData.title}</div>
              <div className="cart-item-type">{item.productData.isCustomizable ? t('shopNavbar.cartItemPersonalized') : t('shopNavbar.cartItemStandard')}</div>
            </div>
            <div className="cart-item-remove-button">
              <img src={trashIconRed} alt="remove item" />
              <span onClick={() => removeCartItem(i)}>{t('shopNavbar.cartItemRemoveButton')}</span>
            </div>
          </div>
        </div>)
      }
    </div>
    <div className="price-line">
      <div>{t('shopNavbar.cartTotalPrice')}</div>
      <div>{getTotalPrice()}</div>
    </div>
    <Link to="/cart" className="button" onClick={closeMenuHandler}>{t('shopNavbar.cart')}</Link>
  </div>

  const renderEmptyCartInfo = () => <div className="no-items">
    <div className="no-items-title">{t('shopNavbar.emptyCartHeader')}</div>
    <div className="no-items-text">{t('shopNavbar.emptyCartText')}</div>
  </div>

  return (
    <div className="cart-button">
      <div className="link-with-image">
        <img src={shoppingCartIcon} alt="shopping cart" />
        <span className="big-screens">{t('shopNavbar.cart')}</span>
        { cart.length ? <div className="cart-counter">{getItemNumbers()}</div> : null }
        <div className="hover-box">
          { cart.length ? renderCartItems() : renderEmptyCartInfo() }
        </div>
      </div>
    </div>
  )
}

export default CartButton
