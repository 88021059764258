import React, {useEffect, useState} from 'react'
import './ContactPage.scss'
import TextInput from '../../../commons/TextInput/TextInput'
import TextareaInput from '../../../commons/TextareaInput/TextareaInput'
import Recaptcha from 'react-recaptcha'
import { validateEmail, onloadCallback } from '../../../../utils'
import contactImage from '../../../../assets/contact_image.webp'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import { CAPTCHA_KEY } from '../../../../config'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";
import {notifyGTMPageOpened} from "../../../../helper/tracking/notifyGTMPageOpened";


function ContactPage() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [reCaptcha, setReCaptcha] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [reCaptchaError, setReCaptchaError] = useState(false)
  const [isMessageSend, setIsMessageSend] = useState(false)
  const token = Storage.get(TOKEN);
  const { t, i18n } = useTranslation()

  const sendMessage = () => {
    const nameError = !name
    const emailError = !validateEmail(email)
    const messageError = !message
    const reCaptchaError = !reCaptcha

    if (
      !nameError
      && !emailError
      && !messageError
      && !reCaptchaError
    ) {
      axios.post(`api/contact`, {
        fullName: name,
        email: email,
        message: message,
      }, { 
        headers: { 
          Authorization: `bearer ${token}`,
        } 
      })
        .then(() => {
          setIsMessageSend(true)
          setName('')
          setEmail('')
          setMessage('')
          setInterval(() => {
            setIsMessageSend(false)
          }, 5000)
        })
    }

    setNameError(nameError)
    setEmailError(emailError)
    setMessageError(messageError)
    setReCaptchaError(reCaptchaError)
  }

  useEffect(()=>{
    notifyGTMPageOpened(i18n.language);
  }, []);

  return (
    <div className="contact-page">

      <MetaTags title="Edikids - kontakt" />

      <div className="content-wrapper-inner">
        <div className="columns">
          <div className="left">
            <h2>{t('contactPage.formHeader')}</h2>
            <div className="input-wrapper mt32 w360">
              <TextInput
                label={t('contactPage.nameLabel')}
                placeholder={t('contactPage.namePlaceholder')}
                value={name}
                error={nameError && t('contactPage.nameValidationMessage')}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="input-wrapper mt24 w360">
              <TextInput
                label={t('contactPage.emailLabel')}
                placeholder={t('contactPage.emailPlaceholder')}
                value={email}
                error={emailError && t('contactPage.emailValidationMessage')}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="input-wrapper mt24">
              <TextareaInput 
                label={t('contactPage.messageLabel')}
                placeholder={t('contactPage.messagePlaceholder')}
                value={message}
                error={messageError && t('contactPage.messageValidationMessage')}
                onChange={e => setMessage(e.target.value)}
              />
            </div>

            <div className="captcha-wrapper mt24">
              <Recaptcha
                sitekey={CAPTCHA_KEY}
                render="explicit"
                verifyCallback={() => setReCaptcha(true)}
                onloadCallback={onloadCallback}
              />
              <div className="input-error">{reCaptchaError && t('contactPage.catpchaValidationMessage')}</div>
            </div>

            <div className="button-wrapper mt48">
              <button className={`button rounded ${isMessageSend ? 'button-success' : ''}`} onClick={sendMessage}>
                { isMessageSend ? t('contactPage.successButtonInfo') : t('contactPage.button') }
              </button>
            </div>
          </div>

          <div className="right">
            <img src={contactImage} alt="contact" />
            <div className="contact-info">
              <h2>{t('contactPage.ourDataHeader')}</h2>
              <p className="mt24">
                {t('contactPage.emailLine')}<br/>
                {t('contactPage.telefonLine')}
              </p>
              <p className="mt24">
                {t('contactPage.contactTextPart1')}<strong>{t('contactPage.contactTextPart2')}</strong>{t('contactPage.contactTextPart3')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactPage
