import React, { useState, useEffect } from 'react'
import './ClientOrderDetailsPage.scss'
import { default as axios } from '../../../../axiosSettings'
import { withRouter } from 'react-router-dom'
import Breadcrumps from '../../../commons/Breadcrumps/Breadcrumps'
import moment from 'moment'
import { serverURL } from '../../../../config'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";


const currencyMap = {
  0: '%',
  1: 'zł',
  2: '€',
}


function ClientOrderDetailsPage(props) {
  const [orderData, setOrderData] = useState({})
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const gendersMap = {
    0: t('clientOrderDetailsPage.boy'),
    1: t('clientOrderDetailsPage.girl'),
  }
    
  const statusMap = {
    0: t('clientOrderDetailsPage.status0'),
    1: t('clientOrderDetailsPage.status1'),
    2: t('clientOrderDetailsPage.status2'),
    3: t('clientOrderDetailsPage.status3'),
    4: t('clientOrderDetailsPage.status4'),
    5: t('clientOrderDetailsPage.status5'),
  }

  const countriesMap = {
    'AT': t('countries.AT'),
    'BE': t('countries.BE'),
    'BG': t('countries.BG'),
    'HR': t('countries.HR'),
    'CZ': t('countries.CZ'),
    'DK': t('countries.DK'),
    'EE': t('countries.EE'),
    'FI': t('countries.FI'),
    'FR': t('countries.FR'),
    'GR': t('countries.GR'),
    'ES': t('countries.ES'),
    'NL': t('countries.NL'),
    'IE': t('countries.IE'),
    'LT': t('countries.LT'),
    'LU': t('countries.LU'),
    'LV': t('countries.LV'),
    'MC': t('countries.MC'),
    'DE': t('countries.DE'),
    'PL': t('countries.PL'),
    'PT': t('countries.PT'),
    'RO': t('countries.RO'),
    'SK': t('countries.SK'),
    'SE': t('countries.SE'),
    'HU': t('countries.HU'),
    'GB': t('countries.GB'),
    'IT': t('countries.IT'),
  }

  useEffect(() => {
    axios.get(`api/orders/${props.match.params.id}`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setOrderData(response.data)
      })
  }, [])

  const renderStandardProducts = () => {
    const subOrders = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 0)
    if (!subOrders || !subOrders.length) return

    return <div className="standard-products">
      { 
        subOrders[0].orderPositions.map((item, i) => <div key={i} className="standard-item-box">
          <div>
            <img src={serverURL + item.book.bookMainImage.filePath} alt="cover" />
            <div className="product-data">
              <div>
                <div className="product-title">{item.book.title}</div>
                <div className="product-type">{t('clientOrderDetailsPage.standardProductType')}</div>
              </div>
            </div>
            <div className="product-status medium-and-big-screens">{statusMap[subOrders[0].status]}</div>
            <div className="product-price medium-and-big-screens">{!!item.book.price && `${(parseFloat(item.book.price)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            <div className="items-counter-wrapper medium-and-big-screens">
              {item.amount} {t('clientOrderDetailsPage.count')}
            </div>
            <div className="total-product-price medium-and-big-screens">{!!item.book.price && `${(parseFloat(item.book.price * item.amount)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
          </div>
          <div className="item-data-mobile small-screens">
            <div className="item-data-mobile-status">{statusMap[subOrders[0].status]}</div>
            <div className="item-data-mobile-wrapper">
              <div>{!!item.book.price && `${(parseFloat(item.book.price)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
              <div>{item.amount} {t('clientOrderDetailsPage.count')}</div>
              <div>{!!item.book.price && `${(parseFloat(item.book.price * item.amount)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            </div>
          </div>
        </div>)
      }
    </div>
  }

  const renderPersonalizedProducts = () => {
    const subOrders = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 1)
    if (!subOrders || !subOrders.length) return

    return <div className="personalized-products">
      { 
        subOrders[0].orderPositions.map((item, i) => <div key={i} className="personalized-item-box">
          <div className="box-columns">
            <img src={serverURL + item.book.bookMainImage.filePath} alt="cover" />
            <div className="product-data">
              <div>
                <div className="product-title">{item.book.title}</div>
                <div className="product-type">{t('clientOrderDetailsPage.personalizedProductType')}</div>
              </div>
            </div>
            <div className="product-status medium-and-big-screens">{statusMap[subOrders[0].status]}</div>
            <div className="product-price medium-and-big-screens">{!!item.book.price && `${(parseFloat(item.book.price)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            <div className="items-counter-wrapper medium-and-big-screens">
              {item.amount} {t('clientOrderDetailsPage.count')}
            </div>
            <div className="total-product-price medium-and-big-screens">{!!item.book.price && `${(parseFloat(item.book.price * item.amount)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
          </div>
          <div className="personalization-details">
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('clientOrderDetailsPage.name')}</span>
              <span className="personalization-details-line-text">{item.childName}</span>
            </div>
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('clientOrderDetailsPage.gender')}</span>
              <span className="personalization-details-line-text">{gendersMap[item.childGender]}</span>
            </div>
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('clientOrderDetailsPage.dedication')}</span>
              <span className="personalization-details-line-text">{item.dedication}</span>
            </div>
          </div>
          <div className="item-data-mobile small-screens">
            <div className="item-data-mobile-status">{statusMap[subOrders[0].status]}</div>
            <div className="item-data-mobile-wrapper">
              <div>{!!item.book.price && `${(parseFloat(item.book.price)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
              <div>{item.amount} {t('clientOrderDetailsPage.count')}</div>
              <div>{!!item.book.price && `${(parseFloat(item.book.price * item.amount)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            </div>
          </div>
        </div>)
      }
    </div>
  }

  return (
    <div className="client-order-details-page">

      <MetaTags title="Edikids - szczegóły zamówienia" />

      <div className="page-content-wrapper">
        <Breadcrumps itemName={`${t('clientOrderDetailsPage.order')} #${orderData.id}`} />
        <h2>{`${t('clientOrderDetailsPage.order')} #${orderData.id}`}</h2>
        <div className="columns">
          <div className="left">
            <div className="data-section">
              <div className="data-section-label">{t('clientOrderDetailsPage.date')}</div>
              <div className="data-section-value">{moment(orderData.createdAt).format("DD.MM.YYYY")}</div>
            </div>
            <div className="data-section">
              <div className="data-section-label">{t('clientOrderDetailsPage.shippingAddress')}</div>
              <div className="data-section-value">
                {orderData.shipping && orderData.shipping.fullName} <br />
                {orderData.shipping && orderData.shipping.companyName && <>{orderData.shipping.companyName} <br /></>}
                {orderData.shipping && orderData.shipping.email} <br />
                {orderData.shipping && orderData.shipping.address1} <br />
                {orderData.shipping && orderData.shipping.address2 && <>{orderData.shipping.address2} <br /></>}
                {orderData.shipping && `${orderData.shipping.zipCode} ${orderData.shipping.city}`} <br />
                {orderData.invoice && `${orderData.shipping.state} ${countriesMap[orderData.shipping.country]}`} <br />
                {orderData.shipping && orderData.shipping.phone}
              </div>
            </div>
            <div className="data-section">
              <div className="data-section-label">{t('clientOrderDetailsPage.invoiceAddress')}</div>
              <div className="data-section-value">
                {orderData.invoice && orderData.invoice.fullName} <br />
                {orderData.invoice && orderData.invoice.type === 1 && <>{orderData.invoice.companyName} <br /></>}
                {orderData.invoice && orderData.invoice.type === 1 && <>{orderData.invoice.taxNumber} <br /></>}
                {orderData.invoice && orderData.invoice.address1} <br />
                {orderData.invoice && orderData.invoice.address2 && <>{orderData.invoice.address2} <br /></>}
                {orderData.invoice && `${orderData.invoice.zipCode} ${orderData.invoice.city}`} <br />
                {orderData.invoice && `${orderData.invoice.state} ${countriesMap[orderData.invoice.country]}`}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="products-headers medium-and-big-screens">
              <div className="products-header-cell">{t('clientOrderDetailsPage.productColumn')}</div>
              <div className="products-header-cell" />
              <div className="products-header-cell">{t('clientOrderDetailsPage.statusColumn')}</div>
              <div className="products-header-cell">{t('clientOrderDetailsPage.priceColumn')}</div>
              <div className="products-header-cell">{t('clientOrderDetailsPage.countColumn')}</div>
              <div className="products-header-cell">{t('clientOrderDetailsPage.totalValueColumn')}</div>
            </div>
            { renderStandardProducts() }
            { renderPersonalizedProducts() }
            <div className="order-summary">
              <div className="order-summary-data">
                <div className="summary-data-line">
                  <div className="summary-data-line-label">{t('clientOrderDetailsPage.productsValue')}</div>
                  <div className="summary-data-line-value">{!!orderData.price && `${(parseFloat(orderData.price)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
                </div>
                <div className="summary-data-line">
                  <div className="summary-data-line-label">{t('clientOrderDetailsPage.shippingCost')}</div>
                  <div className="summary-data-line-value">{(orderData.shippingCost/100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
                </div>
                { orderData.couponValue && <div className="summary-data-line">
                  <div className="summary-data-line-label">{t('clientOrderDetailsPage.coupon')}</div>
                  <div className="summary-data-line-value green-text">-{(parseFloat(orderData.couponValue)/100).toFixed(2)} {currencyMap[orderData.couponType]}</div>
                </div> }
                <div className="summary-data-line">
                  <div className="summary-data-line-label bold-text">{t('clientOrderDetailsPage.paymentAmount')}</div>
                  <div className="summary-data-line-value bold-text">{!!orderData.totalCost && `${(parseFloat(orderData.totalCost)/100).toFixed(2)}`} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ClientOrderDetailsPage)
