import React from 'react'
import './PriceInput.scss'


function PriceInput({
  label,
  value,
  error,
  placeholder,
  currency,
  onChange,
}) {

  return (
    <div className="price-input">
      <div className="input-label">{label}</div>
      <div className="relative-wrapper">
        <input
          className={error ? 'red-border': ''}
          type="number"
          value={value}
          placeholder={placeholder}
          onChange={e => {
            if (e.target.value.length <= 10) {
               onChange(e)
            }
          }}
        />
        <div className="currency">{currency}</div>
      </div>
      <div className="input-error">{error}</div>
    </div>
  )
}

export default PriceInput