import React from 'react'
import './ProfileMenu.scss'
import { Link, withRouter } from 'react-router-dom'
import { useStateValue } from '../../../../../state/state'
import userIcon from '../../../../../assets/user_navbar_icon.svg'
import clientLogoutIcon from '../../../../../assets/client_logout_icon.svg'
import { useTranslation } from 'react-i18next'
import {CLIENT_ID, REFRESH_TOKEN, Storage, TOKEN} from "../../../../../helper/storage/Storage";
 

function ProfileMenu(props) {
  const { closeMenuHandler } = props
  const [{ clientData }, dispatch] = useStateValue()
  const { t } = useTranslation()

  const handleLogout = () => {
    Storage.remove(CLIENT_ID);
    Storage.remove(TOKEN);
    Storage.remove(REFRESH_TOKEN);
    dispatch({ type: 'setData', field: 'isClientLoggedIn', data: false })
    dispatch({ type: 'setData', field: 'clientData', data: {} })
    props.history.push('/')
    closeMenuHandler()
  }

  const renderProfileMenu = () => <div className="link-with-image menu">
    <img src={userIcon} alt="user" />
    <span className="big-screens">{t('shopNavbar.myAccount')}</span>
    <div className="menu-box">
      <Link className="menu-position" to="/orders" onClick={closeMenuHandler}>{t('shopNavbar.myOrders')}</Link>
      <Link className="menu-position" to="/my-profile" onClick={closeMenuHandler}>{t('shopNavbar.myProfile')}</Link>
      <Link className="menu-position" to="/address-data" onClick={closeMenuHandler}>{t('shopNavbar.myAddress')}</Link>
      <div className="menu-position" onClick={handleLogout}>
        <img src={clientLogoutIcon} alt="user" />
        <span>{t('shopNavbar.logout')}</span>
      </div>
    </div>
  </div>

  const renderLoginButton = () => <Link className={`link-with-image menu ${window.location.pathname === '/login' ? 'link-active' : ''}`} to="/login">
    <img src={userIcon} alt="user" />
    <span className="big-screens">{t('shopNavbar.login')}</span>
  </Link>

  return (
    <div className="profile-menu">
      { clientData.id ? renderProfileMenu() : renderLoginButton() }
    </div>
  )
}

export default withRouter(ProfileMenu)
