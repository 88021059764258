import React from 'react'
import './RadioButtonsGroup.scss'


function RadioButtonsGroup({
  label,
  name,
  value,
  options,
  verticalOptions,
  error,
  onChange,
}) {

  return (
    <div className="radio-buttons-group">
      <div className="input-label">{label}</div>
      {
        options.map((option, i) => <label key={i} className={`container ${verticalOptions ? 'input-vertical' : ''}`} onClick={() => onChange(option.value)}>
          {option.label}
          <input type="radio" checked={option.value === value} name={name} onChange={() => null} />
          <span className="checkmark"></span>
        </label>)
      }
      <div className="input-error">{error}</div>
    </div>
  )
}

export default RadioButtonsGroup