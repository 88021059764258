import React from 'react'
import './ItemsCounter.scss'


function ItemsCounter({
  label,
  value,
  setValue,
}) {

  return (
    <div className="items-counter-component">
      { label && <div className="counter-label">{label}</div> }
      <div className="counter-wrapper">
        <div className="counter-button counter-button-left" onClick={() => value > 1 && setValue(value - 1)}>-</div>
        <div className="counter-value">{value}</div>
        <div className="counter-button counter-button-right" onClick={() => setValue(value + 1)}>+</div>
      </div>
    </div>
  )
}

export default ItemsCounter
