import React, { useState, useEffect } from 'react'
import './ProductsPage.scss'
import {Link} from 'react-router-dom'
import SearchBox from '../../../commons/SearchBox/SearchBox'
import LabeledSelect from '../../../commons/LabeledSelect/LabeledSelect'
import { ITEMS_PER_PAGE } from '../../../../consts'
import Pagination from '../../../commons/Pagination/Pagination'
import SortableHeader from '../../../commons/SortableHeader/SortableHeader'
import { default as axios } from '../../../../axiosSettings'
import { useStateValue } from '../../../../state/state'
import { serverURL } from '../../../../config'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import useUrlParameters from "../../../../helper/location/useUrlParameter";


function ProductsPage() {
  const [{ marketOptions }] = useStateValue()
  const [booksData, setBooksData] = useState([])
  // const [searchQuery, setSearchQuery] = useState('')
  // const [status, setStatus] = useState('all')
  // const [type, setType] = useState('all')
  // const [market, setMarket] = useState('all')
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const [ getUrlParameter, setUrlParameter, setUrlParameters ] = useUrlParameters();

  const currentPageNumber = getUrlParameter('page', 1, true);
  function setCurrentPageNumber(val) {
    setUrlParameter('page', parseInt(val), 1)
  }

  const sortBy = getUrlParameter('sortBy', 'title');
  function setSortBy(val) {
    setUrlParameters([['sortBy', val, 'title'], ['page',1,1]])
  }

  const sortDirection = getUrlParameter('sortDir', 'asc');
  function setSortDirection(val) {
    setUrlParameters([['sortDir', val, 'asc'], ['page',1,1]])
  }

  const searchQuery = getUrlParameter('search', '');
  function setSearchQuery(val) {
    setUrlParameters([['search', val, ''], ['page',1,1]])
  }

  const status = getUrlParameter('status', 'all') !== 'all' ? parseInt(getUrlParameter('status', 'all')) : getUrlParameter('status', 'all');
  function setStatus(val) {
    setUrlParameters([['status', val, 'all'], ['page',1,1]])
  }

  const type = getUrlParameter('type', 'all');
  function setType(val) {
    setUrlParameters([['type', val, 'all'], ['page',1,1]])
  }

  const market = getUrlParameter('market', 'all');
  function setMarket(val) {
    setUrlParameters([['market', val, 'all'], ['page',1,1]])
  }

  const productStatusOptions = [
    { value: 'all', label: t('productsPage.statusAll') },
    { value: 0, label: t('productsPage.statusInactive') },
    { value: 1, label: t('productsPage.statusActive') },
  ]

  const productStatusMap = {
    0: t('productsPage.statusInactive'),
    1: t('productsPage.statusActive'),
  }

  const productTypeOptions = [
    { value: 'all', label: t('productsPage.typeAll') },
    { value: 'standard', label: t('productsPage.typeStandard') },
    { value: 'personalized', label: t('productsPage.typePersonalized') },
  ]

  const productMarketOptions = [
    { value: 'all', label: t('productsPage.marketAll') },
    { value: 'pl', label: t('productsPage.marketPl') },
    { value: 'es', label: t('productsPage.marketEs') },
    { value: 'en', label: t('productsPage.marketEn') },
  ]

  useEffect(() => {
      getBooks()
    }, [currentPageNumber, sortBy, sortDirection, status, type, market, searchQuery])

  const getBooks = () => {
    let url = `api/books?page=${currentPageNumber}&itemsPerPage=${ITEMS_PER_PAGE}&order[${sortBy}]=${sortDirection}`
    if (status !== 'all') url += `&status=${status}`
    if (type === 'standard') url += `&isCustomizable=false`
    if (type === 'personalized') url += `&isCustomizable=true`
    if (market !== 'all') url += `&market.countryCode=${market}`
    if (searchQuery) url += `&title=${searchQuery}`

    axios.get(url, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setBooksData(response.data['hydra:member'])
        setTotalItemsCount(response.data['hydra:totalItems'])
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">
        <SortableHeader text={t('productsPage.productColumn')} sortByText="title" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('productsPage.typeColumn')} sortByText="isCustomizable" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('productsPage.marketColumn')} sortByText="market.countryCode" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('productsPage.priceColumn')} sortByText="price" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('productsPage.statusColumn')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell" />
    </div>
  )

  const renderMarket = (marketId) => {
    const marketObject = marketOptions.find(market => market.value === marketId)
    return marketObject ? t(`productsPage.${marketObject.label}`) : ''
  }

  const renderCurrencty = (marketId) => {
    const marketObject = marketOptions.find(market => market.value === marketId)
    if (marketObject && marketObject.value === 1) {
      return 'zł'
    }
    else if (marketObject && marketObject.value === 2) {
      return '€'
    }
    else if (marketObject && marketObject.value === 3) {
      return '€'
    }
    return ''
  }

  const renderRows = () => (
    booksData.map((book, i) => (
      <div key={i} className="table-row">
        <div className="table-row-cell">
          {book.bookMainImage && <img src={serverURL + book.bookMainImage.filePath} alt="book cover" />}
          {book.title}
        </div>
        <div className="table-row-cell">{book.isCustomizable ? t('productsPage.personalizedItemType') : t('productsPage.standardItemType')}</div>
        <div className="table-row-cell">{book.market && renderMarket(book.market.id)}</div>
        <div className="table-row-cell">{!!book.price && `${(parseFloat(book.price)/100).toFixed(2)} ${book.market ? renderCurrencty(book.market.id): ''}`}</div>
        <div className="table-row-cell">{productStatusMap[book.status]}</div>
        <div className="table-row-cell link-cell">
          <Link to={`/admin/products/${book.id}`}>{t('productsPage.detailsLink')}</Link>
        </div>
      </div>
    ))
  )

  return (
    <div className="products-page">
      <div className="content-wrapper-inner">
        <h2>{t('productsPage.header')}</h2>
        <div className="filters-line">
          <div className="left">
            <SearchBox
              value={searchQuery}
              placeholder={t('productsPage.searchBoxPlaceholder')}
              onChange={e => setSearchQuery(e.target.value)}
              clearInput={() => setSearchQuery('')}
            />
          </div>
          <div className="right">
            <div className="select-wrapper">
              <LabeledSelect
                selectedOptionLabel={t('productsPage.statusLabel')}
                options={productStatusOptions}
                value={status}
                error={null}
                placeholder={t('productsPage.selectPlaceholder')}
                onChange={e => setStatus(e.value)}
              />
            </div>
            <div className="select-wrapper">
              <LabeledSelect
                selectedOptionLabel={t('productsPage.typeLabel')}
                options={productTypeOptions}
                value={type}
                error={null}
                placeholder={t('productsPage.selectPlaceholder')}
                onChange={e => setType(e.value)}
              />
            </div>
            <div className="select-wrapper">
              <LabeledSelect
                selectedOptionLabel={t('productsPage.marketLabel')}
                options={productMarketOptions}
                value={market}
                error={null}
                placeholder={t('productsPage.selectPlaceholder')}
                onChange={e => setMarket(e.value)}
              />
            </div>
          </div>
        </div>

        <div className="table-wrapper">
          <div className="table">
            { renderHeaders() }
            { renderRows() }
          </div>
        </div>
        <div className="pagination-wrapper">
          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            totalItemsCount={totalItemsCount}
            currentPageNumber={currentPageNumber}
            paginationChangeHandler={pageNumber => setCurrentPageNumber(pageNumber)} />
        </div>

      </div>
    </div>
  )
}

export default ProductsPage
