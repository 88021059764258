import React, { useState } from 'react'
import './ThanksPage.scss'
import BackgroundImageMenu from '../BackgroundImageMenu/BackgroundImageMenu'
import TextMenu from '../TextMenu/TextMenu'
import photoPlaceholder from '../../../../../../../assets/photo_placeholder.svg'
import RadioButtonsGroup from '../../../../../../commons/RadioButtonsGroup/RadioButtonsGroup'
import { useStateValue } from '../../../../../../../state/state'
import { serverURL } from '../../../../../../../config'
import { useTranslation } from 'react-i18next'


function ThanksPage({
  pageNumber,
  handleImageChange,
}) {
  const [{ bookData }, dispatch] = useStateValue()
  const [openMenu, setOpenMenu] = useState(null)
  const [gender, setGender] = useState('boys')
  const { t } = useTranslation()
  const pageData = bookData.pages.find(page => page.number === pageNumber)

  const genderOptions = [
    { value: 'boys', label: t('pagesEditor.forBoys') },
    { value: 'girls', label: t('pagesEditor.forGirls') },
  ]

  const renderText = () => {
    if (gender === 'boys') return pageData.boysText || `${t('pagesEditor.textPlaceholder')}`
    if (gender === 'girls') return pageData.girlsText || `${t('pagesEditor.textPlaceholder')}`
  }

  const getImageSrc = (uploadedFile) => {
    if (uploadedFile.filePath) {
      return (serverURL + uploadedFile.filePath)
    } else {
      return window.URL.createObjectURL(uploadedFile)
    }
  }

  return (
    <div className="thanks-page">
      <div className="page-menu">
        <div className="page-name">{t(`pageNames.${pageData.name}`)}</div>
        <div className="page-menu-item mt16">
          <BackgroundImageMenu 
            dragAndDropId={`drag-and-drop-page-${pageNumber}`}
            isMenuOpen={openMenu === 'backgroundImage'}
            handleMenuClick={setOpenMenu} 
            backgroundImage={pageData.backgroundImage}
            onChange={value => handleImageChange(value, pageData)}
          />
        </div>
        <div className="page-menu-item mt12">
          <TextMenu 
            isMenuOpen={openMenu === 'text'} 
            handleMenuClick={setOpenMenu} 
            boysText={pageData.boysText}
            girlsText={pageData.girlsText}
            onPositionChange={value => dispatch({ type: 'setBookPageData', number: pageNumber, field: 'textPosition', data: value})}
            onBoysTextChange={value => dispatch({ type: 'setBookPageData', number: pageNumber, field: 'boysText', data: value})}
            onGirlsTextChange={value => dispatch({ type: 'setBookPageData', number: pageNumber, field: 'girlsText', data: value})}
          />
        </div>
      </div>

      <div>
        <div className="page-content">
          {
            pageData.backgroundImage && <img src={getImageSrc(pageData.backgroundImage)} className="background-image" alt="background image" />
          }
          <img src={photoPlaceholder} alt="photo placeholder" className="photo-placeholder" />
          <div dangerouslySetInnerHTML={{__html: renderText()}} className="text" />
        </div>

        <div className="mt24">
          <RadioButtonsGroup
            label={t('pagesEditor.previewLabel')}
            name={`gender-page-${pageNumber}`}
            value={gender}
            options={genderOptions}
            error={null}
            onChange={value => setGender(value)}
          />
        </div>
      </div>
    </div>
  )
}

export default ThanksPage
