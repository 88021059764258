import React, { useState, useEffect } from 'react'
import './OrdersPage.scss'
import {Link} from 'react-router-dom'
import Pagination from '../../../commons/Pagination/Pagination'
import { ITEMS_PER_PAGE } from '../../../../consts'
import SortableHeader from '../../../commons/SortableHeader/SortableHeader'
import SelectInput from '../../../commons/SelectInput/SelectInput'
import SearchBox from '../../../commons/SearchBox/SearchBox'
import { default as axios } from '../../../../axiosSettings'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import useUrlParameters from "../../../../helper/location/useUrlParameter";
import {serverURL} from "../../../../config";

const couponTypeMap = {
  0: '%',
  1: "zł",
  2: '€',
}

function OrdersPage() {
  const [ordersData, setOrdersData] = useState([])
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const [ getUrlParameter, setUrlParameter, setUrlParameters ] = useUrlParameters();

  const currentPageNumber = getUrlParameter('page', 1, true);
  function setCurrentPageNumber(val) {
    setUrlParameter('page', parseInt(val), 1)
  }

  const sortBy = getUrlParameter('sortBy', 'createdAt');
  function setSortBy(val) {
    setUrlParameters([['sortBy', val, 'createdAt'], ['page',1,1]])
  }

  const sortDirection = getUrlParameter('sortDir', 'desc');
  function setSortDirection(val) {
    setUrlParameters([['sortDir', val, 'desc'], ['page',1,1]])
  }

  const orderType = getUrlParameter('type', 0, true);
  function setOrderType(val) {
    setUrlParameters([['type', val, 0], ['page',1,1]])
  }

  const searchQuery = getUrlParameter('search', '');
  function setSearchQuery(val) {
    setUrlParameters([['search', val, ''], ['page',1,1], ['type',0,0]])
  }

  const statusMap = {
    0: t('packageDetailsPage.status0'),
    1: t('packageDetailsPage.status1'),
    2: t('packageDetailsPage.status2'),
    3: t('packageDetailsPage.status3'),
    4: t('packageDetailsPage.status4'),
    5: t('packageDetailsPage.status5'),
  }

  const orderTypeOptions =  [
    { label: t('ordersPage.orderTypeLabel1'), value: 0 },
    { label: t('ordersPage.orderTypeLabel2'), value: 2 },
    { label: t('ordersPage.orderTypeLabel3'), value: 1 },
    { label: t('ordersPage.orderTypeLabel4'), value: 3 },
  ]

  useEffect(() => {
    getOrders()
  }, [currentPageNumber, sortBy, sortDirection, searchQuery, orderType])

  const getOrders = () => {
    let url = `api/orders?page=${currentPageNumber}&itemsPerPage=${ITEMS_PER_PAGE}&order[${sortBy}]=${sortDirection}`
    if (searchQuery) url += `&id=${searchQuery}`
    if (orderType) url += `&type=${orderType}`
    axios.get(url, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setOrdersData(response.data['hydra:member'])
        setTotalItemsCount(response.data['hydra:totalItems'])
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">
        <SortableHeader text={t('ordersPage.idColumn')} sortByText="id" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('ordersPage.clientColumn')} sortByText="shipping.fullName" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('ordersPage.dateColumn')} sortByText="createdAt" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('ordersPage.valueColumn')} sortByText="price" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('ordersPage.priceColumn')} sortByText="price" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <div className="not-sortable-header">{t('ordersPage.codeName')}</div>
      </div>
      <div className="table-header-cell">
        <div className="not-sortable-header">{t('ordersPage.codeValue')}</div>
      </div>
      <div className="table-header-cell">
        <div className="not-sortable-header">{t('ordersPage.statusColumn')}</div>
      </div>
      <div className="table-header-cell" />
    </div>
  )

  const renderRows = () => (
    ordersData.map((order, i) => (
      <div key={i} className={`table-row row-${i%2 ? 'even' : 'odd'}`}>
        <div className="table-row-cell">#{order.id}</div>
        <div className="table-row-cell">{order.fullName}</div>
        <div className="table-row-cell">{moment(order.createdAt).format('DD.MM.YYYY')}</div>
        <div className="table-row-cell">{(parseFloat(order.price + order.shippingCost)/100).toFixed(2)} {order.currencyType === 'PLN' ? 'zł' : '€'}</div>
        <div className="table-row-cell">{(parseFloat(order.totalCost)/100).toFixed(2)} {order.currencyType === 'PLN' ? 'zł' : '€'}</div>
        <div className="table-row-cell">{order.couponName || '-'}</div>
        <div className="table-row-cell">{order.couponValue ? (parseFloat(order.couponValue)/100).toFixed(2) : '-'} {order.couponName && couponTypeMap[order.couponType]}</div>
        <div className="table-row-cell status-cell">{renderStatus(order)}</div>
        <div className="table-row-cell link-cell">
          <Link to={`/admin/orders/${order.id}`}>{t('ordersPage.detailsLink')}</Link>
        </div>
      </div>
    ))
  )

  const renderStatus = orderData => {
    const packages = orderData?.subOrders?.map((subOrder) => {
      return subOrder?.package ? {id: subOrder.package.id, packagePath: subOrder.package.packagePath} : null;
    }).filter((pkg)=>(pkg!==null));

    const standardStatuses = orderData?.subOrders.filter(item => item.type === 0)
    const personalizedStatuses = orderData?.subOrders.filter(item => item.type === 1)
    const mixedStatuses = orderData?.subOrders.filter(item => item.type === 2)

    return (
      <>
        {standardStatuses && standardStatuses.length>0 && standardStatuses.map((subOrder)=>(
          <div key={subOrder.id}>
            {t('ordersPage.orderTypeLabel3')}: <strong>{statusMap[subOrder.status]}</strong>
          </div>
        ))}
        {personalizedStatuses && personalizedStatuses.length>0 && personalizedStatuses.map((subOrder)=>(
          <div key={subOrder.id}>
            {t('ordersPage.orderTypeLabel2')}: <strong>{statusMap[subOrder.status]}</strong>
          </div>
        ))}
        {mixedStatuses && mixedStatuses.length>0 && mixedStatuses.map((subOrder)=>(
          <div key={subOrder.id}>
            {t('ordersPage.orderTypeLabel4')}: <strong>{statusMap[subOrder.status]}</strong>
          </div>
        ))}
        {packages && packages.length > 0 && packages.map((pkg) => (
          <div key={pkg.id} className="package-link">
            {t('packageDetailsPage.package')}&nbsp;
            <a href={`${serverURL}${pkg.packagePath}`} style={{color: '#139CFF'}}>
              #{pkg.id}
            </a>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="orders-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('ordersPage.header')}</h2>
          <div className="right">
            <div className="select-wrapper">
              <SelectInput
                options={orderTypeOptions}
                value={orderType}
                error={null}
                placeholder={t('ordersPage.orderTypeSelectPlaceholder')}
                onChange={e => setOrderType(e.value)}
              />
            </div>
            <div className="search-box-wrapper">
              <SearchBox
                value={searchQuery}
                placeholder={t('ordersPage.searchBoxPlaceholder')}
                onChange={e => setSearchQuery(e.target.value)}
                clearInput={() => setSearchQuery('')}
              />
            </div>
          </div>
        </div>

        <div className="table-wrapper">
          <div className="table">
            { renderHeaders() }
            { renderRows() }
          </div>
        </div>
        <div className="pagination-wrapper">
          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            totalItemsCount={totalItemsCount}
            currentPageNumber={currentPageNumber}
            paginationChangeHandler={pageNumber => setCurrentPageNumber(pageNumber)} />
        </div>
      </div>
    </div>
  )
}

export default OrdersPage
