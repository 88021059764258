import React, { useState } from 'react'
import './PublishBookModal.scss'
import closeIcon from '../../../../../../assets/close_icon.svg'
import confetti from '../../../../../../assets/confetti.svg'
import { default as axios } from '../../../../../../axiosSettings'
import { useStateValue } from '../../../../../../state/state'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../../helper/storage/Storage";


function PublishBookModal(props) {
  const { closeModalHandler } = props
  const [{ bookData }, dispatch] = useStateValue()
  const [bookPublished, setBookPublished] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const handlePublishBook = () => {
    const requestBody = {
      title: bookData.title,
      description: bookData.description,
      isbn: bookData.isbn,
      status: 0,
      isCustomizable: true,
      bookMainImage: '/api/book_main_images/' + bookData.mainImage.id,
      coverFont: bookData.converFont,
      innerFont: bookData.innerFont,
      printingHouse: '/api/users/' + bookData.printingHouse,
      market: '/api/markets/' + bookData.market,
      pages: bookData.pages.map(page => { 
        const pageData = {
          number: page.number,
          type: page.type,
          clientPhotoPosition: page.clientPhotoPosition || 0,
          textPosition: page.textPosition || 0,
          boysText: page.boysText || '',
          girlsText: page.girlsText || '',
          pageBackgroundImage: page.uploadedImage ? '/api/page_background_images/' + page.uploadedImage.id : null
        }
        if (bookData.savedPages.find(savedPage => savedPage.number === page.number)) {
          pageData.id = `/api/pages/${bookData.savedPages.find(savedPage => savedPage.number === page.number).id}`
        }
        return pageData
      }),
    }

    if (bookData.bookId) {
      axios.patch(`api/books/${bookData.bookId}`, requestBody, { 
        headers: { 
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        }
      })
        .then(() => {
          setBookPublished(true)
        })
    } else {
      axios.post(`api/books`, requestBody, { 
        headers: { 
          Authorization: `bearer ${token}`,
        } 
      })
        .then(response => {
          setBookPublished(true)
          dispatch({ type: 'setBookData', field: 'bookId', data: response.data.id })
          props.history.push(`/admin/products/${response.data.id}`)
        })
    }
  }

  const handleCloseModal = () => {
    if (bookPublished) {
      closeModalHandler()
      dispatch({ type: 'resetBookData' })
    } else {
      closeModalHandler()
    }
  }
 
  return (
    <div className="publish-book-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={handleCloseModal} />
        {
          !bookPublished && <React.Fragment>
            <h2>{t('pagesEditor.savaAndAddPriceModalTitle')}</h2>
            <p className="text mt24">{t('pagesEditor.savaAndAddPriceModalText')}</p>
            <div className="button-wrapper mt64">
              <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('pagesEditor.savaAndAddPriceModalCancelButton')}</button>
              <button className="button auto-width" onClick={handlePublishBook}>{t('pagesEditor.savaAndAddPriceModalSaveButton')}</button>
            </div>
          </React.Fragment>
        }

        {
          bookPublished && <React.Fragment>
            <img src={confetti} alt="icon" />
            <h2 className="mt24">{t('pagesEditor.bookPublishedModalTitle')}</h2>
            <p className="text mt24">{t('pagesEditor.bookPublishedModalText1')} <strong>{t('pagesEditor.bookPublishedModalText2')}</strong></p>
          </React.Fragment>
        }
      </div>
    </div>
  )
}

export default withRouter(PublishBookModal)
