import React, { useState, useEffect } from 'react'
import './PersonalizePage.scss'
import { default as axios } from '../../../../axiosSettings'
import { serverURL } from '../../../../config'
import advantagesIcon1 from '../../../../assets/advantages_icon_1.svg'
import advantagesIcon2 from '../../../../assets/advantages_icon_2.svg'
import advantagesIcon3 from '../../../../assets/advantages_icon_3.svg'
import advantagesIcon4 from '../../../../assets/advantages_icon_4.svg'
import KidPhotoDragAndDrop from '../../../commons/KidPhotoDragAndDrop/KidPhotoDragAndDrop'
import chevronLeft from '../../../../assets/chevron_left.svg'
import chevronRight from '../../../../assets/chevron_right.svg'
import bookPreviewPhotoPlaceholder from '../../../../assets/book_preview_photo_placeholder.svg'
import { useStateValue } from '../../../../state/state'
import { useTranslation } from 'react-i18next'
import MetaTags from "../../../commons/MetaTags/MetaTags";
import {notifyGTMPageOpened} from "../../../../helper/tracking/notifyGTMPageOpened";
import {notifyGoogleTagManagerEvent} from "../../../../helper/tracking/notifyGoogleTagManagerEvent";


const boyNamePlaceholder = 'ADAM'
const girlNamePlaceholder = 'ANNA'
const MIN_BOOK_PAGE = -1
const MAX_BOOK_PAGE = 29
const KID_NAME_MAX_LENGTH = 15;


function PersonalizePage(props) {
  const [{ kidName, kidGender, kidEditedPhoto, dedication, cart }, dispatch] = useStateValue()
  const [bookData, setBookData] = useState({})
  const [currentBookPage, setCurrentBookPage] = useState(-1)
  const [kidPhoto, setKidPhoto] = useState(null)
  const [kidNameError, setKidNameError] = useState(false)
  const [kidGenderError, setKidGenderError] = useState(false)
  const [kidPhotoError, setKidPhotoError] = useState(false)
  const [dedicationError, setDedicationError] = useState(false)
  const [isButtonLocked, setIsButtonLocked] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [isSaleAvailable, setSaleAvailable] = useState(false)

  const bookId = props.match.params.id
  const { i18n, t } = useTranslation()

  useEffect(() => {
    axios.get(`api/books/${bookId}`)
      .then(response => {
        setBookData(response.data)
        setSaleAvailable(!!response.data?.status)
      }).catch(()=>{
        setSaleAvailable(false);
      })
  }, [bookId])

  useEffect(() => {
    if (isButtonLocked) {
      setInterval(() => {
        setIsButtonLocked(false)
      }, 5000)
    }
  }, [isButtonLocked])

  useEffect(()=>{
    notifyGTMPageOpened(i18n.language);
    notifyGoogleTagManagerEvent('książka personalizowana', bookData.title);
  }, [bookData.title]);

  const b64toBlob = dataURI => {
    var byteString = atob(dataURI.split(',')[1])
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
  }

  const addToCart = () => {
    const kidNameError = !kidName || typeof kidName !== 'string' || kidName.length > KID_NAME_MAX_LENGTH;
    const kidGenderError = !kidGender
    const kidPhotoError = !kidPhoto
    const dedicationError = !dedication

    if (
      !kidNameError
      && !kidGenderError
      && !kidPhotoError
      && !dedicationError
    ) {
      const formData = new FormData()
      formData.append('file', b64toBlob(kidEditedPhoto))
      setIsButtonDisabled(true)
      axios.post(`api/custom_photos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          const newCart = [
            ...cart,
            {
              id: bookData.id,
              count: 1,
              productData: bookData,
              kidName: kidName,
              kidGender: kidGender,
              kidEditedPhoto: response.data,
              dedication: dedication,
            }
          ]
          dispatch({ type: 'setData', field: 'cart', data: newCart })
          sessionStorage.setItem('cart', JSON.stringify(newCart))

          setKidPhoto(null)
          dispatch({ type: 'setData', field: 'kidName', data: '' })
          dispatch({ type: 'setData', field: 'kidGender', data: '' })
          dispatch({ type: 'setData', field: 'kidEditedPhoto', data: null })
          dispatch({ type: 'setData', field: 'dedication', data: '' })

          setIsButtonDisabled(false)
          setIsButtonLocked(true)
        })
    }

    setKidNameError(kidNameError)
    setKidGenderError(kidGenderError)
    setKidPhotoError(kidPhotoError)
    setDedicationError(dedicationError)
  }

  const insertName = text => {
    return text.replaceAll('{{ child_name }}', kidName ? kidName : (kidGender === 'girl' ? girlNamePlaceholder : boyNamePlaceholder))
  }

  const renderText = (pageNumber) => {
    return kidGender === 'girl'
      ? insertName(bookData.pages[pageNumber].girlsText)
      : insertName(bookData.pages[pageNumber].boysText)
  }

  const renderPagePreviewContent = (pageNumber) => {
    if (pageNumber === -1) return
    return (
      <div className={`page-content-wrapper ${
        bookData.innerFont === 'linotte' ? 'inner-font-linotte' : ''
      } ${
        bookData.innerFont === 'pol-wonder-comic' ? 'inner-font-pol-wonder-comic' : ''
      }`}>
        {
          bookData.pages
            && bookData.pages[pageNumber]
            && bookData.pages[pageNumber].pageBackgroundImage
            && <img src={serverURL + bookData.pages[pageNumber].pageBackgroundImage.filePreviewPath} className={bookData.pages[pageNumber].type === 0 ? 'cover-background' : ''} />
        }
        { bookData.pages && bookData.pages[pageNumber] && bookData.pages[pageNumber].type === 0 && renderCoverPageContent() }
        { bookData.pages && bookData.pages[pageNumber] && bookData.pages[pageNumber].type === 2 && renderDedicationPageContent() }
        { bookData.pages && bookData.pages[pageNumber] && bookData.pages[pageNumber].type === 3 && renderStandardPageContent(pageNumber) }
        { bookData.pages && bookData.pages[pageNumber] && bookData.pages[pageNumber].type === 4 && renderThanksContent(pageNumber) }
      </div>
    )
  }

  const renderCoverPageContent = () => <>
    <img src={kidEditedPhoto || bookPreviewPhotoPlaceholder} alt="kid face" className="page-photo cover-photo" />
    <div className="page-text cover-text">{kidName ? kidName : (kidGender === 'girl' ? girlNamePlaceholder : boyNamePlaceholder)}</div>
  </>

  const renderDedicationPageContent = () => <>
    <img src={kidEditedPhoto || bookPreviewPhotoPlaceholder} alt="kid face" className="page-photo dedication-photo" />
    <div className="page-text dedication-text">{dedication}</div>
  </>

  const renderStandardPageContent = pageNumber => <>
    {
      bookData.pages[pageNumber].textPosition && bookData.pages[pageNumber].textPosition !== 0
        ? <div
          dangerouslySetInnerHTML={{__html: renderText(pageNumber) }}
          className={`page-text ${
            bookData.pages[pageNumber].textPosition === 1 && pageNumber % 2 == 0 ? 'right-page-top-left' : ''
          } ${  
            bookData.pages[pageNumber].textPosition === 1 && pageNumber % 2 == 1 ? 'left-page-top-left' : ''
          } ${
            bookData.pages[pageNumber].textPosition === 2 && pageNumber % 2 == 0 ? 'right-page-botton-left' : ''
          } ${  
            bookData.pages[pageNumber].textPosition === 2 && pageNumber % 2 == 1 ? 'left-page-botton-left' : ''
          } ${
            bookData.pages[pageNumber].textPosition === 3 && pageNumber % 2 == 0 ? 'right-page-top-right' : ''
          } ${
            bookData.pages[pageNumber].textPosition === 3 && pageNumber % 2 == 1 ? 'left-page-top-right' : ''
          } ${
            bookData.pages[pageNumber].textPosition === 4 && pageNumber % 2 == 0 ? 'right-page-botton-right' : ''
          } ${
            bookData.pages[pageNumber].textPosition === 4 && pageNumber % 2 == 1 ? 'left-page-botton-right' : ''
          }`}
        />
        : null
    }
    {
      bookData.pages[pageNumber].clientPhotoPosition && bookData.pages[pageNumber].clientPhotoPosition !== 0
        ? <img src={kidEditedPhoto || bookPreviewPhotoPlaceholder}
          alt="kid face"
          className={`page-photo ${
            bookData.pages[pageNumber].clientPhotoPosition === 1 && pageNumber % 2 == 0 ? 'right-page-top-left' : ''
          } ${  
            bookData.pages[pageNumber].clientPhotoPosition === 1 && pageNumber % 2 == 1 ? 'left-page-top-left' : ''
          } ${
            bookData.pages[pageNumber].clientPhotoPosition === 2 && pageNumber % 2 == 0 ? 'right-page-botton-left' : ''
          } ${  
            bookData.pages[pageNumber].clientPhotoPosition === 2 && pageNumber % 2 == 1 ? 'left-page-botton-left' : ''
          } ${
            bookData.pages[pageNumber].clientPhotoPosition === 3 && pageNumber % 2 == 0 ? 'right-page-top-right' : ''
          } ${
            bookData.pages[pageNumber].clientPhotoPosition === 3 && pageNumber % 2 == 1 ? 'left-page-top-right' : ''
          } ${
            bookData.pages[pageNumber].clientPhotoPosition === 4 && pageNumber % 2 == 0 ? 'right-page-botton-right' : ''
          } ${
            bookData.pages[pageNumber].clientPhotoPosition === 4 && pageNumber % 2 == 1 ? 'left-page-botton-right' : ''
          }`}
        />
        : null
    }
  </>

  const renderThanksContent = (pageNumber) => <>
    <img src={kidEditedPhoto || bookPreviewPhotoPlaceholder} alt="kid face" className="page-photo thanks-page-photo" />
    <div dangerouslySetInnerHTML={{__html: renderText(pageNumber) }} className="page-text thanks-page-text" />
  </>

  const changePage = difference => {
    if (difference < 0) { // go to prev pages
      if (currentBookPage > MIN_BOOK_PAGE) {
        setCurrentBookPage(currentBookPage + difference)
      }
    }
    else { // go to next pages
      if (currentBookPage < MAX_BOOK_PAGE) {
        setCurrentBookPage(currentBookPage + difference)
      }
    }
  }

  const computedMetaTitle = t('seo.titleTemplate.personalizePage', {
    metaTitle: typeof (bookData.metaTitle)==='string' && bookData.metaTitle.length > 0
      ? bookData.metaTitle
      : (bookData?.title?.substring(0,250) ?? t('seo.metaTitleDefault'))
  });
  const computedMetaDescription = bookData?.metaDescription ?? bookData?.description?.substring(0,250) ?? t('seo.metaDescriptionDefault');
  const computedMetaKeywords = bookData?.metaKeywords ?? t('seo.metaKeywordsDefault');

  return (
    <div className="personalize-page">

      <MetaTags
        title={computedMetaTitle}
        description={computedMetaDescription}
        keywords={computedMetaKeywords}
      />

      <section className="book-data-section">
        <div className="section-inner">
          <div className="columns">
            <div className="left">
              <div className="book-preview">
                <div className="page page-left">
                  <div className="page-change-arrow arrow-left">
                    <img src={chevronLeft} alt="arrow" onClick={() => changePage(-2)} />
                  </div>
                  <div className="left-page-shadow" />
                  <div className="left-bottom-shadow" />
                  { renderPagePreviewContent(currentBookPage) }
                </div>
                <div className="page page-right">
                  <div className="page-change-arrow arrow-right">
                    <img src={chevronRight} alt="arrow" onClick={() => changePage(2)} />
                  </div>
                  <div className="right-page-shadow" />
                  <div className="right-bottom-shadow" />
                  { renderPagePreviewContent(currentBookPage + 1) }
                </div>
              </div>

              <h3>{bookData.title}</h3>
              <div className="book-price-line small-and-medium-screens">
                <span className="book-price">{!!bookData.price && `${(parseFloat(bookData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</span>
                <span className="book-price-label">{t('personalizePage.vatText')}</span>
              </div>
              <div className="book-description mt12">{bookData.description}</div>
            </div>
            <div className="right">
              <h2 className="big-screens">{bookData.title}</h2>
              <div className="book-price-line big-screens">
                <span className="book-price">{!!bookData.price && `${(parseFloat(bookData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</span>
                <span className="book-price-label">{t('personalizePage.vatText')}</span>
              </div>
              {isSaleAvailable &&
                <>
                  <div className="input-wrapper">
                    <div className="input-label">
                      <div className="input-label-number">1</div>
                      <div className="input-label-text">{t('personalizePage.nameLabel')}</div>
                    </div>
                    <input
                      type="text"
                      className="name-input"
                      value={kidName}
                      placeholder={t('personalizePage.namePlaceholder')}
                      maxLength={KID_NAME_MAX_LENGTH}
                      onChange={e => dispatch({ type: 'setData', field: 'kidName', data: e.target.value })}
                    />
                    <div className="name-info-message">{t('personalizePage.nameInfo')}</div>
                    { kidNameError &&
                      <div className="input-error">{t('personalizePage.nameValidationMessage')}</div>
                    }
                  </div>

                  <div className="input-wrapper">
                    <div className="input-label">
                      <div className="input-label-number">2</div>
                      <div className="input-label-text">{t('personalizePage.genderLabel')}</div>
                    </div>
                    <div className="gender-options">
                      <div className={`gender-option ${kidGender === 'boy' ? 'option-active' : ''}`} onClick={() => dispatch({ type: 'setData', field: 'kidGender', data: 'boy'})}>{t('personalizePage.boy')}</div>
                      <div className={`gender-option ${kidGender === 'girl' ? 'option-active' : ''}`} onClick={() => dispatch({ type: 'setData', field: 'kidGender', data: 'girl'})}>{t('personalizePage.girl')}</div>
                    </div>
                    { kidGenderError && <div className="input-error">{t('personalizePage.genderValidationMessage')}</div> }
                  </div>

                  <div className="input-wrapper">
                    <div className="input-label">
                      <div className="input-label-number">3</div>
                      <div className="input-label-text">{t('personalizePage.photoLabel')}</div>
                    </div>
                    <div className="photo-info-message">{t('personalizePage.imageSizeInfo')}</div>
                    <KidPhotoDragAndDrop
                      componentId="kid-photo"
                      uploadedFile={kidPhoto}
                      error={null}
                      setUploadedFile={value => setKidPhoto(value)}
                      setEditedImage={value => dispatch({ type: 'setData', field: 'kidEditedPhoto', data: value })}
                    />
                    { kidPhotoError && <div className="input-error">{t('personalizePage.photoValidationMessage')}</div> }
                  </div>

                  <div className="input-wrapper">
                    <div className="input-label">
                      <div className="input-label-number">4</div>
                      <div className="input-label-text">{t('personalizePage.dedicationLabel')}</div>
                    </div>
                    <textarea
                      className="textarea-input"
                      type="text"
                      value={dedication}
                      placeholder={t('personalizePage.dedicationPlaceholder')}
                      onChange={e => e.target.value.length <= 200 && dispatch({ type: 'setData', field: 'dedication', data: e.target.value })}
                    />
                    <div className="textarea-counter">{dedication.length}/200</div>
                    { dedicationError && <div className="input-error">{t('personalizePage.dedicationValidationMessage')}</div> }
                  </div>

                  <div className="button-wrapper">
                    <button className={`button ${isButtonLocked ? 'button-success' : ''} ${isButtonDisabled ? 'button-disabled' : ''}`} onClick={addToCart}>
                      <span>{isButtonLocked ? t('personalizePage.successButton') : t('personalizePage.addButton')}</span>
                      { !isButtonLocked && <span>{(parseFloat(bookData.price)/100).toFixed(2)} {i18n.language === 'pl' ? 'zł' : '€'}</span> }
                    </button>
                  </div>
                </>
              }
              {!isSaleAvailable &&
                <div className="button-wrapper">
                  <button
                    className={`button rounded button-disabled`}
                    onClick={null}
                  >
                    {t('standardBookDetailsPage.forbiddenButton')}
                  </button>
                </div>
              }

            </div>
          </div>
        </div>
      </section>

      <section className="advantages-section">
        <div className="section-inner">
          <h2>{t('personalizedBooksPage.advantagesHeader')}</h2>
          <div className="advantages-grid">
            <div className="advantages-grid-item">
              <img src={advantagesIcon1} alt="advantages icon" />
              <div>{t('personalizedBooksPage.advantage1')}</div>
            </div>
            <div className="advantages-grid-item">
              <img src={advantagesIcon2} alt="advantages icon" />
              <div>{t('personalizedBooksPage.advantage2')}</div>
            </div>
            <div className="advantages-grid-item">
              <img src={advantagesIcon3} alt="advantages icon" />
              <div>{t('personalizedBooksPage.advantage3')}</div>
            </div>
            <div className="advantages-grid-item">
              <img src={advantagesIcon4} alt="advantages icon" />
              <div>{t('personalizedBooksPage.advantage4')}</div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default PersonalizePage
