import React from 'react'
import './CreatorPage.scss'
import { Link } from 'react-router-dom'
import bookIcon from '../../../../assets/book.svg'
import pencilIcon from '../../../../assets/pencil.svg'
import { useTranslation } from 'react-i18next'


function CreatorPage() {
  const { t } = useTranslation()
  
  return (
    <div className="creator-page">
      <div className="switch-grid">
        <Link to="/admin/creator/standard" className="grid-item">
          <img className="icon" src={bookIcon} alt="book" />
          <div>{t('addBookPage.standardBook')}</div>
        </Link>
        <Link to="/admin/creator/personalized" className="grid-item">
          <img className="icon" src={pencilIcon} alt="pencil" />
          <div>{t('addBookPage.personalizedBook')}</div>
        </Link>
      </div>
    </div>
  )
}

export default CreatorPage
