import React from 'react'
import './ProfilePage.scss'


function ProfilePage() {

  return (
    <div className="profile-page">
      ProfilePage
    </div>
  )
}

export default ProfilePage
