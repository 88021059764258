import React, { useState, useEffect } from 'react'
import './OrdersDetailsPage.scss'
import calendarIcon from '../../../../../assets/calendar_icon.svg'
import arrowDownIcon from '../../../../../assets/arrow_down.svg'
// import externalLinkIcon from '../../../../../assets/external_link_icon.svg'
import TextareaInput from '../../../../commons/TextareaInput/TextareaInput'
import TextInput from '../../../../commons/TextInput/TextInput'
import SelectInput from '../../../../commons/SelectInput/SelectInput'
import { default as axios } from '../../../../../axiosSettings'
import moment from 'moment'
import { serverURL } from '../../../../../config'
import ConfirmationModal from '../../../../commons/ConfirmationModal/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


const currencyMap = {
  0: '%',
  1: 'zł',
  2: '€',
}


function OrdersDetailsPage(props) {
  const [orderData, setOrderData] = useState({})
  const [openedItemId, setOpenedItemId] = useState(null)
  const [childName, setChildName] = useState('')
  const [dedication, setDedidation] = useState('')
  const [childPhoto, setChildPhoto] = useState(null)
  const [childNameError, setChildNameError] = useState(false)
  const [dedicationError, setDedicationError] = useState(false)
  const [orderStatus, setOrderStatus] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const [standardSubOrderHistory, setStandardSubOrderHistory] = useState([])
  const [personalizedSubOrderHistory, setPersonalizedSubOrderHistory] = useState([])
  const [statusChangeModalShown, setStatusChangeModalShown] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);
  const orderId = props.match.params.id

  const countriesMap = {
    'AT': t('countries.AT'),
    'BE': t('countries.BE'),
    'BG': t('countries.BG'),
    'HR': t('countries.HR'),
    'CZ': t('countries.CZ'),
    'DK': t('countries.DK'),
    'EE': t('countries.EE'),
    'FI': t('countries.FI'),
    'FR': t('countries.FR'),
    'GR': t('countries.GR'),
    'ES': t('countries.ES'),
    'NL': t('countries.NL'),
    'IE': t('countries.IE'),
    'LT': t('countries.LT'),
    'LU': t('countries.LU'),
    'LV': t('countries.LV'),
    'MC': t('countries.MC'),
    'DE': t('countries.DE'),
    'PL': t('countries.PL'),
    'PT': t('countries.PT'),
    'RO': t('countries.RO'),
    'SK': t('countries.SK'),
    'SE': t('countries.SE'),
    'HU': t('countries.HU'),
    'GB': t('countries.GB'),
    'IT': t('countries.IT'),
  }

  const statusMap = {
    0: t('packageDetailsPage.status0'),
    1: t('packageDetailsPage.status1'),
    2: t('packageDetailsPage.status2'),
    3: t('packageDetailsPage.status3'),
    4: t('packageDetailsPage.status4'),
    5: t('packageDetailsPage.status5'),
  }

  const orderHistoryStatusMap = {
    0: t('orderDetailsPage.statusHistory0'),
    1: t('orderDetailsPage.statusHistory1'),
    2: t('orderDetailsPage.statusHistory2'),
    3: t('orderDetailsPage.statusHistory3'),
    4: t('orderDetailsPage.statusHistory4'),
    5: t('orderDetailsPage.statusHistory5'),
  }

  useEffect(() => {
    getOrderData()
  }, [orderId])

  const getOrderData = () => {
    axios.get(`api/orders/${orderId}`, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setOrderData(response.data)

        const standardSubOrders = response.data.subOrders.filter(item => item.type === 0)
        const personalizedSubOrders = response.data.subOrders.filter(item => item.type === 1)
        if (standardSubOrders.length) setStandardSubOrderHistory(standardSubOrders[0].orderHistories)
        if (personalizedSubOrders.length) setPersonalizedSubOrderHistory(personalizedSubOrders[0].orderHistories)
      })
  }

  const handleOpenDetails = (item) => {
    if (item['@id'] !== openedItemId) {
      setOpenedItemId(item['@id'])
      setChildName(item.childName)
      setDedidation(item.dedication)
      setChildPhoto(serverURL + item.customPhoto.filePath)
    } else {
      setOpenedItemId(null)
    }
  }

  const handeSaveChanges = (item) => {
    const childNameError = !childName
    const dedicationError = !dedication

    if (
      !childNameError
      && !dedicationError
    ) {
      if (childPhoto !== serverURL + item.customPhoto.filePath) {

        const formData = new FormData()
        formData.append('file', uploadedFile)
        axios.post(`api/custom_photos`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            const requestData = {
              childName: childName,
              dedication: dedication,
              customPhoto: response.data['@id']
            }
            patchBookData(item, requestData)
          })
      } else {
        const requestData = {
          childName: childName,
          dedication: dedication
        }
        patchBookData(item, requestData)
      }
    }

    setChildNameError(childNameError)
    setDedicationError(dedicationError)
  }

  const patchBookData = (item, requestData) => {
    axios.patch(item['@id'], requestData, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        setConfirmationModalShown(true)
      })
  }

  const handleNewPhotoUpload = (files) => {
    if (files && files[0]) {
      setUploadedFile(files[0])
      setChildPhoto(window.URL.createObjectURL(files[0]))
    }
  }

  const setStandatdBooksAsSend = () => {
    if (orderStatus !== 5) return
    const subOrders = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 0)
    if (!subOrders || !subOrders.length) return

    axios.patch(subOrders[0]['@id'], {
      status: 5,
    }, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        getOrderData()
        setStatusChangeModalShown(true)
      })
  }

  const renderStandardBooks = () => {
    const subOrders = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 0)
    if (!subOrders || !subOrders.length) return

    return subOrders[0].orderPositions.map((item, i) => {
      return (
        <div key={i} className="table-row-wrapper">
          <div className="table-row">
            <div className="table-row-cell">
              <img src={serverURL + item.book.bookMainImage.filePath} alt="book cover" />
              <div className="book-detals">
                <div className="book-title">{item.book.title}</div>
                <div className="book-type">{t('orderDetailsPage.standardBookType')}</div>
              </div>
            </div>
            <div className="table-row-cell">{statusMap[subOrders[0].status]}</div>
            <div className="table-row-cell">{(item.book.price/100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            <div className="table-row-cell">{item.amount} {t('orderDetailsPage.count')}</div>
            <div className="table-row-cell">{(item.book.price * item.amount / 100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            <div className="table-row-cell" />
          </div>
        </div>
      )}
    )
  }

  const renderPersonalizedBooks = () => {
    const subOrders = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 1)
    if (!subOrders || !subOrders.length) return

    return subOrders[0].orderPositions.map((item, i) => {
      return (
        <div key={i} className={`table-row-wrapper ${openedItemId === item['@id'] ? 'with-background' : ''}`}>
          <div className="table-row">
            <div className="table-row-cell">
              <img src={serverURL + item.book.bookMainImage.filePath} alt="book cover" />
              <div className="book-detals">
                <div className="book-title">{item.book.title}</div>
                <div className="book-type">{t('orderDetailsPage.personalizedBookType')}</div>
                {/* <div className="book-preview" onClick={() => console.log('OPEN PDF')}>
                  <img src={externalLinkIcon} alt="external link" />
                  <span>Podgląd pdf</span>
                </div> */}
              </div>
            </div>
            <div className="table-row-cell">{statusMap[subOrders[0].status]}</div>
            <div className="table-row-cell">{(item.book.price/100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            <div className="table-row-cell">{item.amount} {t('orderDetailsPage.count')}</div>
            <div className="table-row-cell">{(item.book.price * item.amount / 100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
            <div className="table-row-cell">
              <img className={item['@id'] === openedItemId ? 'icon-rotated' : ''} src={arrowDownIcon} alt="arrow down" onClick={() => handleOpenDetails(item)} />
            </div>
          </div>
          { openedItemId === item['@id'] && <div className="personalization-details">
            <div className="left">
              <div>
                <TextInput
                  label={t('orderDetailsPage.kidNameLabel')}
                  placeholder={t('orderDetailsPage.kidNamePlaceholder')}
                  value={childName}
                  error={childNameError && t('orderDetailsPage.kidNameValidationMessage')}
                  onChange={e => setChildName(e.target.value)}
                />
              </div>
              <div className="label mt24">{t('orderDetailsPage.photoLabel')}</div>
              <div className="photo-area">
                <img src={childPhoto} alt="child face" />
              </div>
              <div className="upload-button">
                <input type="file" name={`photo-${item.id}`} id={`photo-${item.id}`} className="inputfile" accept="image/png, image/jpg, image/jpeg" onChange={e => handleNewPhotoUpload(e.target.files)} />
                <label htmlFor={`photo-${item.id}`}>{t('orderDetailsPage.changePhotoButton')}</label>
              </div>
            </div>
            <div className="right">
              <div>
                <TextareaInput
                  label={t('orderDetailsPage.dedicationLabel')}
                  placeholder={t('orderDetailsPage.dedicationPlaceholder')}
                  value={dedication}
                  error={dedicationError && t('orderDetailsPage.dedicationValidationMessage')}
                  onChange={e => setDedidation(e.target.value)}
                />
              </div>
              <div className="button-wrapper">
                <button className="button auto-width" onClick={() => handeSaveChanges(item)}>{t('orderDetailsPage.saveChangesButton')}</button>
              </div>
            </div>
          </div> }
        </div>
      )}
    )
  }

  return (
    <div className="orders-details-page">
      <div className="content-wrapper-inner">
        <div className="title-line">
          <div className="left">
            <h2>{t('orderDetailsPage.order')} #{orderId}</h2>
          </div>
          <div className="right">
            <div className="select-wrapper">
              <SelectInput
                options={[{ label: t('packageDetailsPage.status5'), value: 5 }]}
                value={orderStatus}
                error={null}
                placeholder={t('orderDetailsPage.standardBookStatusChangePlaceholder')}
                onChange={e => setOrderStatus(e.value)}
              />
            </div>
            <button className={`button ${orderStatus !== 5 ? 'button-inactive' : ''}`} onClick={setStandatdBooksAsSend}>{t('orderDetailsPage.saveButton')}</button>
          </div>
        </div>
        <div className="date-line">
          <img src={calendarIcon} alt="calendar-icon" />
          <span>{moment(orderData.createdAt).format('DD.MM.YYYY')}</span>
        </div>

        <div className="columns-2-1">
          <div className="left">
            <div className="box">
              <div className="table-header">
                <div className="table-header-cell">{t('orderDetailsPage.productColumn')}</div>
                <div className="table-header-cell">{t('orderDetailsPage.statusColumn')}</div>
                <div className="table-header-cell">{t('orderDetailsPage.priceColumn')}</div>
                <div className="table-header-cell">{t('orderDetailsPage.countColumn')}</div>
                <div className="table-header-cell">{t('orderDetailsPage.totalColumn')}</div>
                <div className="table-header-cell" />
              </div>
              <div className="table-rows">
                { renderStandardBooks() }
                { renderPersonalizedBooks() }
              </div>
              <div className="summary-line">
                <div className="summary-line-label">{t('orderDetailsPage.totalCost')}</div>
                <div className="summary-line-value">{parseFloat(orderData.price/100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
              </div>
              <div className="summary-line">
                <div className="summary-line-label">{t('orderDetailsPage.shippingCost')}</div>
                <div className="summary-line-value">{(orderData.shippingCost/100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
              </div>
              { orderData.couponValue && <div className="summary-line">
                <div className="summary-line-label">{t('orderDetailsPage.coupon')}</div>
                <div className="summary-line-value green-text">-{(parseFloat(orderData.couponValue)/100).toFixed(2)} {currencyMap[orderData.couponType]}</div>
              </div> }
              <div className="summary-line with-background">
                <div className="summary-line-label">{t('orderDetailsPage.orderValue')}</div>
                <div className="summary-line-value">{parseFloat((orderData.totalCost)/100).toFixed(2)} {orderData.currencyType === 'PLN' ? 'zł' : '€'}</div>
              </div>
            </div>

            <div className="box mt24">
              <div className="label">{t('orderDetailsPage.historySectionTitle')}</div>
              <div className={standardSubOrderHistory.length ? 'mb32' : ''}>
                { standardSubOrderHistory.length ? <div className="mt24 mb32">{t('orderDetailsPage.standardBooks')}</div> : null }
                {
                  standardSubOrderHistory
                    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                    .map((item, i) => <div key={i} className="order-history-item-wrapper">
                      <div className="order-history-item">
                        <span className="item-date">{moment(item.createdAt).format('DD.MM.YYYY')}</span>
                        <span className="circle" />
                        <span>{orderHistoryStatusMap[item.status]}</span>
                      </div>
                      <hr className="hr-smaller" />
                    </div>)
                }
              </div>
              <div>
                { personalizedSubOrderHistory.length ? <div className="mt24 mb32">{t('orderDetailsPage.personalizedBooks')}</div> : null }
                {
                  personalizedSubOrderHistory
                    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                    .map((item, i) => <div key={i} className="order-history-item-wrapper">
                      <div className="order-history-item">
                        <span className="item-date">{moment(item.createdAt).format('DD.MM.YYYY')}</span>
                        <span className="circle" />
                        <span>{orderHistoryStatusMap[item.status]}</span>
                      </div>
                      <hr className="hr-smaller" />
                    </div>)
                }
              </div>
            </div>
          </div>
          <div className="right">
            <div className="box">
              <div className="label">{t('orderDetailsPage.clientLabel')}</div>
              <div className="text-bigger mt16">{orderData.shipping && orderData.fullName}</div>
              <div className="text mt4">{orderData.shipping && orderData.email}</div>
              <hr />
              <div className="label">{t('orderDetailsPage.invoiceLabel')}</div>
              <div className="text mt8">{orderData.invoice && orderData.invoice.fullName}</div>
              <div className="text">{orderData.invoice && orderData.invoice.type === 1 && orderData.invoice.companyName}</div>
              <div className="text">{orderData.invoice && orderData.invoice.type === 1 && orderData.invoice.taxNumber}</div>
              <div className="text">{orderData.invoice && orderData.invoice.address1}</div>
              <div className="text">{orderData.invoice && orderData.invoice.address2}</div>
              <div className="text">{orderData.invoice && `${orderData.invoice.zipCode} ${orderData.invoice.city}`}</div>
              <div className="text">{orderData.invoice && `${orderData.invoice.state} ${countriesMap[orderData.invoice.country]}`}</div>
              <hr />
              {/* <div className="label">Sposób płatności</div>
              <div className="text mt8">BLIK</div>
              <hr /> */}
              <div className="label">{t('orderDetailsPage.shippingLabel')}</div>
              <div className="text mt8">{orderData.shipping && orderData.shipping.fullName}</div>
              <div className="text">{orderData.shipping && orderData.shipping.companyName}</div>
              <div className="text">{orderData.shipping && orderData.shipping.email}</div>
              <div className="text">{orderData.shipping && orderData.shipping.address1}</div>
              <div className="text">{orderData.shipping && orderData.shipping.address2}</div>
              <div className="text">{orderData.shipping && `${orderData.shipping.zipCode} ${orderData.shipping.city}`}</div>
              <div className="text">{orderData.shipping && `${orderData.shipping.state} ${countriesMap[orderData.shipping.country]}`}</div>
              <div className="text">{orderData.shipping && orderData.shipping.phone}</div>
            </div>
          </div>
        </div>
      </div>
      {
        confirmationModalShown && <ConfirmationModal
          header={t('orderDetailsPage.personalizationChangesModalHeader')}
          buttonText={t('orderDetailsPage.personalizationChangesModalButton')}
          closeModalHandler={() => {
            setConfirmationModalShown(false)
          }}
        >
          {t('orderDetailsPage.personalizationChangesModalText')}
        </ConfirmationModal>
      }
      {
        statusChangeModalShown && <ConfirmationModal
          header={t('orderDetailsPage.statusChangeModalHeader')}
          buttonText={t('orderDetailsPage.statusChangeModalButton')}
          closeModalHandler={() => {
            setStatusChangeModalShown(false)
          }}
        >
          {t('orderDetailsPage.statusChangeModalText')}
        </ConfirmationModal>
      }
    </div>
  )
}

export default OrdersDetailsPage
