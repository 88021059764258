import {get, remove, set} from "./driver_cookies";

export const COOKIE_CONSENT_TECHNICAL = 'cookieConsentTechnical';
export const COOKIE_CONSENT_TRACKING = 'cookieConsentTracking';

const CookieConsentStorage = {

  getCookieConsent: function() {
    return {
      COOKIE_CONSENT_TECHNICAL: this.getCookieConsentTechnical(),
      COOKIE_CONSENT_TRACKING: this.getCookieConsentTracking(),
    }
  },

  getCookieConsentTechnical: function() {
    const storedVal = get(COOKIE_CONSENT_TECHNICAL);
    return Boolean(storedVal) && storedVal!=='false' && storedVal!=='0';
  },

  setCookieConsentTechnical: function(val) {
    const newValue = Boolean(val) && val!=='false' && val!=='0';
    if (newValue) {
      set(COOKIE_CONSENT_TECHNICAL, "1");
    }
    else {
      remove(COOKIE_CONSENT_TECHNICAL);
      remove(COOKIE_CONSENT_TRACKING);
    }
  },

  getCookieConsentTracking: function() {
    const storedVal = get(COOKIE_CONSENT_TRACKING);
    return Boolean(storedVal) && storedVal!=='false' && storedVal!=='0';
  },

  setCookieConsentTracking: function(val) {
    const newValue = Boolean(val) && val!=='false' && val!=='0';
    if (newValue) {
      set(COOKIE_CONSENT_TRACKING, "1");
      set(COOKIE_CONSENT_TECHNICAL, "1");
    }
    else {
      remove(COOKIE_CONSENT_TRACKING);
    }
  },

  hasCookieConsent: function (type = COOKIE_CONSENT_TRACKING) {
    switch (type) {
      case COOKIE_CONSENT_TRACKING:
        return this.getCookieConsentTracking();
      case COOKIE_CONSENT_TECHNICAL:
        return this.getCookieConsentTechnical();
      default:
        return false;
    }
  }

}

export default CookieConsentStorage;