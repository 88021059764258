import React, { useState, useEffect } from 'react'
import './SimilarProducts.scss'
import { default as axios } from '../../../../../axiosSettings'
import { serverURL } from '../../../../../config'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const shuffle = array => {
  let currentIndex = array.length, temporaryValue, randomIndex
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}


function SimilarProducts(props) {
  const [similarProducts, setSimilarProducts] = useState([])
  const { i18n } = useTranslation()
  const bookId = props.match.params.id

  useEffect(() => {
    getSimilarProducts()
  }, [bookId])

  const getSimilarProducts = () => {
    let url = `api/books?isCustomizable=false&itemsPerPage=9999&market.countryCode=${i18n.language}&status=1`
    axios.get(url)
      .then(response => {
        const filteredItems = response.data['hydra:member'].filter(item => item.id !== parseInt(bookId))
        setSimilarProducts(shuffle(filteredItems).splice(0,4))
      })
  }

  return (
    <div className="similar-products">
      {
        similarProducts.map((product, i) => <Link to={`/shop/${product.id}`} key={i} className="products-box">
          <img src={serverURL + product.bookMainImage.filePath} alt="cover" />
          <div className="mt12">{product.title}</div>
          <div className="similar-product-price mt8">{!!product.price && `${(parseFloat(product.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
        </Link>)
      }
    </div>
  )
}

export default withRouter(SimilarProducts)
