import React, { useState } from 'react'
import './ShopLoginPage.scss'
import { useStateValue } from '../../../../state/state'
import { Link, withRouter } from 'react-router-dom'
import tickImageGreen from '../../../../assets/tick_image_green.svg'
import PasswordInput from '../../../commons/PasswordInput/PasswordInput'
import TextInput from '../../../commons/TextInput/TextInput'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {CLIENT_ID, REFRESH_TOKEN, Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";


function ShopLoginPage(props) {
  const [{ cart }, dispatch] = useStateValue()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const { t } = useTranslation()


  const handleLogin = () => {
    axios.post(`api/login`, {
      email: email,
      password: password,
    })
      .then(response => {
        if (response.status === 200 && response.data.data.roles.indexOf('ROLE_CLIENT') !== -1) {
          Storage.setToken(response.data.token);
          Storage.set(REFRESH_TOKEN, response.data.refresh_token);
          Storage.set(CLIENT_ID, response.data.data.id);
          dispatch({ type: 'setData', field: 'isClientLoggedIn', data: true })
          if (props.location.state && props.location.state.prevPath === '/cart') {
            props.history.push('/order-data')
          } else {
            props.history.push('/')
          }
        }
        else {
          setLoginError(t('shopLoginPage.loginValidationMessage'))
        }
      })
      .catch(() => {
        setLoginError(t('shopLoginPage.loginValidationMessage'))
      })
  }

  return (
    <div className="shop-login-page">

      <MetaTags title="Edikids - logowanie" />

      <div className="page-content-wrapper">

        <div className="columns">
          <div className="left">
            <h2>{t('shopLoginPage.loginSectionHeader')}</h2>
            <div className="input-wrapper">
              <TextInput
                label={t('shopLoginPage.emailLabel')}
                value={email}
                placeholder={t('shopLoginPage.emailPlaceholder')}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="input-wrapper password-input-wrapper mt24">
              <PasswordInput
                label={t('shopLoginPage.passwordLabel')}
                value={password}
                placeholder={t('shopLoginPage.passwordPlaceholder')}
                onChange={e => setPassword(e.target.value)}
              />
            </div>

            <div className="link-wrapper">
              <Link to="/reset-password">{t('shopLoginPage.forgotPassword')}</Link>
            </div>

            { loginError && <div className="login-error">{loginError}</div> }

            <div className="button-wrapper">
              <button className="button rounded" onClick={handleLogin}>{t('shopLoginPage.loginButton')}</button>
            </div>
          </div>

          <div className="right">
            <h2>{t('shopLoginPage.registerSectionHeader')}</h2>
            <p>{t('shopLoginPage.advandategText')}</p>
            <ul>
              <li><img src={tickImageGreen} alt="" />{t('shopLoginPage.advantage1')}</li>
              <li><img src={tickImageGreen} alt="" />{t('shopLoginPage.advantage2')}</li>
              <li><img src={tickImageGreen} alt="" />{t('shopLoginPage.advantage3')}</li>
              <li><img src={tickImageGreen} alt="" />{t('shopLoginPage.advantage4')}</li>
            </ul>
            <div className="button-wrapper">
              <Link to={{pathname: "/sign-up", state: { prevPath: props.location.state && props.location.state.prevPath }}}><button className="button">{t('shopLoginPage.registerButton')}</button></Link>
            </div>
            {
              cart && cart.length 
                ? <div className="button-wrapper">
                  <Link to="/buy-without-registration"><button className="button button-secondary">{t('shopLoginPage.buyWithoutRegistrationButton')}</button></Link>
                </div> 
                : null
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export default withRouter(ShopLoginPage)
