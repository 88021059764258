import React, {useEffect, useState} from 'react'
import './FAQPage.scss'
import plusIcon from '../../../../assets/plus_icon.svg'
import minusIcon from '../../../../assets/minus_icon.svg'
import { useTranslation } from 'react-i18next'
import MetaTags from "../../../commons/MetaTags/MetaTags";
import {notifyGTMPageOpened} from "../../../../helper/tracking/notifyGTMPageOpened";


function FAQPage() {
  const [openedQuestion, setOpenedQuestion] = useState(null)
  const { t, i18n } = useTranslation()

  const questions = [
    {
      id: 1,
      question: t('faqPage.question1question'),
      answer: t('faqPage.question1answer'),
    },
    {
      id: 2,
      question: t('faqPage.question2question'),
      answer: t('faqPage.question2answer'),
    },
    {
      id: 3,
      question: t('faqPage.question3question'),
      answer: t('faqPage.question3answer'),
    },
    {
      id: 4,
      question: t('faqPage.question4question'),
      answer: t('faqPage.question4answer'),
    },
    {
      id: 5,
      question: t('faqPage.question5question'),
      answer: t('faqPage.question5answer'),
    },
    {
      id: 6,
      question: t('faqPage.question6question'),
      answer: t('faqPage.question6answer'),
    },
    {
      id: 7,
      question: t('faqPage.question7question'),
      answer: t('faqPage.question7answer'),
    },
    {
      id: 8,
      question: t('faqPage.question8question'),
      answer: t('faqPage.question8answer'),
    },
    {
      id: 9,
      question: t('faqPage.question9question'),
      answer: t('faqPage.question9answer'),
    },
    {
      id: 10,
      question: t('faqPage.question10question'),
      answer: t('faqPage.question10answer'),
    },
  ]

  useEffect(()=>{
    notifyGTMPageOpened(i18n.language);
  }, []);

  return (
    <div className="faq-page">

      <MetaTags title="Edikids - FAQ" />

      <div className="page-content">
        <h2>{t('faqPage.header')}</h2>
        {
          questions.map((item, i) => <div className={`question-wrapper ${openedQuestion === item.id ? 'question-opened' : ''}`} key={i}>
            <div className="question-line" onClick={() => setOpenedQuestion(openedQuestion === item.id ? null : item.id)}>
              {item.question}
              <img src={openedQuestion === item.id ? minusIcon : plusIcon} alt="" />
            </div>
            { openedQuestion === item.id && <p className="mt16">{item.answer}</p> }
          </div>)
        }
      </div>
    </div>
  )
}

export default FAQPage
