import React, { useState, useEffect } from 'react'
import './StandardBook.scss'
import TextInput from '../../../../commons/TextInput/TextInput'
import TextareaInput from '../../../../commons/TextareaInput/TextareaInput'
import SelectInput from '../../../../commons/SelectInput/SelectInput'
import PriceInput from '../../../../commons/PriceInput/PriceInput'
import { currencyMap } from '../../../../../consts'
import DragAndDrop2 from '../../../../commons/DragAndDrop2/DragAndDrop2'
import PublishBookModal from './PublishBookModal/PublishBookModal'
import { useStateValue } from '../../../../../state/state'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


function StandardBook() {
  const [{ marketOptions }] = useStateValue()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [market, setMarket] = useState('pl')
  const [price, setPrice] = useState('')
  const [isbn, setIsbn] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [metaKeywords, setMetaKeywords] = useState('')
  const [bookImage1, setBookImage1] = useState(null)
  const [bookImage2, setBookImage2] = useState(null)
  const [bookImage3, setBookImage3] = useState(null)
  const [bookImage4, setBookImage4] = useState(null)
  const [bookImage5, setBookImage5] = useState(null)
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [priceError, setPriceError] = useState(false)
  const [isbnError, setIsbnError] = useState(false)
  const [bookImage1Error, setBookImage1Error] = useState(false)
  const [publishBookModalShown, setPublishBookModalShown] = useState(false)
  const [requestBody, setRequestBody] = useState({})
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    if(marketOptions.length) {
      setMarket(marketOptions[0].value)
    }
  }, [marketOptions])

  const handleImageChange = (value, endpointName, image, changeHandler) => {
    if (!value) {
      removeExistingImage(image.id, endpointName, changeHandler)
    } else if (value && image) {
      replaceImage(image.id, value, endpointName, image, changeHandler)
    } else if (value && !image) {
      uploadNewImage(value, endpointName, changeHandler)
    }
  }

  const uploadNewImage = (value, endpointName, changeHandler) => {
    const formData = new FormData()
    formData.append('file', value)
    axios.post(`api/${endpointName}`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      } 
    })
      .then(response => {
        if (response.status === 201) {
          changeHandler(response.data)
        }
      })
  }

  const removeExistingImage = (imageId, endpointName, changeHandler) => {
    axios.delete(`api/${endpointName}/${imageId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        if (response.status === 204) {
          changeHandler(null)
        }
      })
  }

  const replaceImage = (imageId, value, endpointName, changeHandler) => {
    axios.delete(`api/${endpointName}/${imageId}`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
    uploadNewImage(value, endpointName, changeHandler)
  }

  const validateInputs = () => {
    const titleError = !title
    const descriptionError = !description
    const priceError = !price
    const isbnError = !isbn
    const bookImage1Error = !bookImage1

    if (
      !titleError
      && !descriptionError
      && !priceError
      && !isbnError
      && !bookImage1Error
    ) {
      const requestBody = {
        title: title,
        description: description,
        market: '/api/markets/' + market,
        price: parseInt(parseFloat(price).toFixed(2) * 100),
        isbn: isbn,
        bookMainImage: '/api/book_main_images/' + bookImage1.id,
        bookAdditionalImages: [],
        isCustomizable: false,
        status: 1,
        metaTitle: metaTitle ?? null,
        metaDescription: metaDescription ?? null,
        metaKeywords: metaKeywords ?? null,
      }
      if (bookImage2) requestBody.bookAdditionalImages.push('/api/book_additional_images/' + bookImage2.id)
      if (bookImage3) requestBody.bookAdditionalImages.push('/api/book_additional_images/' + bookImage3.id)
      if (bookImage4) requestBody.bookAdditionalImages.push('/api/book_additional_images/' + bookImage4.id)
      if (bookImage5) requestBody.bookAdditionalImages.push('/api/book_additional_images/' + bookImage5.id)
      setRequestBody(requestBody)
      setPublishBookModalShown(true)
    }

    setTitleError(titleError)
    setDescriptionError(descriptionError)
    setPriceError(priceError)
    setIsbnError(isbnError)
    setBookImage1Error(bookImage1Error)
  }


  return (
    <div className="standard-book">
      <div className="details-box">
        <h2><center>{t('addStandardBookPage.header')}</center></h2>

        <div className="input-wrapper">
          <TextInput 
            label={t('addStandardBookPage.titleLabel')}
            placeholder={t('addStandardBookPage.titlePlaceholder')}
            value={title}
            error={titleError && t('addStandardBookPage.titleValidationMessage')}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <TextareaInput 
            label={t('addStandardBookPage.descriptionLabel')}
            placeholder={t('addStandardBookPage.descriptionPlaceholder')}
            value={description}
            error={descriptionError && t('addStandardBookPage.descriptionValidationMessage')}
            maxCharacters={900}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        
        <div className="columns-2">
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addStandardBookPage.verionsLabel')}
                options={marketOptions.map(item => ({
                  value: item.value,
                  label: t(`productDetailsPage.${item.label}`),
                }))}
                value={market}
                error={null}
                placeholder={t('addStandardBookPage.selectPlaceholder')}
                onChange={e => setMarket(e.value)}
              />
            </div>
          </div>
          <div>
            <div className="input-wrapper">
              <PriceInput 
                label={t('addStandardBookPage.priceLabel')}
                value={price}
                error={priceError && t('addStandardBookPage.priceValidationMessage')}
                placeholder={t('addStandardBookPage.pricePlaceholder')}
                currency={currencyMap[market]}
                onChange={e => setPrice(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="input-wrapper">
          <TextInput
            label={t('addStandardBookPage.isbnLabel')}
            placeholder={t('addStandardBookPage.isbnPlaceholder')}
            value={isbn}
            error={isbnError && t('addStandardBookPage.isbnValidationMessage')}
            onChange={e => setIsbn(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <div className="label">{t('addStandardBookPage.photosLabel')}</div>
          <div className="book-images">
            <DragAndDrop2
              isMain={true}
              componentId="book-image-1"
              uploadedFile={bookImage1} 
              error={bookImage1Error && t('addStandardBookPage.mainPhotoValidationMessage')}
              setUploadedFile={value => handleImageChange(value, 'book_main_images', bookImage1, setBookImage1)}
              clearUpload={() => handleImageChange(null, 'book_main_images', bookImage1, setBookImage1)}
            />
            <DragAndDrop2
              componentId="book-image-2"
              uploadedFile={bookImage2} 
              setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage2, setBookImage2)}
              clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage2, setBookImage2)}
            />
            <DragAndDrop2
              componentId="book-image-3"
              uploadedFile={bookImage3} 
              setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage3, setBookImage3)}
              clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage3, setBookImage3)}
            />
            <DragAndDrop2
              componentId="book-image-4"
              uploadedFile={bookImage4} 
              setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage4, setBookImage4)}
              clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage4, setBookImage4)}
            />
            <DragAndDrop2
              componentId="book-image-5"
              uploadedFile={bookImage5} 
              setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage5, setBookImage5)}
              clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage5, setBookImage5)}
            />
          </div>
        </div>

        <div className="seo-fields-wrapper">
          <h4 className="title">{t('addStandardBookPage.seoSectionTitle')}</h4>
          <div className="input-wrapper">
            <TextInput
              label={t('addStandardBookPage.metaTitleLabel')}
              placeholder={t('addStandardBookPage.metaTitlePlaceholder')}
              value={metaTitle}
              maxCharacters={250}
              onChange={e => setMetaTitle(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <TextareaInput
              label={t('addStandardBookPage.metaDescriptionLabel')}
              placeholder={t('addStandardBookPage.metaDescriptionPlaceholder')}
              value={metaDescription}
              maxCharacters={250}
              onChange={e => setMetaDescription(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <TextInput
              label={t('addStandardBookPage.metaKeywordsLabel')}
              placeholder={t('addStandardBookPage.metaKeywordsPlaceholder')}
              value={metaKeywords}
              maxCharacters={250}
              onChange={e => setMetaKeywords(e.target.value)}
            />
          </div>
        </div>

        <div className="button-wrapper mt48">
          <button className="button" onClick={validateInputs}>{t('addStandardBookPage.addButtonButton')}</button>
        </div>

        { publishBookModalShown && <PublishBookModal closeModalHandler={() => setPublishBookModalShown(false)} bookData={requestBody} /> }

      </div>
    </div>
  )
}

export default StandardBook
