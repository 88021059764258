import React, { useState, useEffect } from 'react'
import { getClientData, validateEmail } from '../../../../utils'
import { useStateValue } from '../../../../state/state'
import TextInput from '../../../commons/TextInput/TextInput'
import './AddressDataPage.scss'
import { default as axios } from '../../../../axiosSettings'
import SelectInput from '../../../commons/SelectInput/SelectInput'
import { countriesDataPL } from '../../../../consts'
import RadioButtonsGroup from '../../../commons/RadioButtonsGroup/RadioButtonsGroup'
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";


function AddressDataPage() {
  const [{ clientData }, dispatch] = useStateValue()
  const { t } = useTranslation()

  const [shippingData, setShippingData] = useState({})
  const [invoiceData, setInvoiceData] = useState({})
  const [page, setPage] = useState('shipping') // shipping, invoice
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')

  const [invoiceType, setInvoiceType] = useState(0)
  const [invoiceFullName, setInvoiceFullName] = useState('')
  const [invoiceCompanyName, setInvoiceCompanyName] = useState('')
  const [invoiceTaxNumber, setInvoiceTaxNumber] = useState('')
  const [invoiceCountry, setInvoiceCountry] = useState('')
  const [invoiceRegion, setInvoiceRegion] = useState('')
  const [invoiceAddress, setInvoiceAddress] = useState('')
  const [invoiceAddress2, setInvoiceAddress2] = useState('')
  const [invoiceZipCode, setInvoiceZipCode] = useState('')
  const [invoiceCity, setInvoiceCity] = useState('')

  const [fullNameError, setFullNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [countryError, setCountryError] = useState(false)
  const [regionError, setRegionError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [cityError, setCityError] = useState(false)

  const [invoiceFullNameError, setInvoiceFullNameError] = useState(false)
  const [invoiceCompanyNameError, setInvoiceCompanyNameError] = useState(false)
  const [invoiceTaxNumberError, setInvoiceTaxNumberError] = useState(false)
  const [invoiceCountryError, setInvoiceCountryError] = useState(false)
  const [invoiceRegionError, setInvoiceRegionError] = useState(false)
  const [invoiceAddressError, setInvoiceAddressError] = useState(false)
  const [invoiceZipCodeError, setInvoiceZipCodeError] = useState(false)
  const [invoiceCityError, setInvoiceCityError] = useState(false)

  const token = Storage.get(TOKEN);

  const invoiceTypeOptions = [
    { value: 0, label: t('addressDataPage.invoiceType1') },
    { value: 1, label: t('addressDataPage.invoiceType2') },
  ]

  useEffect(() => {
    if (clientData.shipping) {
      axios.get(clientData.shipping['@id'], { 
        headers: { 
          Authorization: `bearer ${token}`,
        } 
      })
        .then(response => {
          setShippingData(response.data)
          setFullName(response.data.fullName)
          setEmail(response.data.email)
          setPhone(response.data.phone)
          setCompanyName(response.data.companyName)
          setCountry(response.data.country)
          setRegion(response.data.state)
          setAddress(response.data.address1)
          setAddress2(response.data.address2)
          setZipCode(response.data.zipCode)
          setCity(response.data.city)
        })
    }
  }, [clientData.shipping])

  useEffect(() => {
    if (clientData.invoice) {
      axios.get(clientData.invoice, { 
        headers: { 
          Authorization: `bearer ${token}`,
        } 
      })
        .then(response => {
          setInvoiceData(response.data)
          setInvoiceFullName(response.data.fullName)
          setInvoiceCompanyName(response.data.companyName)
          setInvoiceTaxNumber(response.data.taxNumber)
          setInvoiceCountry(response.data.country)
          setInvoiceRegion(response.data.state)
          setInvoiceAddress(response.data.address1)
          setInvoiceAddress2(response.data.address2)
          setInvoiceZipCode(response.data.zipCode)
          setInvoiceCity(response.data.city)
        })
    }
  }, [clientData.invoice])

  const renderShippingDataForm = () => <>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.shippingNameLabel')}
        placeholder={t('addressDataPage.shippingNamePlaceholder')}
        value={fullName}
        error={fullNameError && t('addressDataPage.shippingNameValidationMessage')}
        onChange={e => setFullName(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.shippingEmailLabel')}
        placeholder={t('addressDataPage.shippingEmailPlaceholder')}
        value={email}
        error={emailError && t('addressDataPage.shippingEmailValidationMessage')}
        onChange={e => setEmail(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.shippingPhoneLabel')}
        placeholder={t('addressDataPage.shippingPhonePlaceholder')}
        value={phone}
        error={phoneError && t('addressDataPage.shippingPhoneValidationMessage')}
        onChange={e => setPhone(e.target.value)}
      />
      <TextInput
        label={t('addressDataPage.shippingCompanyNameLabel')}
        placeholder={t('addressDataPage.shippingCompanyNamePlaceholder')}
        value={companyName}
        error={null}
        onChange={e => setCompanyName(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <SelectInput
        label={t('addressDataPage.shippingCountryLabel')}
        options={countriesDataPL}
        value={country}
        error={countryError && t('addressDataPage.shippingCountryValidationMessage')}
        placeholder={t('addressDataPage.shippingCountryPlaceholder')}
        onChange={e => setCountry(e.value)}
      />
      <TextInput
        label={t('addressDataPage.shippingRegionLabel')}
        placeholder={t('addressDataPage.shippingRegionPlaceholder')}
        value={region}
        error={regionError && t('addressDataPage.shippingRegionValidationMessage')}
        onChange={e => setRegion(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.shippingAddress1Label')}
        placeholder={t('addressDataPage.shippingAddress1Placeholder')}
        value={address}
        error={addressError && t('addressDataPage.shippingAddress1ValidationMessage')}
        onChange={e => setAddress(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.shippingAddress2Label')}
        placeholder={t('addressDataPage.shippingAddress2Placeholder')}
        value={address2}
        error={null}
        onChange={e => setAddress2(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.shippingZipCodeLabel')}
        placeholder={t('addressDataPage.shippingZipCodePlaceholder')}
        value={zipCode}
        error={zipCodeError && t('addressDataPage.shippingZipCodeValidationMessage')}
        onChange={e => setZipCode(e.target.value)}
      />
      <TextInput
        label={t('addressDataPage.shippingCityLabel')}
        placeholder={t('addressDataPage.shippingCityPlaceholder')}
        value={city}
        error={cityError && t('addressDataPage.shippingCityValidationMessage')}
        onChange={e => setCity(e.target.value)}
      />
    </div>

    <div className="button-wrapper">
      <button className="button auto-width" onClick={saveShippingData}>{t('addressDataPage.shippingSave')}</button>
    </div>
  </>

  const renderInvoiceDataForm = () => <div className="invoice-form">
    <div className="mb32">
      <RadioButtonsGroup
        name="invoiceType"
        value={invoiceType}
        options={invoiceTypeOptions}
        onChange={value => setInvoiceType(value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.invoiceNameLabel')}
        placeholder={t('addressDataPage.invoiceNamePlaceholder')}
        value={invoiceFullName}
        error={invoiceFullNameError && t('addressDataPage.invoiceNameValidationMessage')}
        onChange={e => setInvoiceFullName(e.target.value)}
      />
    </div>
    { 
      invoiceType === 1 && <div className="input-wrapper">
        <TextInput
          label={t('addressDataPage.invoiceCompanyNameLabel')}
          placeholder={t('addressDataPage.invoiceCompanyNamePlaceholder')}
          value={invoiceCompanyName}
          error={invoiceCompanyNameError && t('addressDataPage.invoiceCompanyNameValidationMessage')}
          onChange={e => setInvoiceCompanyName(e.target.value)}
        />
        <TextInput
          label={t('addressDataPage.invoiceTaxNumberLabel')}
          placeholder={t('addressDataPage.invoiceTaxNumberPlaceholder')}
          value={invoiceTaxNumber}
          error={invoiceTaxNumberError && t('addressDataPage.invoiceTaxNumberValidationMessage')}
          onChange={e => setInvoiceTaxNumber(e.target.value)}
        />
      </div> 
    }
    <div className="input-wrapper">
      <SelectInput
        label={t('addressDataPage.invoiceCountryLabel')}
        options={countriesDataPL}
        value={invoiceCountry}
        error={invoiceCountryError && t('addressDataPage.invoiceCountryPlaceholder')}
        placeholder={t('addressDataPage.invoiceCountryValidationMessage')}
        onChange={e => setInvoiceCountry(e.value)}
      />
      <TextInput
        label={t('addressDataPage.invoiceRegionLabel')}
        placeholder={t('addressDataPage.invoiceRegionPlaceholder')}
        value={invoiceRegion}
        error={invoiceRegionError && t('addressDataPage.invoiceRegionValidationMessage')}
        onChange={e => setInvoiceRegion(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.invoiceAddress1Label')}
        placeholder={t('addressDataPage.invoiceAddress1Placeholder')}
        value={invoiceAddress}
        error={invoiceAddressError && t('addressDataPage.invoiceAddress1ValidationMessage')}
        onChange={e => setInvoiceAddress(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.invoiceAddress2Label')}
        placeholder={t('addressDataPage.invoiceAddress2Placeholder')}
        value={invoiceAddress2}
        error={null}
        onChange={e => setInvoiceAddress2(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('addressDataPage.invoiceZipCodeLabel')}
        placeholder={t('addressDataPage.invoiceZipCodePlaceholder')}
        value={invoiceZipCode}
        error={invoiceZipCodeError && t('addressDataPage.invoiceZipCodeValidationMessage')}
        onChange={e => setInvoiceZipCode(e.target.value)}
      />
      <TextInput
        label={t('addressDataPage.invoiceCityLabel')}
        placeholder={t('addressDataPage.invoiceCityPlaceholder')}
        value={invoiceCity}
        error={invoiceCityError && t('addressDataPage.invoiceCityValidationMessage')}
        onChange={e => setInvoiceCity(e.target.value)}
      />
    </div>

    <div className="button-wrapper">
      <button className="button auto-width" onClick={saveInvoiceData}>{t('addressDataPage.invoiceSave')}</button>
    </div>
  </div>

  const saveShippingData = () => {
    const fullNameError = !fullName
    const emailError = !validateEmail(email)
    const phoneError = !phone
    const countryError = !country
    const regionError = !region
    const addressError = !address
    const zipCodeError = !zipCode
    const cityError = !city

    if (
      !fullNameError
      && !emailError
      && !phoneError
      && !countryError
      && !regionError
      && !addressError
      && !zipCodeError
      && !cityError
    ) {
      if (clientData.shipping) {
        const shippingRequestBody = {
          fullName: fullName,
          phone: phone,
          email: email,
          companyName: companyName,
          country: country,
          state: region,
          address1: address,
          address2: address2,
          zipCode: zipCode,
          city: city,
        }
        axios.patch(clientData.shipping['@id'], shippingRequestBody, { 
          headers: { 
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/merge-patch+json',
          } 
        }) 
          .then(() => {
            getClientData(clientData.id, token, dispatch)
            setConfirmationModalShown(true)
          })
      }
      else {
        const shippingRequestBody = {
          user: clientData['@id'],
          fullName: fullName,
          phone: phone,
          email: email,
          companyName: companyName,
          country: country,
          state: region,
          address1: address,
          address2: address2,
          zipCode: zipCode,
          city: city,
        }
        axios.post(`api/shippings`, shippingRequestBody, { 
          headers: { 
            Authorization: `bearer ${token}`,
          } 
        }) 
          .then(() => {
            getClientData(clientData.id, token, dispatch)
            setConfirmationModalShown(true)
          })
      }
    }

    setFullNameError(fullNameError)
    setEmailError(emailError)
    setPhoneError(phoneError)
    setCountryError(countryError)
    setRegionError(regionError)
    setAddressError(addressError)
    setZipCodeError(zipCodeError)
    setCityError(cityError)
  }

  const saveInvoiceData = () => {
    const invoiceFullNameError = !invoiceFullName
    const invoiceCompanyNameError = invoiceType === 1 && !invoiceCompanyName
    const invoiceTaxNumberError = invoiceType === 1 && !invoiceTaxNumber
    const invoiceCountryError = !invoiceCountry
    const invoiceRegionError = !invoiceRegion
    const invoiceAddressError = !invoiceAddress
    const invoiceZipCodeError = !invoiceZipCode
    const invoiceCityError = !invoiceCity

    if (
      !invoiceFullNameError
      && !invoiceCompanyNameError
      && !invoiceTaxNumberError
      && !invoiceCountryError
      && !invoiceRegionError
      && !invoiceAddressError
      && !invoiceZipCodeError
      && !invoiceCityError
    ) {
      if (clientData.invoice) {
        const invoiceRequestBody = {
          fullName: invoiceFullName,
          type: invoiceType,
          country: invoiceCountry,
          state: invoiceRegion,
          address1: invoiceAddress,
          address2: invoiceAddress2,
          zipCode: invoiceZipCode,
          city: invoiceCity
        }
        if (invoiceType === 1) {
          invoiceRequestBody.companyName = invoiceCompanyName
          invoiceRequestBody.taxNumber = invoiceTaxNumber
        }
        axios.patch(clientData.invoice, invoiceRequestBody, { 
          headers: { 
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/merge-patch+json',
          } 
        }) 
          .then(() => {
            getClientData(clientData.id, token, dispatch)
            setConfirmationModalShown(true)
          })
      } else {
        const invoiceRequestBody = {
          user: clientData['@id'],
          fullName: invoiceFullName,
          type: invoiceType,
          country: invoiceCountry,
          state: invoiceRegion,
          address1: invoiceAddress,
          address2: invoiceAddress2,
          zipCode: invoiceZipCode,
          city: invoiceCity
        }
        if (invoiceType === 1) {
          invoiceRequestBody.companyName = invoiceCompanyName
          invoiceRequestBody.taxNumber = invoiceTaxNumber
        }
        axios.post(`api/invoices`, invoiceRequestBody)
          .then(() => {
            getClientData(clientData.id, token, dispatch)
            setConfirmationModalShown(true)
          })
      }
    }

    setInvoiceFullNameError(invoiceFullNameError)
    setInvoiceCompanyNameError(invoiceCompanyNameError)
    setInvoiceTaxNumberError(invoiceTaxNumberError)
    setInvoiceCountryError(invoiceCountryError)
    setInvoiceRegionError(invoiceRegionError)
    setInvoiceAddressError(invoiceAddressError)
    setInvoiceZipCodeError(invoiceZipCodeError)
    setInvoiceCityError(invoiceCityError)
  }


  return (
    <div className="address-data-page">

      <MetaTags title="Edikids - moje adresy" />

      <div className="page-content-wrapper">
        <div className="columns">
          <div className="left">
            <div className="menu">
              <div className={`menu-item ${page === 'shipping' ? 'active' : ''}`} onClick={() => setPage('shipping')}>{t('addressDataPage.shippingTab')}</div>
              <div className="menu-item empty-item" />
              <div className={`menu-item ${page === 'invoice' ? 'active' : ''}`} onClick={() => setPage('invoice')}>{t('addressDataPage.invoiceTab')}</div>
            </div>
            <div className="form-wrapper">
              { page === 'shipping' && renderShippingDataForm() }
              { page === 'invoice' && renderInvoiceDataForm() }
            </div>
          </div>
          <div className="right" />
        </div>
      </div>

      { 
        confirmationModalShown && <ConfirmationModal 
          header={t('myProfilePage.confirmationModalTitle')}
          buttonText={t('myProfilePage.confirmationModalButton')}
          closeModalHandler={() => setConfirmationModalShown(false)}
        >
        {t('myProfilePage.confirmationModalText')}
        </ConfirmationModal> 
      }
    </div>
  )
}

export default AddressDataPage
