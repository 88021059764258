import React, {Suspense} from "react";
import PropTypes from 'prop-types';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useTranslation} from "react-i18next";

export default function MetaTags(props) {
  return (
    <Suspense fallback="">
      <SuspendedMetaTags {...props} />
    </Suspense>
  )
}

function SuspendedMetaTags(props) {
  const { t } = useTranslation()

  const computedMetaTitle = props.title ?? t('seo.metaTitleDefault');
  const computedMetaDescription = props.description ?? t('seo.metaDescriptionDefault');
  const computedMetaKeywords = props.keywords ?? t('seo.metaKeywordsDefault');

  return (
    <Suspense fallback="">

      <HelmetProvider>
        <Helmet>
          <title>{computedMetaTitle}</title>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={computedMetaTitle} />
          <meta property="og:description" content={computedMetaDescription} />
          <meta name="description" content={computedMetaDescription} />
          <meta name="keywords" content={computedMetaKeywords} />
        </Helmet>

      </HelmetProvider>
    </Suspense>
  )
}

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
}