import React from "react";
import css from './BooksList.module.scss';
import PropTypes from "prop-types";
import SingleBookTile from "../../../commons/SingleBookTile";

function BooksList({ books }) {
  return (
    <>
      <div className={css.BooksList}>
        <div className={css.grid}>
          { books.map((book, bookIdx) => {
            return (
              <SingleBookTile key={book.id} book={book} bookIdx={bookIdx}/>
            )
          })
          }
        </div>
      </div>
      <div className={css.frills} />
    </>
  )
}

BooksList.propTypes = {
  books: PropTypes.arrayOf(PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }))
}

export default BooksList;