import React from 'react'
import './DeactivateModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { default as axios } from '../../../../../axiosSettings'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


function DeactivateModal(props) {
  const { productId, closeModalHandler } = props
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const deactivateProduct = () => {
    axios.patch(`api/books/${productId}`, {
      status: 0,
    }, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        closeModalHandler(true)
        props.history.push('/admin/products')
      })
  }
 
  return (
    <div className="deactivate-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('productDetailsPage.deactivateModalTitle')}</h2>

        <div className="mt24">{t('productDetailsPage.deactivateModalText1')} <strong>{t('productDetailsPage.deactivateModalText2')}</strong>.</div>

        <div className="button-wrapper mt64">
          <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('productDetailsPage.deactivateModalCancelButton')}</button>
          <button className="button auto-width" onClick={deactivateProduct}>{t('productDetailsPage.deactivateModalConfirmButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(DeactivateModal)
