import React, { useState } from 'react'
import './PasswordInput.scss'
import showIcon from '../../../assets/show.svg'
import hideIcon from '../../../assets/hide.svg'


function PasswordInput({
  label,
  value,
  placeholder,
  error,
  onChange,
}) {
  const [passwordShown, setPasswordShown] = useState(false)

  return (
    <div className="password-input">
      <div className="input-label">{label}</div>
      <input
        className={error ? 'red-border': ''}
        type={passwordShown ? 'text' : 'password'}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      { !passwordShown && <img className="password-icon" src={showIcon} alt="show password" onClick={() => setPasswordShown(true)} /> }
      { passwordShown && <img className="password-icon" src={hideIcon} alt="hide password" onClick={() => setPasswordShown(false)} /> }
      <div className="input-error">{error}</div>
    </div>
  )
}

export default PasswordInput