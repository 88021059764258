import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Breadcrumps from '../../../commons/Breadcrumps/Breadcrumps'
import './StandardBookDetailsPage.scss'
import { default as axios } from '../../../../axiosSettings'
import { serverURL } from '../../../../config'
import ItemsCounter from '../../../commons/ItemsCounter/ItemsCounter'
import prevArrow from '../../../../assets/prev_arrow.svg'
import nextArrow from '../../../../assets/next_arrow.svg'
import SimilarProducts from './SimilarProducts/SimilarProducts'
import { useStateValue } from '../../../../state/state'
import { useTranslation } from 'react-i18next'
import MetaTags from "../../../commons/MetaTags/MetaTags";
import {notifyGTMPageOpened} from "../../../../helper/tracking/notifyGTMPageOpened";
import {notifyGoogleTagManagerEvent} from "../../../../helper/tracking/notifyGoogleTagManagerEvent";


function StandardBookDetailsPage(props) {
  const [{ cart }, dispatch] = useStateValue()
  const [productData, setProductData] = useState([])
  const [itemsCount, setItemsCount] = useState(1)
  const [selectedImage, setSelectedImage] = useState('main')
  const [isButtonLocked, setIsButtonLocked] = useState(false)
  const [isSaleAvailable, setSaleAvailable] = useState(false)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    let url = `api/books/${props.match.params.id}`
    axios.get(url)
      .then(response => {
        setProductData(response.data)
        setSaleAvailable(!!response.data?.status)
      })
      .catch(()=>{
        setSaleAvailable(false)
      })
  }, [props.match.params.id])

  useEffect(() => {
    setItemsCount(1)
  }, [props.match.params.id])

  useEffect(() => {
    if (isButtonLocked) {
      setInterval(() => {
        setIsButtonLocked(false)
      }, 5000)
    }
  }, [isButtonLocked])

  const addToCart = () => {
    const newCart = [
      ...cart,
      {
        id: productData.id,
        count: itemsCount,
        productData: productData,
      }
    ]
    dispatch({ type: 'setData', field: 'cart', data: newCart })
    sessionStorage.setItem('cart', JSON.stringify(newCart))
    setIsButtonLocked(true)
  }

  const changeImagePreview = (value) => {
    const additionalImagesCount = productData.bookAdditionalImages.length
    if (value === 'next') {
      if (selectedImage === 'main' && additionalImagesCount > 0) setSelectedImage(0)
      if (typeof selectedImage === 'number' && selectedImage < additionalImagesCount - 1) setSelectedImage(selectedImage + 1)
      if (typeof selectedImage === 'number' && selectedImage >= additionalImagesCount - 1) setSelectedImage('main')
    }
    else if (value === 'prev') {
      if (selectedImage === 'main' && additionalImagesCount > 0) setSelectedImage(additionalImagesCount - 1)
      if (typeof selectedImage === 'number' && selectedImage > 0) setSelectedImage(selectedImage - 1)
      if (typeof selectedImage === 'number' && selectedImage <= 0) setSelectedImage('main')
    }
  }

  const computedMetaTitle = t('seo.titleTemplate.standardBookDetailsPage', {
    metaTitle: typeof (productData.metaTitle)==='string' && productData.metaTitle.length > 0
      ? productData.metaTitle
      : (productData?.title?.substring(0,250) ?? t('seo.metaTitleDefault'))
  });
  const computedMetaDescription = productData?.metaDescription ?? productData?.description?.substring(0,250) ?? t('seo.metaDescriptionDefault');
  const computedMetaKeywords = productData?.metaKeywords ?? t('seo.metaKeywordsDefault');

  useEffect(()=>{
    notifyGTMPageOpened(i18n.language);
    notifyGoogleTagManagerEvent('książka standardowa', productData.title);
  }, [productData.title]);


  return (
    <div className="standard-book-details-page">

      <MetaTags
        title={computedMetaTitle}
        description={computedMetaDescription}
        keywords={computedMetaKeywords}
      />

      <section className="content-wrapper">
        <div className="breadcrupms-wrapper">
          <Breadcrumps itemName={productData.title} />
        </div>

        <div className="columns">
          <div className="left">
            <div className="thumbnails">
            { productData.bookMainImage && productData.bookMainImage.filePath && <img
              src={serverURL + productData.bookMainImage.filePath}
              alt="cover"
              onClick={() => setSelectedImage('main')}
            /> }
            {
              productData.bookAdditionalImages && productData.bookAdditionalImages.map((image, i) => <img
                key={i}
                src={serverURL + image.filePath}
                alt=""
                onClick={() => setSelectedImage(i)}
               />)
            }
            </div>
            <div className="main-image-wrapper">
              <div className="arrow arrow-left">
                <img src={prevArrow} alt="previous" onClick={() => changeImagePreview('prev')} />
              </div>
              { productData.bookMainImage && productData.bookMainImage.filePath && selectedImage === 'main'
                && <img className="main-image" src={serverURL + productData.bookMainImage.filePath} />
              }
              { productData.bookAdditionalImages
                && productData.bookAdditionalImages[selectedImage]
                && productData.bookAdditionalImages[selectedImage].filePath
                && selectedImage !== 'main'
                && <img className="main-image" src={serverURL + productData.bookAdditionalImages[selectedImage].filePath} />
              }
              <div className="arrow arrow-right">
                <img src={nextArrow} alt="next" onClick={() => changeImagePreview('next')} />
              </div>
            </div>
          </div>
          <div className="right">
            <div className="book-title">{productData.title}</div>
            <div className="book-price-line">
              <span className="book-price">{!!productData.price && `${(parseFloat(productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</span>
              <span className="book-price-label">{t('standardBookDetailsPage.vatText')}</span>
            </div>

            <div className="items-count-wrapper">
              {isSaleAvailable &&
                <ItemsCounter
                  label={t('standardBookDetailsPage.countLabel')}
                  value={itemsCount}
                  setValue={setItemsCount}
                />
              }
            </div>

            <div className="button-wrapper">
              {isSaleAvailable &&
                <button
                  className={`button rounded ${isButtonLocked ? 'button-success' : ''}`}
                  onClick={addToCart}
                >
                  { isButtonLocked ? t('standardBookDetailsPage.successButton') : t('standardBookDetailsPage.addButton')}
                </button>
              }
              {!isSaleAvailable &&
                <button
                  className={`button rounded button-disabled`}
                  onClick={null}
                >
                  {t('standardBookDetailsPage.forbiddenButton')}
                </button>
              }
            </div>
          </div>
        </div>
      </section>

      <section className="content-wrapper with-background desctiption-section">
        <div className="description-title">{t('standardBookDetailsPage.desctiptionLabel')}</div>
        <div>{productData.description}</div>
      </section>

      <section className="content-wrapper similar-products-section">
        <h2>{t('standardBookDetailsPage.similarProductsHeader')}</h2>
        <SimilarProducts />
      </section>
    </div>
  )
}

export default withRouter(StandardBookDetailsPage)
