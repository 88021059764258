import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { default as axios } from './axiosSettings'
import {CLIENT_ID, REFRESH_TOKEN, Storage, TOKEN, USER_ID} from "./helper/storage/Storage";

export const onloadCallback = () => null
window.onloadCallback = onloadCallback

export const ScrollToTop = () =>{
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  setTimeout(() => { // timeout needed to override default back button behavior
    window.scrollTo(0, 0)
  }, 0)

  return null
}

export const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePassword = password => {
  // eslint-disable-next-line
  const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#\$%\^&\*\(\)_\=\+\|{},.<>?~`;:'\"\/])(?=.{8,})")
  return re.test(String(password))
}

export const getQueryVariable = variable => {
  var query = window.location.search.substring(1)
  var vars = query.split("&")
  for (let i=0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    if (pair[0] == variable){
      return pair[1]
    }
  }
  return false
}

export const checkTokenValidity = () => {
  setInterval(
    () => {
      const token = Storage.get(TOKEN);
      const refreshToken = Storage.get(REFRESH_TOKEN);

      if (token) {
        return null
      }
      else if (!token && refreshToken) {
        axios.post(`api/token/refresh`, {
          refresh_token: refreshToken,
        })
          .then(response => {
            if (response.status === 200) {
              Storage.setToken(response.data.token);
              Storage.set(REFRESH_TOKEN, response.data.refresh_token);
            }
          })
      }
      else if (!token && !refreshToken) {
        Storage.remove(USER_ID);
      }
    }, 1000
  )
}

export const getUserData = (userId, token, dispatch) => {
  if (!userId) return
  if (!token) return
  axios.get(`api/users/${userId}`, {
    headers: { 
      Authorization: `bearer ${token}`,
    }
  })
    .then(response => {
      dispatch({ type: 'setData', field: 'userData', data: response.data })
    })
}

export const getClientData = (userId, token, dispatch) => {
  if (!userId) return
  if (!token) return
  axios.get(`api/users/${userId}`, {
    headers: { 
      Authorization: `bearer ${token}`,
    }
  })
    .then(response => {
      dispatch({ type: 'setData', field: 'clientData', data: response.data })
    })
}

export const getPritingHouses = (token, dispatch) => {
  if (!token) return
  axios.get(`api/users?roles=ROLE_PRINTINGHOUSE`, {
    headers: { 
      Authorization: `bearer ${token}`,
    } 
  })
    .then(response => {
      dispatch({ 
        type: 'setData', 
        field: 'printingHouseOptions', 
        data: response.data['hydra:member'].map(option => { 
          return { 
            value: option.id, 
            label: option.organization
          }
        })
      })
    })
}