import React from 'react'
import './RemoveWwwVoucherModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { useTranslation } from 'react-i18next'


function RemoveWwwVoucherModal({
  closeModalHandler,
  handleRemoveCoupon,
}) {
  const { t } = useTranslation()

  return (
    <div className="remove-www-voucher-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('wwwCouponPage.removeModalTitle')}</h2>
        <div className="mt24">{t('wwwCouponPage.removeModalText')}</div>
        <div className="button-wrapper mt64">
          <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('wwwCouponPage.removeModalCancelButton')}</button>
          <button className="button auto-width" onClick={handleRemoveCoupon}>{t('wwwCouponPage.removeModalConfirmButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default RemoveWwwVoucherModal
