import React, { useState } from 'react'
import './EditUserModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import TextInput from '../../../../commons/TextInput/TextInput'
import SelectInput from '../../../../commons/SelectInput/SelectInput'
import { validateEmail, validatePassword, getPritingHouses } from '../../../../../utils'
import PasswordInput from '../../../../commons/PasswordInput/PasswordInput'
import { default as axios } from '../../../../../axiosSettings'
import { useStateValue } from '../../../../../state/state'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN, USER_ID} from "../../../../../helper/storage/Storage";


function EditUserModal({
  userData,
  closeModalHandler,
  refreshUsersList,
}) {
  const [, dispatch] = useStateValue()
  const [email, setEmail] = useState(userData.email || '')
  const [emailError, setEmailError] = useState('')
  const [organization, setOrganization] = useState(userData.organization || '')
  const [organizationError, setOrganizationError] = useState('')
  const [role, setRole] = useState(userData.roles[0] || userRoles[0].value)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);
  const currentUserId = Storage.get(USER_ID);
  const isMe = userData.id == currentUserId
  
  const userRoles = [
    { value: 'ROLE_ADMIN', label: t('manageUserModals.roleSuperAdmin') },
    { value: 'ROLE_CREATOR', label: t('manageUserModals.roleCreator') },
    { value: 'ROLE_PRINTINGHOUSE', label: t('manageUserModals.rolePrintingHouse') },
  ]

  const handleSaveUser = () => {
    const emailError = !validateEmail(email)
    const organizationError = !organization
    const passwordError = password && !validatePassword(password)

    if (
      !emailError
      && !organizationError
      && !passwordError
    ) {
      const requestBody = {
        organization,
        roles: [role],
      }
      if (email !== userData.email) requestBody.email = email
      if (isMe && password) requestBody.plainPassword = password
      axios.patch(`api/users/${userData.id}`, requestBody, { 
        headers: { 
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        } 
      })
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            closeModalHandler()
            refreshUsersList()
            getPritingHouses(token, dispatch)
          }
        })
    }

    setEmailError(emailError)
    setOrganizationError(organizationError)
    setPasswordError(passwordError)
  }
 
  return (
    <div className="edit-user-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2><center>{t('manageUserModals.editUserModalHeader')}</center></h2>

        <div className="input-wrapper mt48">
          <TextInput
            label={t('manageUserModals.emailLabel')}
            placeholder={t('manageUserModals.emailPlaceholder')}
            value={email}
            error={emailError && t('manageUserModals.emailValidationMessage')}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="input-wrapper mt24">
          <TextInput
            label={t('manageUserModals.organizationLabel')}
            placeholder={t('manageUserModals.organizationPlaceholder')}
            value={organization}
            error={organizationError && t('manageUserModals.organizationValidationMessage')}
            onChange={e => setOrganization(e.target.value)}
          />
        </div>

        <div className="input-wrapper mt24">
          <SelectInput
            label={t('manageUserModals.roleLabel')}
            options={userRoles}
            value={role}
            error={null}
            placeholder={t('manageUserModals.selectPlaceholder')}
            onChange={e => setRole(e.value)}
          />
        </div>

        {
          isMe && <div className="input-wrapper mt24">
            <PasswordInput
              label={t('manageUserModals.passwordLabel')}
              value={password}
              placeholder={t('manageUserModals.passwordPlaceholder')}
              error={passwordError && t('manageUserModals.passwordValidationMessage')}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        }

        <div className="button-wrapper mt64">
          <button className="button" onClick={handleSaveUser}>{t('manageUserModals.saveChangesModal')}</button>
        </div>
      </div>
    </div>
  )
}

export default EditUserModal
