import React from 'react'
import './MultipleSelectInput.scss'
import Select from 'react-select'


function MultipleSelectInput({
  label,
  options,
  value,
  placeholder,
  error,
  onChange,
}) {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: error ? '1px solid #D21C1C' : (state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB'),
      boxShadow: error ? '1px solid #D21C1C' : (state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB'),
      minHeight: 44,
      borderRadius: 3,
      '&:hover': {
        borderColor: error ? '#D21C1C' : '#DCDFEB',
        boxShadow: 'none',
      },
    }),
    placeholder: (provieded) => ({
      ...provieded,
      color: '#95A4AE',
    }),
    indicatorSeparator: () => null,
    clearIndicator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: 'rgba(19, 156, 255, 0.15)',
    }),
    multiValueLabel: base => ({
      ...base,
    }),
    multiValueRemove: base => ({
      ...base,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        borderRadius: '0 2px 2px 0',
        color: '#000',
      }
    }),
  }

  return (
    <div className="multiple-select-input">
      { label && <div className="input-label">{label}</div> }
      <Select 
        isMulti
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        value={options.find(option => option.value === value)}
        onChange={onChange}
      />
      <div className="input-error">{error}</div>
    </div>
  )
}

export default MultipleSelectInput