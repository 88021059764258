import React, { useState, useEffect, useRef } from 'react'
import './KidPhotoDragAndDrop.scss'
import { MAX_FILE_SIZE, MIN_FILE_SIZE } from '../../../config'
import folderIcon from '../../../assets/folder_icon.svg'
import 'react-image-crop/lib/ReactCrop.scss'
import { useTranslation } from 'react-i18next'
import CropperModal from "./CropperModal";


function KidPhotoDragAndDrop({
  componentId,
  uploadedFile,
  setUploadedFile,
  error,
  setEditedImage,
}) {
  const [dragActive, setDragActive] = useState(false)
  const [fileUploadWarning, setFileUploadWarning] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)
  const { t } = useTranslation()

  const dropRef = useRef(null)
  let dragCounter = 0

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragActive(true)
    }
  }
  
  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter--
    if (dragCounter === 0) {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleUpload(e.dataTransfer.files)
      e.dataTransfer.clearData()
      dragCounter = 0
    }
  }
  
  const handleUpload = (files) => {
    if (files[0] && files[0].name) {
      if (files[0].size > MAX_FILE_SIZE) { // validate image size
        setFileUploadWarning(t('personalizePage.imageTooBigMessage'))
      } 
      else if (files[0].size < MIN_FILE_SIZE) { // validate image size
        setFileUploadWarning(t('personalizePage.imageTooSmallMessage'))
      } 
      else if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') { // validate image type
        setFileUploadWarning('Niepoprawny plik. Spróbuj wgrać inny.')
      }
      else {
        const imageToUpload = files[0]
        setUploadedFile(imageToUpload)
        setFileUploadWarning(null)
        setIsEditModalOpen(true)
      }

      if (document.querySelector('#file')) {
        document.querySelector('#file').value = null
      }
    }
  }

  useEffect(() => {
    dropRef.current.addEventListener('dragenter', handleDragIn)
    dropRef.current.addEventListener('dragleave', handleDragOut)
    dropRef.current.addEventListener('dragover', handleDrag)
    dropRef.current.addEventListener('drop', handleDrop)
  
    return () => {
      dropRef.current.removeEventListener('dragenter', handleDragIn)
      dropRef.current.removeEventListener('dragleave', handleDragOut)
      dropRef.current.removeEventListener('dragover', handleDrag)
      dropRef.current.removeEventListener('drop', handleDrop)
    }
  }, [])
  
  const setImage = (imageUrl) => {
    const image = new Image()
    image.onload = function() {
      setCroppedImage(imageUrl)
      setEditedImage(imageUrl)
      setIsEditModalOpen(false)
    }
    image.src = imageUrl
  }

  return (
    <div className="kid-photo-drag-and-drop">
      <div className={`drag-and-drop-input ${error ? 'red-border' : ''} ${uploadedFile ? 'blue-background' : ''}`} ref={dropRef}>
        {
          dragActive && <div className="drop-active-overlay">wgraj zdjęcie</div>
        }
        {
          !uploadedFile && <span className="browse-button">
            <input type="file" name={componentId} id={componentId} className="inputfile" accept="image/png, image/jpg, image/jpeg" onChange={e => handleUpload(e.target.files)} />
            <label htmlFor={componentId}>
              <img src={folderIcon} alt="folder" />
              <div>{t('personalizePage.photoUploadPlaceholder')}</div>
            </label>
          </span>
        }
        {
          uploadedFile && <div className="after-upload-features">
            <div className="left">
              <img src={croppedImage || uploadedFile} className="file-preview mr8" alt="file preview" />
            </div>
            <div className="right">
              <span className="browse-button">
                <input type="file" name={componentId} id={componentId} className="inputfile" accept="image/png, image/jpg, image/jpeg" onChange={e => handleUpload(e.target.files)} />
                <label htmlFor={componentId}>{t('personalizePage.changePhotoText')}</label>
              </span>
            </div>
          </div>
        }
      </div>

      { fileUploadWarning && <div className="input-error">{fileUploadWarning}</div>}
      { error && <div className="input-error">{error}</div>}

      <CropperModal
        isOpen={isEditModalOpen}
        uploadedFile={uploadedFile}
        onClose={()=>setIsEditModalOpen(false)}
        onSave={(image)=>setImage(image)}
      />

    </div>
  )
}

export default KidPhotoDragAndDrop
