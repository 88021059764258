import { initialState } from './initialState'

export const reducer = (state, action) => {
  switch (action.type) {

    case 'setData':
      return {
        ...state,
        [`${action.field}`]: action.data,
      }

    case 'setBookData':
      return {
        ...state,
        bookData: {
          ...state.bookData,
          [`${action.field}`]: action.data,
        }
      }

    case 'setBookPageData':
      return {
        ...state,
        bookData: {
          ...state.bookData,
          pages: state.bookData.pages.map(page => {
            if (page.number === action.number) {
              return {
                ...page,
                [`${action.field}`]: action.data,
              }
            } else {
              return page
            }
          })
        }
      }

    case 'resetBookData':
      return {
        ...state,
        creatorPage: initialState.creatorPage,
        bookData: initialState.bookData,
      }
      
    default:
      return state
  }
}