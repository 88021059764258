import React, { useState } from 'react'
import './CartPage.scss'
import { useStateValue } from '../../../../state/state'
import { Link } from 'react-router-dom'
import ItemsCounter from '../../../commons/ItemsCounter/ItemsCounter'
import { serverURL } from '../../../../config'
import trashIconRed from '../../../../assets/trash_icon_red.svg'
import { useTranslation } from 'react-i18next'
import MetaTags from "../../../commons/MetaTags/MetaTags";


const currencyMap = {
  0: '%',
  1: 'zł',
  2: '€',
}

function CartPage(props) {
  const [{ cart, clientData }, dispatch] = useStateValue()
  const [discountValue, setDiscountValue] = useState(null)
  const [discountCurrency, setDiscountCurrency] = useState(null)
  const [discountMinOrderValue, setDiscountMinOrderValue] = useState(null)
  const standardProducts = cart.filter(item => !item.productData.isCustomizable)
  const personalizedProducts = cart.filter(item => item.productData.isCustomizable)
  const { i18n, t } = useTranslation()

  const gendersMap = {
    'boy': t('cartPage.boy'),
    'girl': t('cartPage.girl'),
  }

  const getTotalPrice = () => {
    let totalPrice = 0
    cart.forEach(cartItem => {
      totalPrice += cartItem.count * cartItem.productData.price
    })
    return (parseFloat(totalPrice)/100).toFixed(2)
  }
  const totalPrice = getTotalPrice()

  const removeCartItem = (index) => {
    const newCart = cart.filter((item, i) => i !== index)
    dispatch({ type: 'setData', field: 'cart', data: newCart })
    sessionStorage.setItem('cart', JSON.stringify(newCart))
  }

  const changeItemCount = (index, newValue) => {
    const newCart = cart.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          count: newValue,
        }
      } else {
        return item
      }
    })
    dispatch({ type: 'setData', field: 'cart', data: newCart })
    sessionStorage.setItem('cart', JSON.stringify(newCart))
  }

  const calculateFinalPrice = () => {
    let price = parseFloat(totalPrice)
    if (discountValue && totalPrice >= (discountMinOrderValue/100)) {
      if (discountCurrency === 0) { // %
        price *= 1-(discountValue/10000)
      }
      else if (discountCurrency === 1) { // pln
        price -= parseFloat(discountValue/100)
      }
      else if (discountCurrency === 2) { // eur
        price -= parseFloat(discountValue/100)
      }
    }
    return `${(price).toFixed(2)} ${i18n.language === 'pl' ? 'zł' : '€'}`
  }

  const renderStandardProducts = () => <section className="standard-product-section">
    <div>{t('cartPage.standardBookSectionTitle')}</div>
    {
      cart.map((item, i) => {
        if (item.productData.isCustomizable) return null
        return <div key={i} className="standard-item-box">
          <div className="columns-wrapper">
            { item.productData && item.productData.bookMainImage && <img src={serverURL + item.productData.bookMainImage.filePath} alt="cover" /> }
            <div className="product-data">
              <div>
                <div className="product-title">{item.productData.title}</div>
                <div className="product-type">{t('cartPage.standardBookType')}</div>
              </div>
              <div className="remove-button medium-and-big-screens">
                <img src={trashIconRed} alt="remove item" />
                <span onClick={() => removeCartItem(i)}>{t('cartPage.removeButton')}</span>
              </div>
            </div>
            <div className="items-counter-wrapper medium-and-big-screens">
              <ItemsCounter 
                value={item.count}
                setValue={(newValue) => changeItemCount(i, newValue)} 
              />
            </div>
            <div className="prudct-price medium-and-big-screens">
              {!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}
            </div>
          </div>

          <div className="product-controls-mobile">
            <div className="remove-button">
              <img src={trashIconRed} alt="remove item" />
              <span onClick={() => removeCartItem(i)}>{t('cartPage.removeButton')}</span>
            </div>
            <div>
              <div className="items-counter-wrapper">
                <ItemsCounter 
                  value={item.count}
                  setValue={(newValue) => changeItemCount(i, newValue)} 
                />
              </div>
              <div className="prudct-price">
                {!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}
              </div>
            </div>
          </div>
        </div>
      })
    }
  </section>

  const renderPersonalizedProducts = () => <section className="personalized-products-section">
    <div>{t('cartPage.personalizedBookSectionTitle')}</div>
    {
      cart.map((item, i) => {
        if (!item.productData.isCustomizable) return null
        return <div key={i} className="personalized-item-box">
          <div className="box-columns">
            <img src={serverURL + item.productData.bookMainImage.filePath} alt="cover" />
            <div className="product-data">
              <div>
                <div className="product-title">{item.productData.title}</div>
                <div className="product-type">{t('cartPage.personalizedBookType')}</div>
              </div>
              <div className="remove-button medium-and-big-screens">
                <img src={trashIconRed} alt="remove item" />
                <span onClick={() => removeCartItem(i)}>{t('cartPage.removeButton')}</span>
              </div>
            </div>
            <div className="items-counter-wrapper medium-and-big-screens">
              <ItemsCounter 
                value={item.count}
                setValue={(newValue) => changeItemCount(i, newValue)} 
              />
            </div>
            <div className="prudct-price medium-and-big-screens">{!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
          </div>

          <div className="personalization-details">
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('cartPage.nameLabel')}</span>
              <span className="personalization-details-line-text">{item.kidName}</span>
            </div>
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('cartPage.genderLabel')}</span>
              <span className="personalization-details-line-text">{gendersMap[item.kidGender]}</span>
            </div>
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('cartPage.dedicationLabel')}</span>
              <span className="personalization-details-line-text">{item.dedication}</span>
            </div>
          </div>

          <div className="product-controls-mobile">
            <div className="remove-button">
              <img src={trashIconRed} alt="remove item" />
              <span onClick={() => removeCartItem(i)}>{t('cartPage.removeButton')}</span>
            </div>
            <div>
              <div className="items-counter-wrapper">
                <ItemsCounter 
                  value={item.count}
                  setValue={(newValue) => changeItemCount(i, newValue)} 
                />
              </div>
              <div className="prudct-price">
                {!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}
              </div>
            </div>
          </div>
        </div>
      })
    }
  </section>

  return (
    <div className="cart-page">

      <MetaTags title="Edikids - koszyk" />

      <div className="page-content">
        
        <div className="left-area">
          <div className="left-area-background" />
          <h2>{t('cartPage.itemsHeader')} ({cart.length})</h2>

          { standardProducts.length ? renderStandardProducts() : null }
          { personalizedProducts.length ? renderPersonalizedProducts() : null }

          <section className="shipping-info-section">
            <h3>{t('cartPage.shippingSectionTitle')}</h3>
            <div className="shipping-info-box">
              <p>{t('cartPage.shippingSectionText')}</p>
            </div>
          </section>
        </div>

        <div className="right-area">
          <div className="right-area-background" />
          <h2>{t('cartPage.summaryHeader')}</h2>
          <div className="line">
            <div className="line-label">{t('cartPage.productsValue')}</div>
            <div className="line-value">{totalPrice} {i18n.language === 'pl' ? 'zł' : '€'}</div>
          </div>
          {
            discountValue && totalPrice >= discountMinOrderValue/100 && <div className="line">
              <div className="line-label">{t('cartPage.couponText')}</div>
              <div className="line-value value-green">-{(parseFloat(discountValue)/100).toFixed(2)} {currencyMap[discountCurrency]}</div>
            </div>
          }
          <hr/>
          <div className="line line-bold">
            <div className="line-label">{t('cartPage.totalValue')}</div>
            <div className="line-value">{calculateFinalPrice()}</div>
          </div>
          <div className="line">{t('cartPage.couponInfo')}</div>
          <div className="button-wrapper">
            <Link className="button" to={{pathname: clientData.id ? '/order-data' : '/login', state: { prevPath: props.location.pathname }}}>{t('cartPage.moveToPaymentButton')}</Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CartPage
