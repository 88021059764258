import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiConnector from "../../../ApiConnector";
import css from './Banner.module.scss'


function Banner() {
  const [bannerText, setBannerText] = useState(null)
  const { i18n } = useTranslation()

  useEffect(() => {
    if (i18n.language) {
      ApiConnector.getBanner().then(result => {
        setBannerText(result[i18n.language])
      })
    }
  }, [i18n.language])

  if (!bannerText) {
    return <></>
  } else {
    return (
      <div className={css.bannerComponent}>
        {bannerText}
      </div>
    )
  }
}

export default Banner
