import React from 'react'
import './RemoveClientModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


function RemoveClientModal({
  clientData,
  closeModalHandler,
  refreshClientsList,
}) {
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const handleRemoveClient = () => {
    axios.delete(`api/users/${clientData.id}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        if (response.status === 204) {
          closeModalHandler()
          refreshClientsList()
        }
      })
  }
 
  return (
    <div className="remove-client-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('clientsPage.removeConfirmationModalTitle')}</h2>

        <div className="mt24">{t('clientsPage.removeConfirmationModalText')}</div>

        <div className="button-wrapper mt64">
          <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('clientsPage.removeConfirmationModalCancelButton')}</button>
          <button className="button auto-width" onClick={handleRemoveClient}>{t('clientsPage.removeConfirmationModalConfirmButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default RemoveClientModal
