import React from 'react'
import './CalendarInput.scss'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import calendarIcon from '../../../assets/calendar_icon.svg'


function CalendarInput({
  label,
  value,
  placeholder,
  error,
  onChange,
}) {
  const format = "DD/MM/YYYY"

  return (
    <div className="calendar-input">
      <div className="input-label">{label}</div>
      <div className={`day-picker-wrapper ${error ? 'red-border' : ''}`}>
        <DayPickerInput 
          dayPickerProps={{localeUtils: MomentLocaleUtils, locale: 'pl'}}
          value={value && formatDate(new Date(value), format)}
          format={format}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={placeholder}
          onDayChange={onChange} 
        />
        <img src={calendarIcon} alt="" />
      </div>
      <div className="input-error">{error}</div>
    </div>
  )
}

export default CalendarInput