import React, { useState, useEffect } from 'react'
import './RenewPaymentPage.scss'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import { CLIENT_ID_PLN, CLIENT_ID_EUR, serverURL } from '../../../../config'


function RenewPaymentPage(props) {
  const { i18n } = useTranslation()

  useEffect(() => {
    axios.get(`api/orders/${props.match.params.id}`)
      .then(response => {
        const orderData = response.data

        if (orderData.language !== 'es' && orderData.payment && orderData.payment.status !== 'CANCELED') { // payU payment was successful
          props.history.push('/')
          return
        }
        else if (orderData.language === 'es' && orderData.payment && orderData.payment.status === '0000') { // redsys payment was successful
          props.history.push('/')
          return
        }

        const subOrdersStandard = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 0)
        const subOrdersPersonalized = orderData.subOrders && orderData.subOrders.filter(subOrder => subOrder.type === 1)
        const subOrdersPosition = []
        if (subOrdersStandard && subOrdersStandard.length) subOrdersPosition.push(...subOrdersStandard[0].orderPositions)
        if (subOrdersPersonalized && subOrdersPersonalized.length) subOrdersPosition.push(...subOrdersPersonalized[0].orderPositions)

        const paymentData = {
          customerIp: "127.0.0.1",
          merchantPosId: i18n.language === 'pl' ? CLIENT_ID_PLN : CLIENT_ID_EUR,
          description: "Ediba - zamówienie",
          currencyCode: i18n.language === 'pl' ? "PLN" : 'EUR',
          totalAmount: orderData.totalCost,
          notifyUrl: `${serverURL + '/api/payment/notify/payu'}`,
          extOrderId: `${orderData.id}-${new Date().getTime()}`,
          buyer: {
            email: orderData.shipping.email,
            phone: orderData.shipping.phone,
            firstName: orderData.shipping.fullName.split(' ')[0],
            lastName: orderData.shipping.fullName.split(' ').length > 1 ? orderData.shipping.fullName.split(' ')[1] : '',
            language: i18n.language === 'pl' ? "pl" : 'en',
          },
          products: subOrdersPosition.map(item => { return {
            name: item.book.title,
            unitPrice: item.book.price,
            quantity: item.amount,
          }})
        }

        if (orderData.language === 'es') {
          const extOrderId = `${orderData.id}-${new Date().getTime()}`
          axios.post(`api/payment/redsys-payment`, {
            currencyCode : "EUR",
            totalAmount : parseFloat(orderData.totalCost/100).toFixed(2),
            language : "ES",
            fullName : orderData.shipping.fullName,
            description : extOrderId,
            extOrderId : extOrderId,
            orderId: `${orderData.id}${new Date().getTime()}`.substr(0, 12)
            // orderId: extOrderId,
          })
            .then(response => {
              document.write(response.data)
            })
        } else {
          axios.post(`api/payment/validate/payu`, paymentData)
          .then(response => {
            window.location.replace(response.data.redirectUri)
          })
        }
      })
  }, [])

  return (
    <div className="renew-payment-page" />
  )
}

export default RenewPaymentPage
