import React from 'react'
import './PublishModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { useTranslation } from 'react-i18next'


function PublishModal({
  saveAndPublishHandler,
  closeModalHandler
}) {
  const { t } = useTranslation()
 
  return (
    <div className="publish-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('productDetailsPage.saveAndPublishModalTitle')}</h2>

        <div className="mt24">{t('productDetailsPage.saveAndPublishModalText1')} <strong>{t('productDetailsPage.saveAndPublishModalText2')}</strong> {t('productDetailsPage.saveAndPublishModalText3')}</div>

        <div className="button-wrapper mt64">
          <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('productDetailsPage.saveAndPublishModalCancelButton')}</button>
          <button className="button auto-width" onClick={saveAndPublishHandler}>{t('productDetailsPage.saveAndPublishModalConfirmButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default PublishModal
