import React, { useState, useEffect } from 'react'
import './BookDetails.scss'
import TextInput from '../../../../../commons/TextInput/TextInput'
import TextareaInput from '../../../../../commons/TextareaInput/TextareaInput'
import SelectInput from '../../../../../commons/SelectInput/SelectInput'
import { useStateValue } from '../../../../../../state/state'
import { coverFontOptions, innerFontOptions } from '../../../../../../consts'
import DragAndDrop from '../../../../../commons/DragAndDrop/DragAndDrop'
import { default as axios } from '../../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../../helper/storage/Storage";


function BookDetails() {
  const [{ bookData, marketOptions, printingHouseOptions }, dispatch] = useStateValue()
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [marketError, setMarketError] = useState(false)
  const [printingHouseError, setPrintingHouseError] = useState(false)
  const [isbnError, setIsbnError] = useState(false)
  const [mainImageError, setMainImageError] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    if (marketOptions.length) {
      setTimeout(() => {
        dispatch({ type: 'setBookData', field: 'market', data: marketOptions[0].value })
      }, 0)
    }
  }, [marketOptions, dispatch])

  const validateInputs = () => {
    const titleError = !bookData.title
    const descriptionError = !bookData.description
    const marketError = !bookData.market
    const printingHouseError = !bookData.printingHouse
    const isbnError = !bookData.isbn
    const mainImageError = !bookData.mainImage

    if (
      !titleError
      && !descriptionError
      && !marketError
      && !printingHouseError
      && !isbnError
      && !mainImageError
    ) {
      dispatch({ type: 'setData', field: 'creatorPage', data: 'pagesEditor' })
      window.scrollTo(0, 0)
    }
  
    setTitleError(titleError)
    setDescriptionError(descriptionError)
    setMarketError(marketError)
    setPrintingHouseError(printingHouseError)
    setIsbnError(isbnError)
    setMainImageError(mainImageError)
  }

  const handleImageChange = (value) => {
    if (!value) {
      removeExistingImage(bookData.mainImage.id)
    } else if (value && bookData.mainImage) {
      replaceImage(bookData.mainImage.id, value)
    } else if (value && !bookData.mainImage) {
      uploadNewImage(value)
    }
  }

  const uploadNewImage = (value) => {
    const formData = new FormData()
    formData.append('file', value)
    axios.post(`api/book_main_images`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      } 
    })
      .then(response => {
        if (response.status === 201) {
          dispatch({ type: 'setBookData', field: 'mainImage', data: response.data })
        }
      })
  }

  const removeExistingImage = (imageId) => {
    axios.delete(`api/book_main_images/${imageId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        if (response.status === 204) {
          dispatch({ type: 'setBookData', field: 'mainImage', data: null })
        }
      })
  }

  const replaceImage = (imageId, value) => {
    axios.delete(`api/book_main_images/${imageId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
    uploadNewImage(value)
  }

  return (
    <div className="book-details">

      <div className="details-box">
        <h2><center>{t('addPersopnalizedBookPage.header')}</center></h2>
        
        <div className="input-wrapper">
          <TextInput 
            label={t('addPersopnalizedBookPage.titleLabel')}
            placeholder={t('addPersopnalizedBookPage.titlePlaceholder')}
            value={bookData.title}
            error={titleError && t('addPersopnalizedBookPage.titleValidationMessage')}
            onChange={e => dispatch({ type: 'setBookData', field: 'title', data: e.target.value })}
          />
        </div>
        <div className="input-wrapper">
          <TextareaInput 
            label={t('addPersopnalizedBookPage.descriptionLabel')}
            placeholder={t('addPersopnalizedBookPage.descriptionPlaceholder')}
            value={bookData.description}
            error={descriptionError && t('addPersopnalizedBookPage.descriptionValidationMessage')}
            maxCharacters={900}
            onChange={e => dispatch({ type: 'setBookData', field: 'description', data: e.target.value })}
          />
        </div>

        <div className="columns-2">
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.coverFontLabel')}
                options={coverFontOptions}
                value={bookData.converFont}
                error={null}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => dispatch({ type: 'setBookData', field: 'converFont', data: e.value })}
              />
            </div>
          </div>
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.innerFontLabel')}
                options={innerFontOptions}
                value={bookData.innerFont}
                error={null}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => dispatch({ type: 'setBookData', field: 'innerFont', data: e.value })}
              />
            </div>
          </div>
        </div>

        <div className="columns-2">
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.verionLabel')}
                options={marketOptions.map(item => ({
                  value: item.value,
                  label: t(`productDetailsPage.${item.label}`),
                }))}
                value={bookData.market}
                error={marketError && t('addPersopnalizedBookPage.verionValidationMessage')}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => dispatch({ type: 'setBookData', field: 'market', data: e.value })}
              />
            </div>
          </div>
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.printingHouseLabel')}
                options={printingHouseOptions}
                value={bookData.printingHouse}
                error={printingHouseError && t('addPersopnalizedBookPage.printingHouseValidationMessage')}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => dispatch({ type: 'setBookData', field: 'printingHouse', data: e.value })}
              />
            </div>
          </div>
        </div>

        <div className="input-wrapper">
          <TextInput
            label={t('addPersopnalizedBookPage.isbnLabel')}
            placeholder={t('addPersopnalizedBookPage.isbnPlaceholder')}
            value={bookData.isbn}
            error={isbnError && t('addPersopnalizedBookPage.isbnValidationMessage')}
            onChange={e => dispatch({ type: 'setBookData', field: 'isbn', data: e.target.value })}
          />
        </div>

        <div className="input-wrapper">
          <DragAndDrop 
            label={t('addPersopnalizedBookPage.photosLabel')}
            componentId="book-main-image"
            uploadedFile={bookData.mainImage}
            error={mainImageError && t('addPersopnalizedBookPage.photoValidationMessage')}
            setUploadedFile={value => handleImageChange(value)}
            clearUpload={() => handleImageChange(null)}
          />
        </div>


        <div className="seo-fields-wrapper">
          <h4 className="title">{t('addStandardBookPage.seoSectionTitle')}</h4>
          <div className="input-wrapper">
            <TextInput
              label={t('addStandardBookPage.metaTitleLabel')}
              placeholder={t('addStandardBookPage.metaTitlePlaceholder')}
              value={bookData.metaTitle}
              maxCharacters={250}
              onChange={e => dispatch({ type: 'setBookData', field: 'metaTitle', data: e.target.value })}
            />
          </div>
          <div className="input-wrapper">
            <TextareaInput
              label={t('addStandardBookPage.metaDescriptionLabel')}
              placeholder={t('addStandardBookPage.metaDescriptionPlaceholder')}
              value={bookData.metaDescription}
              maxCharacters={250}
              onChange={e => dispatch({ type: 'setBookData', field: 'metaDescription', data: e.target.value })}
            />
          </div>
          <div className="input-wrapper">
            <TextInput
              label={t('addStandardBookPage.metaKeywordsLabel')}
              placeholder={t('addStandardBookPage.metaKeywordsPlaceholder')}
              value={bookData.metaKeywords}
              maxCharacters={250}
              onChange={e => dispatch({ type: 'setBookData', field: 'metaKeywords', data: e.target.value })}
            />
          </div>
        </div>

        <div className="button-wrapper mt48">
          <button className="button" onClick={validateInputs}>{t('addPersopnalizedBookPage.gotoCreatorButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default BookDetails
