import React, {useState, useEffect, useRef} from 'react'
import { serverURL } from '../../../../config'
import { useStateValue } from '../../../../state/state'
import { useTranslation } from 'react-i18next'
import Banner from '../../../commons/Banner/Banner'
import MetaTags from "../../../commons/MetaTags/MetaTags";
import ApiConnector from "../../../../ApiConnector";
import CallToAction from "../../../CallToAction";
import BooksList from "./BooksList";
import Advantages from "./Advantages";
import HowItWorks from "./HowItWorks";
import KidsPhotos from "./KidsPhotos";
import FeedbackList from "./FeedbackList";
import './ShopMainPage.scss'
import SeeMore from "./SeeMore";
import {notifyGoogleTagManagerEvent} from "../../../../helper/tracking/notifyGoogleTagManagerEvent";

function ShopMainPage() {
  const [{ kidName, kidGender }, dispatch] = useStateValue()
  const [personalizedBooks, setPersonalizedBooks] = useState([])
  const { i18n, t } = useTranslation()
  const booksContainerRef = useRef();

  useEffect(() => {
    ApiConnector.getPersonalizedBooks(i18n.language).then(booksList => {
      setPersonalizedBooks(booksList)
    })
  }, [i18n.language])

  useEffect(() => {
    dispatch({ type: 'setData', field: 'kidName', data: ''})
    dispatch({ type: 'setData', field: 'kidGender', data: ''})
    dispatch({ type: 'setData', field: 'kidEditedPhoto', data: null})
    dispatch({ type: 'setData', field: 'dedication', data: ''})
  }, [])

  const bookCoverUrl = personalizedBooks.length > 0
    ? `${serverURL}${personalizedBooks[0]?.bookMainImage?.webPath ?? personalizedBooks[0]?.bookMainImage?.filePath}`
    : null;

  useEffect(()=>{
    notifyGoogleTagManagerEvent('strona główna');
  }, []);

  return (
    <div className="shop-main-page">

      <MetaTags />

      <section className="personalize-section">
        <Banner />
        <CallToAction bookCover={bookCoverUrl} scrollTargetRef={booksContainerRef}/>
      </section>

      <section className="books-section" id="books" ref={booksContainerRef}>
        <BooksList books={personalizedBooks} />
      </section>

      <section className="advantages-section">
        <Advantages />
      </section>

      <section className="how-it-works-section">
        <HowItWorks />
      </section>

      <section className="photos-section">
        <KidsPhotos />
      </section>

      <section className="feedback-section">
        <FeedbackList />
      </section>

      <section className="see-more-section">
        <SeeMore />
      </section>

    </div>
  )
}

export default ShopMainPage
