import React, { useEffect, useState } from 'react'
import './AddUserModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import TextInput from '../../../../commons/TextInput/TextInput'
import SelectInput from '../../../../commons/SelectInput/SelectInput'
import { validateEmail, getPritingHouses } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { useStateValue } from '../../../../../state/state'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


function AddUserModal({
  closeModalHandler,
  refreshUsersList,
}) {
  const [, dispatch] = useStateValue()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [organization, setOrganization] = useState('')
  const [organizationError, setOrganizationError] = useState('')
  const [role, setRole] = useState(null)
  const [userLanguage, setUserLanguage] = useState(null)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const userLanguagesOptions = [
    { value: 'pl', label: t('manageUserModals.languagePolish') },
    { value: 'en', label: t('manageUserModals.languageEnglish') },
    { value: 'es', label: t('manageUserModals.languageSpanish') },
  ]
  
  const userRoles = [
    { value: 'ROLE_ADMIN', label: t('manageUserModals.roleSuperAdmin') },
    { value: 'ROLE_CREATOR', label: t('manageUserModals.roleCreator') },
    { value: 'ROLE_PRINTINGHOUSE', label: t('manageUserModals.rolePrintingHouse') },
  ]

  useEffect(() => {
    setRole(userRoles[0].value)
    setUserLanguage(userLanguagesOptions[0].value)
  }, [t])

  const handleAddUser = () => {
    const emailError = !validateEmail(email)
    const organizationError = !organization

    if (
      !emailError
      && !organizationError
    ) {
      axios.post(`api/register`, {
        email,
        roles: role,
        organization,
        language: userLanguage,
      }, { 
        headers: { 
          Authorization: `bearer ${token}`,
        } 
      })
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            closeModalHandler()
            refreshUsersList()
            getPritingHouses(token, dispatch)
          }
        })
    }

    setEmailError(emailError)
    setOrganizationError(organizationError)
  }
 
  return (
    <div className="add-user-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2><center>{t('manageUserModals.addUserModalheader')}</center></h2>

        <div className="input-wrapper mt48">
          <TextInput
            label={t('manageUserModals.emailLabel')}
            placeholder={t('manageUserModals.emailPlaceholder')}
            value={email}
            error={emailError && t('manageUserModals.emailValidationMessage')}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="input-wrapper mt24">
          <TextInput
            label={t('manageUserModals.organizationLabel')}
            placeholder={t('manageUserModals.organizationPlaceholder')}
            value={organization}
            error={organizationError && t('manageUserModals.organizationValidationMessage')}
            onChange={e => setOrganization(e.target.value)}
          />
        </div>

        <div className="input-wrapper mt24">
          <SelectInput
            label={t('manageUserModals.roleLabel')}
            options={userRoles}
            value={role}
            error={null}
            placeholder={t('manageUserModals.selectPlaceholder')}
            onChange={e => setRole(e.value)}
          />
        </div>

        <div className="input-wrapper mt24">
          <SelectInput
            label={t('manageUserModals.languageLabel')}
            options={userLanguagesOptions}
            value={userLanguage}
            error={null}
            placeholder={t('manageUserModals.selectPlaceholder')}
            onChange={e => setUserLanguage(e.value)}
          />
        </div>

        <div className="button-wrapper mt64">
          <button className="button" onClick={handleAddUser}>{t('manageUserModals.sendInvitationButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default AddUserModal
