export const MIN_FILE_SIZE = 307200
export const MAX_FILE_SIZE = 31457280
export const MIN_KID_IMAGE_SIZE = 300

// export const CLIENT_ID_PLN = 396180
//export const CLIENT_ID_PLN = 2915459
// const payuIdsPLN = {
//   'localhost': 396180,
//   'dev.editbook.wenus.softnauts.com': 396180,
//   'stg.editbook.wenus.softnauts.com': 397852,
//   'edikids.softnauts.pl': 2915459,
//   'edikids.pl': 2915459,
// }
//
// const serverUrls = {
//   'localhost': 'http://dev.api.editbook.wenus.softnauts.com',
//   // 'localhost': 'https://api.edikids.pl',
//   'dev.editbook.wenus.softnauts.com': 'http://dev.api.editbook.wenus.softnauts.com',
//   'stg.editbook.wenus.softnauts.com': 'http://stg.api.editbook.wenus.softnauts.com',
//   'edikids.softnauts.pl': 'http://api.edikids.softnauts.pl',
//   'edikids.pl': 'https://api.edikids.pl',
//   'edikids.es': 'https://api.edikids.pl',
// }
//
// const captchaKeys = {
//   'localhost': '6LceD78UAAAAAHdegniO928WMb90rmVwOU0mt2zI',
//   'dev.editbook.wenus.softnauts.com': '6LceD78UAAAAAHdegniO928WMb90rmVwOU0mt2zI',
//   'stg.editbook.wenus.softnauts.com': '6LceD78UAAAAAHdegniO928WMb90rmVwOU0mt2zI',
//   'edikids.softnauts.pl': '6LceD78UAAAAAHdegniO928WMb90rmVwOU0mt2zI',
//   'edikids.pl': '6LfBOe4ZAAAAABSw--UIRii4RDbeORnP3XSkcXL7',
//   'edikids.es': '6LfBOe4ZAAAAABSw--UIRii4RDbeORnP3XSkcXL7',
// }

export const serverURL = process.env.REACT_APP_SERVER_URL
export const CLIENT_ID_PLN = process.env.REACT_APP_PAYU_CLIENT_ID_PLN
export const CLIENT_ID_EUR = process.env.REACT_APP_PAYU_CLIENT_ID_EUR
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
export const GOOGLE_TAG_MANAGER_DATA_LAYER = process.env.REACT_APP_GOOGLE_TAG_MANAGER_DATA_LAYER

export const LANG_AUTO_DETECTION_BY_HOSTNAME = {
  pl: [ 'edikids.pl' ],
  es: [ 'edikids.es' ],
  en: [ 'edikids.eu' ]
};
