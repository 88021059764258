import JsCookie from "js-cookie";

const cookies = JsCookie.withAttributes({ path: '/', sameSite: 'Lax', secure: true });

export function set(name, value, options = {}) {
  cookies.set(name, value, options);
  return true;
}

export function get(name) {
  return cookies.get(name);
}

export function remove(name) {
  cookies.remove(name);
  return true;
}