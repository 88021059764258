import React from 'react'
import './TextInput.scss'


function TextInput({
  label,
  value,
  placeholder,
  error,
  onChange,
}) {

  return (
    <div className="text-input">
      <div className="input-label">{label}</div>
      <input
        className={error ? 'red-border': ''}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div className="input-error">{error}</div>
    </div>
  )
}

export default TextInput