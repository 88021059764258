import React from "react";
import css from './Advantages.module.scss';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

function Advantages() {
  const { t } = useTranslation()

  return (
    <div className={css.Advantages}>
      <h2 className={css.AdvantagesTitle}>{t('personalizedBooksPage.advantagesHeader')}</h2>
      <div className={css.Grid}>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.cool)} />
          <div className={css.Description}>{t('personalizedBooksPage.advantage1')}</div>
        </div>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.wow)} />
          <div className={css.Description}>{t('personalizedBooksPage.advantage2')}</div>
        </div>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.omg)} />
          <div className={css.Description}>{t('personalizedBooksPage.advantage3')}</div>
        </div>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.och)} />
          <div className={css.Description}>{t('personalizedBooksPage.advantage4')}</div>
        </div>
      </div>
    </div>
  )
}

export default Advantages;