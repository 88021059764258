import React, { useState, useEffect } from 'react'
import './PackageSuborderDetailsPage.scss'
import calendarIcon from '../../../../assets/calendar_icon.svg'
import moment from 'moment'
import { default as axios } from '../../../../axiosSettings'
import { serverURL } from '../../../../config'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


function PackageSuborderDetailsPage(props) {
  const [subOrderData, setSubOrderData] = useState({})
  const suborderId = props.match.params.suborderId 
  const { t } = useTranslation()
  const token = Storage.get(TOKEN)

  const statusMap = {
    0: t('packageDetailsPage.status0'),
    1: t('packageDetailsPage.status1'),
    2: t('packageDetailsPage.status2'),
    3: t('packageDetailsPage.status3'),
    4: t('packageDetailsPage.status4'),
    5: t('packageDetailsPage.status5'),
  }

  const countriesMap = {
    'AT': t('countries.AT'),
    'BE': t('countries.BE'),
    'BG': t('countries.BG'),
    'HR': t('countries.HR'),
    'CZ': t('countries.CZ'),
    'DK': t('countries.DK'),
    'EE': t('countries.EE'),
    'FI': t('countries.FI'),
    'FR': t('countries.FR'),
    'GR': t('countries.GR'),
    'ES': t('countries.ES'),
    'NL': t('countries.NL'),
    'IE': t('countries.IE'),
    'LT': t('countries.LT'),
    'LU': t('countries.LU'),
    'LV': t('countries.LV'),
    'MC': t('countries.MC'),
    'DE': t('countries.DE'),
    'PL': t('countries.PL'),
    'PT': t('countries.PT'),
    'RO': t('countries.RO'),
    'SK': t('countries.SK'),
    'SE': t('countries.SE'),
    'HU': t('countries.HU'),
    'GB': t('countries.GB'),
    'IT': t('countries.IT'),
  }

  useEffect(() => {
    axios.get(`api/sub_orders/${suborderId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setSubOrderData(response.data)
      })
  }, [suborderId])

  return (
    <div className="package-suborder-details-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>
            {t('packageSuborderDetailsPage.order')} #{suborderId}
            <span class="status-label">{statusMap[subOrderData.status]}</span>
          </h2>
        </div>
        <div className="date-line">
          <img src={calendarIcon} alt="calendar-icon" />
          <span>{moment(subOrderData.createdAt).format('DD.MM.YYYY')}</span>
        </div>
        
        <div className="columns-2-1">
          <div className="left">
            <div className="box">
              <div className="table-header">
                <div className="table-header-cell">{t('packageSuborderDetailsPage.productColumn')}</div>
                <div className="table-header-cell">{t('packageSuborderDetailsPage.idColumn')}</div>
                <div className="table-header-cell">{t('packageSuborderDetailsPage.kidNameColumn')}</div>
                <div className="table-header-cell">{t('packageSuborderDetailsPage.countColumn')}</div>
              </div>
              <div className="table-rows">
                {
                  subOrderData.orderPositions && subOrderData.orderPositions.map((item, i) => {
                    return (<div className="table-row" key={i}>
                      <div className="table-row-cell">
                        <img src={serverURL + item.book.bookMainImage.filePath} alt="book cover" />
                        <div className="book-detals">
                          <div className="book-title">{item.title}</div>
                          <div className="book-type">{t('packageSuborderDetailsPage.personalizedItemType')}</div>
                        </div>
                      </div>
                      <div className="table-row-cell">#{item.id}</div>
                      <div className="table-row-cell">{item.childName}</div>
                      <div className="table-row-cell">{item.amount} {t('packageSuborderDetailsPage.count')}</div>
                    </div>)
                  })
                }
              </div>
            </div>
          </div>
          <div className="right">
            <div className="box">
              <div className="label">{t('packageSuborderDetailsPage.clientLabel')}</div>
              <div className="text-bigger mt16">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.fullName}</div>
              <hr />
              <div className="label">{t('packageSuborderDetailsPage.shippingDataLabe')}</div>
              <div className="text mt8">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.fullName}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.companyName}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.email}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.address1}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.address2}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.zipCode} {subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.city}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.state}, {subOrderData.orderData && subOrderData.orderData.shipping && countriesMap[subOrderData.orderData.shipping.country]}</div>
              <div className="text">{subOrderData.orderData && subOrderData.orderData.shipping && subOrderData.orderData.shipping.phone}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageSuborderDetailsPage
