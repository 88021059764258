import React from 'react'
import './SearchBox.scss'
import searchIcon from '../../../assets/search_icon.svg'
import closeIcon from '../../../assets/close_icon_smaller.svg'


function SearchBox({
  value,
  placeholder,
  onChange,
  clearInput
}) {

  return (
    <div className="search-box">
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      { !value && <img className="search-icon" src={searchIcon} alt="search" /> }
      { value && <img className="remove-icon" src={closeIcon} alt="search" onClick={clearInput} /> }
    </div>
  )
}

export default SearchBox