import React, {useEffect} from 'react'
import './TransaltionAcceptedModal.scss'
import { useTranslation } from 'react-i18next'
import {notifyGTMConversion} from "../../../../helper/tracking/notifyGTMConversion";


function TransaltionAcceptedModal({ okButtonHandler, transactionId }) {
  const { t } = useTranslation()
  
  useEffect(()=>{
    notifyGTMConversion(transactionId.id, transactionId.totalCost, transactionId.currencyType)
  }, [transactionId.currencyType, transactionId.id, transactionId.totalCost])

  return (
    <div className="transaltion-accepted-modal">
      <div className="modal-box">
        <h2>{t('orderDataPage.transactionAcceptedModalText')}</h2>
        <div className="button-wrapper mt64">
          <button className="button auto-width" onClick={okButtonHandler}>{t('orderDataPage.transactionAcceptedModalButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default TransaltionAcceptedModal
