import axios from "axios";
import {serverURL} from '../config';

class ApiConnector {
  constructor() {
    this.axiosAnon = axios.create({
      baseURL: serverURL
    });
  }

  getPersonalizedBooks(countryCode)
  {
    return new Promise((resolve, reject) => {
      const url = `/api/books?itemsPerPage=99999&isCustomizable=true&status=1&order[id]=desc&market.countryCode=${countryCode}`;
      this.axiosAnon.get(url).then(response => {
        resolve(response.data['hydra:member']);
      }).catch(error => {
        reject(error);
      })
    });
  }

  getBanner()
  {
    return new Promise((resolve, reject) => {
      const url = '/api/banners';
      this.axiosAnon.get(url).then(response => {
        const banners = response.data['hydra:member'];
        const first = banners.shift();
        resolve({
          'en': first?.textEn,
          'es': first?.textEs,
          'pl': first?.textPl
        });
      }).catch(error => {
        reject(error);
      })
    });
  }
}

const connector = new ApiConnector();
export default connector;