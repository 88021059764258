import { pageNamesMap } from "../consts";

export const initialState = {
  isAdminLoggedIn: null,
  userData: {},

  isClientLoggedIn: false,
  clientData: {},

  marketOptions: [],
  printingHouseOptions: [],

  creatorPage: 'bookDetails', // 'bookDetails', 'pagesEditor'
  bookData: {
    bookId: null,
    savedPages: [],
    title: '',
    description: '',
    market: null,
    printingHouse: null,
    isbn: '',
    mainImage: null,
    converFont: 'coiny-regular',
    innerFont: 'linotte',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    pages: [
      { type: 0, name: pageNamesMap[1], number: 1, backgroundImage: null, uploadedImage: null },
      { type: 1, name: pageNamesMap[3], number: 3, backgroundImage: null, uploadedImage: null },
      { type: 2, name: pageNamesMap[4], number: 4, backgroundImage: null, uploadedImage: null },
      { type: 1, name: pageNamesMap[5], number: 5, backgroundImage: null, uploadedImage: null },
      { type: 1, name: pageNamesMap[6], number: 6, backgroundImage: null, uploadedImage: null },
      { type: 3, name: pageNamesMap[7], number: 7, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[8], number: 8, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[9], number: 9, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[10], number: 10, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[11], number: 11, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[12], number: 12, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[13], number: 13, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[14], number: 14, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[15], number: 15, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[16], number: 16, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[17], number: 17, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[18], number: 18, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[19], number: 19, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[20], number: 20, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[21], number: 21, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[22], number: 22, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[23], number: 23, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[24], number: 24, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[25], number: 25, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[26], number: 26, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[27], number: 27, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[28], number: 28, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[29], number: 29, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 3, name: pageNamesMap[30], number: 30, backgroundImage: null, uploadedImage: null, clientPhotoPosition: 0, textPosition: 0, boysText: '', girlsText: '' },
      { type: 4, name: pageNamesMap[31], number: 31, backgroundImage: null, uploadedImage: null, boysText: '', girlsText: '' },
      { type: 1, name: pageNamesMap[32], number: 32, backgroundImage: null, uploadedImage: null },
      { type: 5, name: pageNamesMap[34], number: 34, backgroundImage: null, uploadedImage: null },
    ],
  },

  cart: [],
  coupon: null,

  kidName: '',
  kidGender: '',
  kidEditedPhoto: null,
  dedication: '',

  noRegistrationName: '',
  noRegistrationEmail: '',
}