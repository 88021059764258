import React from 'react'
import './Checkbox.scss'


function Checkbox({
  value,
  onChange,
  text,
  error,
  children,
}) {

  return (
    <div className="checkbox">
      {
        <label className="container" onClick={e => {
            e.preventDefault()
            onChange(!value)
          }}>
          {text}
          {children}
          <input type="checkbox" checked={value} onChange={() => null} />
          <span className="checkmark"></span>
        </label>
      }
      { error && <div className="input-error">{error}</div> }
    </div>
  )
}

export default Checkbox