import React, {useEffect} from 'react'
import './AboutPage.scss'
import aboutUsImage1 from '../../../../assets/about_us_image_1.svg'
import aboutUsImage2 from '../../../../assets/about_us_image_2.svg'
import aboutUsImage3 from '../../../../assets/about_us_image_3.svg'
import { useTranslation } from 'react-i18next'
import MetaTags from "../../../commons/MetaTags/MetaTags";
import {notifyGTMPageOpened} from "../../../../helper/tracking/notifyGTMPageOpened";


function AboutPage() {
  const { t, i18n } = useTranslation()

  useEffect(()=>{
    notifyGTMPageOpened(i18n.language);
  }, []);

  return (
    <div className="about-page">

      <MetaTags title="Edikids - O nas" />

      <div className="page-content">
        <h2>{t('aboutPage.header')}</h2>
        <p className="mt32">{t('aboutPage.paragraph1')}</p>
        <p className="mt32">{t('aboutPage.paragraph2')}</p>
        <div className="images-grid mt32">
          <img src={aboutUsImage1} alt="about us" />
          <img src={aboutUsImage2} alt="about us" />
          <img src={aboutUsImage3} alt="about us" />
        </div>
        <p className="mt48">{t('aboutPage.paragraph3')}</p>
        <p className="mt32">{t('aboutPage.paragraph4')}</p>
        <p className="text-bigger">{t('aboutPage.paragraph5')}</p>
      </div>
    </div>
  )
}

export default AboutPage
