import React from 'react'
import './RemoveCouponModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { useTranslation } from 'react-i18next'


const currencyMap = {
  0: '%',
  1: 'zł',
  2: '€',
}


function RemoveCouponModal({
  coupon,
  closeModalHandler,
  removeCouponHandler,
}) {
  const { t } = useTranslation()
 
  return (
    <div className="remove-coupon-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('emailCouponsPage.removeModalTitle')} {coupon.text}?</h2>
        <div className="mt24">
        {t('emailCouponsPage.removeModalText1')} <strong>{coupon.text}</strong> {t('emailCouponsPage.removeModalText2')} <strong>{(parseFloat(coupon.value)/100).toFixed(2)}{currencyMap[coupon.currencyType]}</strong> {t('emailCouponsPage.removeModalText3')}
        </div>
        <div className="button-wrapper mt64">
          <button className="button button-secondary auto-width mr16" onClick={closeModalHandler}>{t('emailCouponsPage.removeModalCancelButton')}</button>
          <button className="button auto-width" onClick={removeCouponHandler}>{t('emailCouponsPage.removeModalConfirmButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default RemoveCouponModal
