import React from 'react'
import './ClientPhotoMenu.scss'
import userIcon from '../../../../../../../assets/user_icon.svg'
import RadioButtonsGroup from '../../../../../../commons/RadioButtonsGroup/RadioButtonsGroup'
import { useTranslation } from 'react-i18next'


function ClientPhotoMenu({
  pageNumber,
  isMenuOpen,
  handleMenuClick,
  position,
  onChange,
}) {
  const { t } = useTranslation()
  
  const options = [
    { value: 1, label: t('pagesEditor.photoLeftTopCorner') },
    { value: 2, label: t('pagesEditor.photoLeftBottomCorner') },
    { value: 3, label: t('pagesEditor.photoRightTopCorner') },
    { value: 4, label: t('pagesEditor.photoRightBottomCorner') },
    { value: 0, label: t('pagesEditor.photoNone') },
  ]

  return (
    <div className="client-photo-menu">
      <div className="menu-header" onClick={() => handleMenuClick(isMenuOpen ? null : 'clientPhoto')}>
        <img src={userIcon} alt="icon" />
        <span className="menu-header-text">{t('pagesEditor.kidPhotoPlaceLabel')}</span>
      </div>
      {
        isMenuOpen && <div className="menu-options">
          <RadioButtonsGroup
            name={`clientPhoto-${pageNumber}`}
            value={position}
            options={options}
            error={null}
            verticalOptions={true}
            onChange={value => onChange(value)}
          />
        </div>
      }
    </div>
  )
}

export default ClientPhotoMenu
