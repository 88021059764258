import React, {useRef} from "react";
import PropTypes from "prop-types";
import css from './index.module.scss';
import classNames from "classnames";

function Modal({isOpen, onClose, skipOutsideClosing, onAction, title, children, actions, className}) {
  const wrapperRef = useRef();

  if (!isOpen) {
    return null;
  }

  function wrapperClick(event) {
    if (!skipOutsideClosing && wrapperRef && wrapperRef.current && event.target === wrapperRef.current) {
      event.stopPropagation();
      onClose();
    }
  }

  return (
    <div className={classNames(css.ModalWrapper, className)} onClick={wrapperClick} ref={wrapperRef}>
      <div className={css.Modal} onClick={wrapperClick}>
        <button type="button" className={css.CloseIcon} onClick={onClose} />
        {title &&
          <h2 className={css.Title}>
            {title}
          </h2>
        }
        <div className={css.Content}>
          {children}
        </div>
        {actions &&
          <div className={css.Actions}>
            {actions.map((action, actionIdx) => {
              const actionText = typeof action === 'object' && action.text ? action.text : action;
              return (
                <button key={actionIdx} type="button" onClick={()=>onAction(action, actionIdx)} className={css.actionButton}>
                  {actionText}
                </button>
              )
            })}
          </div>
        }
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  skipOutsideClosing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  ]).isRequired),
  className: PropTypes.string
}

export default Modal;