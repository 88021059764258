import React, { useState } from 'react'
import './EditShippingDataModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import TextInput from '../../../../commons/TextInput/TextInput'
import PriceInput from '../../../../commons/PriceInput/PriceInput'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


const originsMap = {
  'pl': 'Polska',
  'es': 'Hiszpania',
  'en': 'Europa',
}

const destinationsMap = {
  'plZoneA': 'Europa A',
  'plZoneB': 'Europe B',
  'esZoneA': 'Europe A',
  'esZoneB': 'Europe B',
  'euZoneA': 'Europe A',
  'euZoneB': 'Europe B',
}


function EditShippingDataModal({
  itemData,
  closeModalHandler,
  refreshData,
}) {
  const [price, setPrice] = useState(itemData.cost ? parseFloat(itemData.cost/100).toFixed(2) : '0.00')
  const [priceError, setPriceError] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const handleSave = () => {
    const priceError = !price
    if (!priceError) {
      axios.patch(`api/shipping_costs/${itemData.id}`,{
        cost: Math.round(parseFloat(price) * 100),
      } , { 
        headers: { 
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        } 
      })
        .then(() => {
          closeModalHandler()
          refreshData()
        })
    }
    setPriceError(priceError)
  }
 
  return (
    <div className="edit-shipping-data-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2><center>{t('shippingCostsPage.editModalTitle')}</center></h2>

        <div className="input-wrapper mt24">
          <TextInput
            label={t('shippingCostsPage.versionLabel')}
            value={originsMap[itemData.origin]}
          />
        </div>

        <div className="input-wrapper mt24">
          <TextInput
            label={t('shippingCostsPage.countryLabel')}
            value={destinationsMap[itemData.destination]}
          />
        </div>

        <div className="input-wrapper mt24">
          <PriceInput
            label={t('shippingCostsPage.costLabel')}
            placeholder={t('shippingCostsPage.costPlaceholder')}
            value={price}
            currency={itemData.origin === 'pl' ? 'zł' : '€'}
            error={priceError && t('shippingCostsPage.costValidationMessage')}
            onChange={e => setPrice(e.target.value)}
          />
        </div>

        <div className="button-wrapper mt64">
          <button className="button" onClick={handleSave}>{t('shippingCostsPage.editModalSaveButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default EditShippingDataModal
