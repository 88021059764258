import React, { useState, useEffect } from 'react'
import './ExistingBookEditor.scss'
import { default as axios } from '../../../../axiosSettings'
import CoverPage from '../CreatorPage/PersonalizedBook/PagesEditor/CoverPage/CoverPage'
import OnlyBackgroundPage from '../CreatorPage/PersonalizedBook/PagesEditor/OnlyBackgroundPage/OnlyBackgroundPage'
import DedicationPage from '../CreatorPage/PersonalizedBook/PagesEditor/DedicationPage/DedicationPage'
import StandardPage from '../CreatorPage/PersonalizedBook/PagesEditor/StandardPage/StandardPage'
import ThanksPage from '../CreatorPage/PersonalizedBook/PagesEditor/ThanksPage/ThanksPage'
import { useStateValue } from '../../../../state/state'
import { pageNamesMap } from '../../../../consts'
import ChangesSavedModal from '../ProductsPage/ChangesSavedModal/ChangesSavedModal'
import BackCoverPage from '../CreatorPage/PersonalizedBook/PagesEditor/BackCoverPage/BackCoverPage'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


function ExistingBookEditor(props) {
  const [{ bookData }, dispatch] = useStateValue()
  const [title, setTitle] = useState('')
  const [coverFont, setCoverFont] = useState(null)
  const [innerFont, setInnerFont] = useState(null)
  const [imagesToRemove, setImagesToRemove] = useState([])
  const [changesSavedModalShown, setChangesSavedModalShown] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);
  const bookId = props.match.params.id

  useEffect(() => {
    axios.get(`api/books/${bookId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setTitle(response.data.title)
        setCoverFont(response.data.coverFont)
        setInnerFont(response.data.innerFont)
        dispatch({ type: 'setBookData', field: 'pages', data: response.data.pages.map(page => {
          return {
            iri: page['@id'],
            type: page.type,
            name: pageNamesMap[page.number],
            number: page.number, 
            backgroundImage: page.pageBackgroundImage,
            uploadedImage: page.pageBackgroundImage,
            clientPhotoPosition: page.clientPhotoPosition, 
            textPosition: page.textPosition, 
            boysText: page.boysText, 
            girlsText: page.girlsText,
          }
        }) })
      })
  }, [bookId])
  
  const handleImageChange = (value, pageData) => {
    if (!value) {
      setImagesToRemove(imagesToRemove => [...imagesToRemove, pageData.uploadedImage['@id']])
      dispatch({ type: 'setBookPageData', number: pageData.number, field: 'backgroundImage', data: null})
      dispatch({ type: 'setBookPageData', number: pageData.number, field: 'uploadedImage', data: null})
    } else if (value && pageData.uploadedImage) {
      setImagesToRemove(imagesToRemove => [...imagesToRemove, pageData.uploadedImage['@id']])
      uploadNewImage(value, pageData.number)
    } else if (value && !pageData.uploadedImage) {
      uploadNewImage(value, pageData.number)
    }
  }

  const uploadNewImage = (value, pageNumber) => {
    const formData = new FormData()
    formData.append('file', value)
    axios.post(`api/page_background_images`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      } 
    })
      .then(response => {
        if (response.status === 201) {
          dispatch({ type: 'setBookPageData', number: pageNumber, field: 'backgroundImage', data: value})
          dispatch({ type: 'setBookPageData', number: pageNumber, field: 'uploadedImage', data: response.data})
        }
      })
  }

  const saveChanges = () => {
    const requestBody = {
      pages: bookData.pages.map(page => { 
        const pageData = {
          id: page.iri,
          number: page.number,
          type: page.type,
          clientPhotoPosition: page.clientPhotoPosition || 0,
          textPosition: page.textPosition || 0,
          boysText: page.boysText || '',
          girlsText: page.girlsText || '',
          pageBackgroundImage: page.uploadedImage ? '/api/page_background_images/' + page.uploadedImage.id : null
        }
        return pageData
      }),
    }
    axios.patch(`api/books/${bookId}`, requestBody, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        setChangesSavedModalShown(true)
        removeUnneededImages()
      })
  }

  const removeUnneededImages = () => {
    if (!imagesToRemove.length) return
    imagesToRemove.forEach(imageIri => {
      axios.delete(imageIri, { 
        headers: { 
          Authorization: `bearer ${token}`,
        }
      })
    })
    setImagesToRemove([])
  }


  return (
    <div className="existing-book-editor">
      
      <div className="top-bar">
        <div className="top-bar-inner">
          <div className="left">
            <h2>{title}</h2>
          </div>
          <div className="right">
            <button className="button auto-width" onClick={saveChanges}>{t('pagesEditor.existingBookEditorSaveButton')}</button>
          </div>
        </div>
      </div>

      <div className={`pages ${
        coverFont === 'coiny-regular' ? 'cover-font-coiny-regular' : ''
      } ${
        coverFont === 'pol-komikax' ? 'cover-font-pol-komikax' : ''
      } ${
        innerFont === 'linotte' ? 'inner-font-linotte' : ''
      } ${
        innerFont === 'pol-wonder-comic' ? 'inner-font-pol-wonder-comic' : ''
      }`}>
        {
          bookData.pages && bookData.pages.map((page, i) => <div className="page-wrapper" key={i}>
            { page.type === 0 && <CoverPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 1 && <OnlyBackgroundPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 2 && <DedicationPage pageNumber={page.number} handleImageChange={handleImageChange} /> } 
            { page.type === 3 && <StandardPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 4 && <ThanksPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 5 && <BackCoverPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            <div className="separator">
              <div className="separator-line"/>
              <div className="separator-text">{page.name}</div>
              <div className="separator-line"/>
            </div>
          </div>)
        }
      </div>

      { changesSavedModalShown && <ChangesSavedModal closeModalHandler={() => setChangesSavedModalShown(false)} /> }

    </div>
  )
}

export default ExistingBookEditor
