import React, { useState, useEffect } from 'react'
import { validateEmail, validatePassword } from '../../../../utils'
import { useStateValue } from '../../../../state/state'
import Checkbox from '../../../commons/Checkbox/Checkbox'
import TextInput from '../../../commons/TextInput/TextInput'
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal'
import './MyProfilePage.scss'
import PasswordInput from '../../../commons/PasswordInput/PasswordInput'
import { default as axios } from '../../../../axiosSettings'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import privacyPolicyPL from '../../../../assets/files/privacy_policy_pl.pdf'
import privacyPolicyES from '../../../../assets/files/privacy_policy_es.pdf'
import termsOfUsePL from '../../../../assets/files/terms_of_use_pl.pdf'
import termsOfUseES from '../../../../assets/files/terms_of_use_es.pdf'
import termsOfUseEN from '../../../../assets/files/terms_of_use_en.pdf'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";


function MyProfilePage() {
  const [{ clientData }] = useStateValue()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [fullNameError, setFullNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [rodoConsent, setRodoConsent] = useState(false)
  const [marketingConsent, setMarketingConsent] = useState(false)
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const { t, i18n } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    setFullName(clientData.fullName)
    setEmail(clientData.email)
    setRodoConsent(clientData.rodoConsent)
    setMarketingConsent(clientData.marketingConsent)
  }, [clientData])

  const getPrivacyPolicyFile = () => {
    if (i18n.language === 'en') return privacyPolicyES;
    if (i18n.language === 'es') return privacyPolicyES;
    return privacyPolicyPL;
  }

  const getTermsOfUseFile = () => {
    if (i18n.language === 'en') return termsOfUseEN;
    if (i18n.language === 'es') return termsOfUseES;
    return termsOfUsePL;
  }

  const handleSave = () => {
    const fullNameError = !fullName
    const emailError = !validateEmail(email)
    const passwordError = password && !validatePassword(password)

    if (
      !fullNameError
      && !emailError
      && !passwordError
    ) {
      const requestBody = {
        fullName,
        email,
        rodoConsent,
        marketingConsent,
      }
      if (password) requestBody.plainPassword = password

      axios.patch(`api/users/${clientData.id}`, requestBody, {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        }
      })
        .then(() => {
          setConfirmationModalShown(true)
        })
    }

    setFullNameError(fullNameError)
    setEmailError(emailError)
    setPasswordError(passwordError)
  }

  return (
    <div className="my-profile-page">
      <MetaTags
        title="Edikids - mój profil"
      />

      <div className="page-content-wrapper">
        <div className="columns">
          <div className="left">
            <h2>{t('myProfilePage.header')}</h2>

            <div className="input-wrapper mt32">
              <TextInput
                label={t('myProfilePage.nameLabel')}
                value={fullName}
                error={fullNameError && t('myProfilePage.nameValidationMessage')}
                placeholder={t('myProfilePage.namePlaceholder')}
                onChange={e => setFullName(e.target.value)}
              />
            </div>
            <div className="input-wrapper mt24">
              <TextInput
                label={t('myProfilePage.emailLabel')}
                value={email}
                error={emailError && t('myProfilePage.emailValidationMessage')}
                placeholder={t('myProfilePage.emailPlaceholder')}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="input-wrapper mt24">
              <PasswordInput
                label={t('myProfilePage.newPasswordLabel')}
                value={password}
                error={passwordError && t('myProfilePage.newPasswordValidationMessage')}
                placeholder={t('myProfilePage.newPasswordPlaceholder')}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="input-wrapper mt32">
              <Checkbox
                value={rodoConsent}
                onChange={() => null}
                error={null}
              >
                {t('myProfilePage.consent1Part1')} <a onClick={() => window.open(getTermsOfUseFile())} href={getTermsOfUseFile()} target="_blank" rel="noopener noreferrer">{t('myProfilePage.consent1Part2')}</a> <Link to='/'>{t('myProfilePage.consent1Part3')}</Link> {t('myProfilePage.consent1Part4')}
              </Checkbox>
            </div>
            <div className="input-wrapper mt24">
              <Checkbox
                value={marketingConsent}
                onChange={setMarketingConsent}
                error={null}
              >
                {t('myProfilePage.constent2')}
              </Checkbox>
            </div>

            <div className="text-smaller mt24">
              {t('myProfilePage.dataInfoPart1')} <a href={getPrivacyPolicyFile()} target="_blank" rel="noopener noreferrer">{t('myProfilePage.dataInfoPart2')}</a>
            </div>

            <div className="button-wrapper">
              <button className="button" onClick={handleSave}>{t('myProfilePage.saveButton')}</button>
            </div>
          </div>
          <div className="right" />
        </div>
      </div>

      {
        confirmationModalShown && <ConfirmationModal
          header={t('myProfilePage.confirmationModalTitle')}
          buttonText={t('myProfilePage.confirmationModalButton')}
          closeModalHandler={() => setConfirmationModalShown(false)}
        >
          {t('myProfilePage.confirmationModalText')}
        </ConfirmationModal>
      }
    </div>
  )
}

export default MyProfilePage
