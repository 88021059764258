import React, { useState, useEffect } from 'react'
import './ClientOrdersPage.scss'
import { default as axios } from '../../../../axiosSettings'
import { useStateValue } from '../../../../state/state'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";


function ClientOrdersPage() {
  const [{ clientData }] = useStateValue()
  const [ordersData, setOrdersData] = useState([])
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    if (!clientData.id) return
    axios.get(`api/orders?user.id=${clientData.id}&order[createdAt]=desc`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(resposne => {
        setOrdersData(resposne.data['hydra:member'])
      })
  }, [clientData.id])

  const getOrderValue = order => {
    const finalOrderValue = order.totalCost
    return `${(parseFloat(finalOrderValue)/100).toFixed(2)} ${order.currencyType === 'PLN' ? 'zł' : '€'}`
  }

  const renderTableHeaders = () => <div className="table-headers medium-and-big-screens">
    <div className="table-header">{t('clientOrdersPage.orderColumnHeader')}</div>
    <div className="table-header">{t('clientOrdersPage.dateColumnsHeader')}</div>
    <div className="table-header">{t('clientOrdersPage.valueColumnHeader')}</div>
    <div className="table-header" />
  </div>

  const renderTableRows = () => <div className="table-rows medium-and-big-screens">
    {
      ordersData.map((order, i) => <div className={`table-row ${i%2 === 0 ? 'row-odd' : ''}`}>
        <div className="table-row-cell">#{order.id}</div>
        <div className="table-row-cell">{moment(order.createdAt).format("DD.MM.YYYY")}</div>
        <div className="table-row-cell">{getOrderValue(order)}</div>
        <div className="table-row-cell"><Link to={`/orders/${order.id}`}>{t('clientOrdersPage.detailsLink')}</Link></div>
      </div>)
    }
  </div>

  const renderTableRowsMobile = () => <div className="table-rows small-screens">
  {
    ordersData.map((order, i) => <div className={`table-row ${i%2 === 0 ? 'row-odd' : ''}`}>
      <div className="table-row-cell-mobile">{t('clientOrdersPage.mobileOrderNumber')} #{order.id}</div>
      <div className="table-row-cell-mobile">{t('clientOrdersPage.mobileDate')} {moment(order.createdAt).format("DD.MM.YYYY")}</div>
      <div className="table-row-cell-mobile">{t('clientOrdersPage.movileValue')} {getOrderValue(order)}</div>
      <div className="table-row-cell-mobile"><Link to={`/orders/${order.id}`}>{t('clientOrdersPage.detailsLink')}</Link></div>
    </div>)
  }
  </div>

  return (
    <div className="client-orders-page">

      <MetaTags title="Edikids - moje zamówienia" />

      <div className="page-content-wrapper">
        <h2>{t('clientOrdersPage.pageHeader')}</h2>
        <div className="table-wrapper">
          { ordersData.length ? renderTableHeaders() : <div>{t('clientOrdersPage.noOrdersInfo')}</div> }
          { renderTableRows() }
          { renderTableRowsMobile() }
        </div>
      </div>
    </div>
  )
}

export default ClientOrdersPage
