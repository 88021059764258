import React, { useState, useEffect } from 'react'
import './PackageDetailsPage.scss'
import { Link } from 'react-router-dom'
import { default as axios } from '../../../../axiosSettings'
import SortableHeader from '../../../commons/SortableHeader/SortableHeader'
import moment from 'moment'
import calendarIcon from '../../../../assets/calendar_icon.svg'
import LabeledSelect from '../../../commons/LabeledSelect/LabeledSelect'
import SelectInput from '../../../commons/SelectInput/SelectInput'
import Checkbox from '../../../commons/Checkbox/Checkbox'
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


function PackageDetailsPage(props) {
  const [packageData, setPackageData] = useState({})
  const [subOrdersData, setSubOrdersData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [newStatus, setNewStatus] = useState(null)
  const [sortBy, setSortBy] = useState('id')
  const [sortDirection, setSortDirection] = useState('asc')
  const [status, setStatus] = useState('all')
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);
  const packageId = props.match.params.packageId

  const statusMap = {
    0: t('packageDetailsPage.status0'),
    1: t('packageDetailsPage.status1'),
    2: t('packageDetailsPage.status2'),
    3: t('packageDetailsPage.status3'),
    4: t('packageDetailsPage.status4'),
    5: t('packageDetailsPage.status5'),
  }
  
  const availableStatusOptions = [
    { value: 'all', label: t('packageDetailsPage.statusAll') },
    { value: 3, label: t('packageDetailsPage.status3') },
    { value: 4, label: t('packageDetailsPage.status4') },
    { value: 5, label: t('packageDetailsPage.status5') },
  ]
  
  const newStatusOptions = [
    { value: 3, label: t('packageDetailsPage.status3') },
    { value: 4, label: t('packageDetailsPage.status4') },
    { value: 5, label: t('packageDetailsPage.status5') },
  ]

  useEffect(() => {
    axios.get(`api/packages/${packageId}`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setPackageData(response.data)
      })
  }, [])

  useEffect(() => {
    getSubOrdersData()
  }, [packageId, status, sortBy, sortDirection])

  const getSubOrdersData = () => {
    let url = `api/packages/${packageId}/sub_orders?order[${sortBy}]=${sortDirection}`
    if (status !== 'all') url += `&status=${status}`
    axios.get(url, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setSubOrdersData(response.data['hydra:member'])
      })
  }

  const handleGenerateDownload = () => {
    axios.post(`api/package/${packageId}/compress`, {
      suborders: selectedItems,
    }, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        if (response.status === 200) {
          setConfirmationModalShown(true)
        }
      })
  }

  const changeItemsStatus = () => {
    axios.post(`api/suborders/status`, {
      suborders: selectedItems,
      status: newStatus,   
    }, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(() => {
        getSubOrdersData()
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">
        <SortableHeader text={t('packageDetailsPage.idColumn')} sortByText="id" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('packageDetailsPage.clientColumn')} sortByText="orderData.fullName" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('packageDetailsPage.dateColumn')} sortByText="createdAt" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('packageDetailsPage.statusColumn')} sortByText="status" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell" />
      <div className="table-header-cell">
        <Checkbox
          value={subOrdersData && selectedItems.length === subOrdersData.length}
          onChange={() => {
            if (selectedItems.length !== subOrdersData.length) {
              setSelectedItems(subOrdersData.map(packageItem => packageItem.id))
            } else {
              setSelectedItems([])
            }
          }}
        />
      </div>
    </div>
  )

  const renderRows = () => (
    subOrdersData && subOrdersData.map((item, i) => (
      <div key={i} className={`table-row row-${i%2 ? 'even' : 'odd'}`}>
        <div className="table-row-cell">#{item.id}</div>
        <div className="table-row-cell">{item.orderData && item.orderData.fullName}</div>
        <div className="table-row-cell">{moment(item.createdAt).format('DD.MM.YYYY')}</div>
        <div className="table-row-cell">{statusMap[item.status]}</div>
        <div className="table-row-cell"><Link to={`/admin/packages/${packageId}/suborder/${item.id}`}>{t('packageDetailsPage.detailsLink')}</Link></div>
        <div className="table-row-cell">
          <Checkbox
            value={selectedItems.indexOf(item.id) !== -1}
            onChange={() => {
              if (selectedItems.indexOf(item.id) === -1) {
                setSelectedItems(packageItem => [...packageItem, item.id])
              } else {
                setSelectedItems(selectedItems.filter(packageItem => packageItem !== item.id))
              }
            }}
          />
        </div>
      </div>
    )
  ))

  return (
    <div className="package-details-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('packageDetailsPage.package')} #{packageId}</h2>
          <div className="right-area">
            <div className="status-filter-wrapper">
              <LabeledSelect
                selectedOptionLabel={t('packageDetailsPage.statusFilterLabel')}
                options={availableStatusOptions}
                value={status}
                placeholder={t('packageDetailsPage.statusFilterPlaceholder')}
                onChange={e => setStatus(e.value)}
              />
            </div>
            <div className="status-select-wrapper">
              <SelectInput
                options={newStatusOptions}
                value={newStatus}
                placeholder={t('packageDetailsPage.changeStatusPlaceholder')}
                inactive={!selectedItems.length}
                onChange={e => setNewStatus(e.value)}
              />
            </div>
            <div>
              <button className={`button ${ selectedItems.length && newStatus ? '' : 'inactive'}`} onClick={changeItemsStatus}>{t('packageDetailsPage.saveButton')}</button>
            </div>
            <div>
              <button className={`button ${ selectedItems.length ? '' : 'inactive'}`} onClick={handleGenerateDownload}>{t('packageDetailsPage.downloadButton')}</button>
            </div>
          </div>
        </div>
        <div className="date-line">
          <img src={calendarIcon} alt="calendar-icon" />
          <span>{moment(packageData.startDate).format('DD.MM.YYYY')} - {moment(packageData.endDate).format('DD.MM.YYYY')}</span>
        </div>
        <div className="table-wrapper mt32">
          <div className="table">
            { !subOrdersData.length ? <div>{t('packageDetailsPage.noOrdersInfo')}</div> : null }
            { subOrdersData.length ? renderHeaders() : null }
            { renderRows() }
          </div>
        </div>
      </div>

      { 
        confirmationModalShown && <ConfirmationModal
          header= {t('packageDetailsPage.downloadConfirmationModalHeader')}
          buttonText= {t('packageDetailsPage.downloadConfirmationModalButton')}
          closeModalHandler={() => {
            setConfirmationModalShown(false)
          }}
        >
          {t('packageDetailsPage.downloadConfirmationModalText')}
        </ConfirmationModal> 
      }
    </div>
  )
}

export default PackageDetailsPage
