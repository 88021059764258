import React, { useEffect, Suspense } from 'react'
import './MainComponent.scss'
import { useStateValue } from '../../state/state'
import {Switch, Route} from 'react-router-dom'
import LoginPage from '../pages/admin/LoginPage/LoginPage'
import ResetPasswordPage from '../pages/admin/ResetPasswordPage/ResetPasswordPage'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import CreatorPage from '../pages/admin/CreatorPage/CreatorPage'
import StandardBook from '../pages/admin/CreatorPage/StandardBook/StandardBook'
import PersonalizedBook from '../pages/admin/CreatorPage/PersonalizedBook/PersonalizedBook'
import UsersPage from '../pages/admin/UsersPage/UsersPage'
import OrdersPage from '../pages/admin/OrdersPage/OrdersPage'
import OrdersDetailsPage from '../pages/admin/OrdersPage/OrdersDetailsPage/OrdersDetailsPage'
import { checkTokenValidity, getPritingHouses, getUserData, getClientData } from '../../utils'
import NewPasswordPage from '../pages/admin/NewPasswordPage/NewPasswordPage'
import ProductsPage from '../pages/admin/ProductsPage/ProductsPage'
import ProductDetailsPage from '../pages/admin/ProductsPage/ProductDetailsPage/ProductDetailsPage'
import { default as axios } from '../../axiosSettings'
import { marketsMap } from '../../consts'
import ExistingBookEditor from '../pages/admin/ExistingBookEditor/ExistingBookEditor'
import ShopMainPage from '../pages/shop/ShopMainPage/ShopMainPage'
import ShippingPage from '../pages/admin/ShippingPage/ShippingPage'
import ClientsPage from '../pages/admin/ClientsPage/ClientsPage'
import ShopNavbar from '../pages/shop/ShopNavbar/ShopNavbar'
import ContactPage from '../pages/shop/ContactPage/ContactPage'
import ProfilePage from '../pages/shop/ProfilePage/ProfilePage'
import CartPage from '../pages/shop/CartPage/CartPage'
import ShopFooter from '../pages/shop/ShopFooter/ShopFooter'
import ShopPage from '../pages/shop/ShopPage/ShopPage'
import AboutPage from '../pages/shop/AboutPage/AboutPage'
import FAQPage from '../pages/shop/FAQPage/FAQPage'
import PrivacyPolicyPage from '../pages/shop/PrivacyPolicyPage/PrivacyPolicyPage'
import StandardBookDetailsPage from '../pages/shop/StandardBookDetailsPage/StandardBookDetailsPage'
import ShopLoginPage from '../pages/shop/ShopLoginPage/ShopLoginPage'
import ShopSignUpPage from '../pages/shop/ShopSignUpPage/ShopSignUpPage'
import BuyWithoutRegistrationPage from '../pages/shop/BuyWithoutRegistrationPage/BuyWithoutRegistrationPage'
import OrderDataPage from '../pages/shop/OrderDataPage/OrderDataPage'
import PersonalizePage from '../pages/shop/PersonalizePage/PersonalizePage'
import MyProfilePage from '../pages/shop/MyProfilePage/MyProfilePage'
import AddressDataPage from '../pages/shop/AddressDataPage/AddressDataPage'
import ClientOrdersPage from '../pages/shop/ClientOrdersPage/ClientOrdersPage'
import ClientOrderDetailsPage from '../pages/shop/ClientOrderDetailsPage/ClientOrderDetailsPage'
import EmailVouchersPage from '../pages/admin/EmailVouchersPage/EmailVouchersPage'
import WwwVouchersPage from '../pages/admin/WwwVouchersPage/WwwVouchersPage'
import AddVoucherPage from '../pages/admin/EmailVouchersPage/AddVoucherPage/AddVoucherPage'
import PackagesPage from '../pages/admin/PackagesPage/PackagesPage'
import PackageDetailsPage from '../pages/admin/PackageDetailsPage/PackageDetailsPage'
import PackageSuborderDetailsPage from '../pages/admin/PackageSuborderDetailsPage/PackageSuborderDetailsPage'
import RenewPaymentPage from '../pages/shop/RenewPaymentPage/RenewPaymentPage'
import BannerPage from '../pages/admin/BannerPage/BannerPage'
import {CLIENT_ID, Storage, TOKEN, USER_ID} from "../../helper/storage/Storage";
import {useDetectLanguage} from "../../helper/i18n/useDetectLanguage";
import MetaTags from "../commons/MetaTags/MetaTags";
import CookieConsent from "../CookieConsent";


function MainComponent() {
  const [{ isAdminLoggedIn, userData }, dispatch] = useStateValue()
  const token = Storage.get(TOKEN);
  const userId = Storage.get(USER_ID);
  const clientId = Storage.get(CLIENT_ID);
  const userRole = userData.roles && userData.roles[0]

  const pageLanguage = useDetectLanguage();

  useEffect(() => {
    const token = Storage.get(TOKEN);
    dispatch({ type: 'setData', field: 'isAdminLoggedIn', data: !!token })
  }, [isAdminLoggedIn])

  useEffect(() => {
    checkTokenValidity()
  }, [])

  useEffect(() => {
   const savedCart = sessionStorage.getItem('cart')
   if (savedCart) {
    dispatch({ type: 'setData', field: 'cart', data: JSON.parse(savedCart) })
   }
  }, [])

  useEffect(() => {
    getUserData(userId, token, dispatch)
  }, [userId])

  useEffect(() => {
    getClientData(clientId, token, dispatch)
  }, [clientId])

  useEffect(() => {
    if (!token) return
    axios.get(`api/markets`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        dispatch({ 
          type: 'setData', 
          field: 'marketOptions', 
          data: response.data['hydra:member'].map(option => { 
            return { 
              value: option.id, 
              label: marketsMap[option.countryCode]
            }
          })
        })
      })
  }, [token])

  useEffect(() => {
    if (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') {
      getPritingHouses(token, dispatch)
    }
  }, [token, dispatch, userRole])

  const renderAdminLoggedInPages = () => <React.Fragment>
    <AdminNavbar />
    <ShopNavbar />
    <Switch>
      <Route exact path="/" component={ShopMainPage} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/cart" component={CartPage} />
      <Route exact path="/shop" component={ShopPage} />
      <Route exact path="/shop/:id" component={StandardBookDetailsPage} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/faq" component={FAQPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/login" component={ShopLoginPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/set-new-password" component={NewPasswordPage} />
      <Route exact path="/sign-up" component={ShopSignUpPage} />
      <Route exact path="/buy-without-registration" component={BuyWithoutRegistrationPage} />
      <Route exact path="/order-data" component={OrderDataPage} />
      <Route exact path="/personalize/:id" component={PersonalizePage} />
      <Route exact path="/my-profile" component={MyProfilePage} />
      <Route exact path="/address-data" component={AddressDataPage} />
      <Route exact path="/orders" component={ClientOrdersPage} />
      <Route exact path="/orders/:id" component={ClientOrderDetailsPage} />
      <Route exact path="/retry-payment/:id" component={RenewPaymentPage} />
      { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Route exact path="/admin/creator" component={CreatorPage} /> }
      { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Route exact path="/admin/creator/standard" component={StandardBook} /> }
      { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Route exact path="/admin/creator/personalized" component={PersonalizedBook} /> }
      { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Route exact path="/admin/creator/personalized/:id" component={ExistingBookEditor} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/users" component={UsersPage} /> }
      <Route exact path="/admin/orders" component={OrdersPage} />
      <Route exact path="/admin/orders/:id" component={OrdersDetailsPage} />
      { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Route exact path="/admin/products" component={ProductsPage} /> }
      { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Route exact path="/admin/products/:id" component={ProductDetailsPage} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/email-vouchers" component={EmailVouchersPage} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/email-vouchers/add" component={AddVoucherPage} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/www-vouchers" component={WwwVouchersPage} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/banner" component={BannerPage} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/shipping" component={ShippingPage} /> }
      { userRole === 'ROLE_ADMIN' && <Route exact path="/admin/clients" component={ClientsPage} /> }
      { userRole === 'ROLE_PRINTINGHOUSE' && <Route exact path="/admin/packages" component={PackagesPage} /> }
      { userRole === 'ROLE_PRINTINGHOUSE' && <Route exact path="/admin/packages/:packageId" component={PackageDetailsPage} /> }
      { userRole === 'ROLE_PRINTINGHOUSE' && <Route exact path="/admin/packages/:packageId/suborder/:suborderId" component={PackageSuborderDetailsPage} /> }
      {/* <Route component={Error404Page} /> */}
    </Switch>
    <ShopFooter />
  </React.Fragment>

  const renderLoggedOutPages = () => <React.Fragment>
    <ShopNavbar />
    <Switch>
      <Route exact path="/" component={ShopMainPage} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/cart" component={CartPage} />
      <Route exact path="/shop" component={ShopPage} />
      <Route exact path="/shop/:id" component={StandardBookDetailsPage} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/faq" component={FAQPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/login" component={ShopLoginPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/set-new-password" component={NewPasswordPage} />
      <Route exact path="/sign-up" component={ShopSignUpPage} />
      <Route exact path="/buy-without-registration" component={BuyWithoutRegistrationPage} />
      <Route exact path="/order-data" component={OrderDataPage} />
      <Route exact path="/personalize/:id" component={PersonalizePage} />
      <Route exact path="/my-profile" component={MyProfilePage} />
      <Route exact path="/address-data" component={AddressDataPage} />
      <Route exact path="/orders" component={ClientOrdersPage} />
      <Route exact path="/orders/:id" component={ClientOrderDetailsPage} />
      <Route exact path="/retry-payment/:id" component={RenewPaymentPage} />
      <Route exact path="/admin" component={LoginPage} />
      <Route exact path="/admin/login" component={LoginPage} />
      <Route exact path="/admin/reset-password" component={ResetPasswordPage} />
      <Route exact path="/admin/set-new-password" component={NewPasswordPage} />
      {/* <Route component={Error404Page} /> */}
    </Switch>
    <ShopFooter />
  </React.Fragment>
  

  if (isAdminLoggedIn === null) return null

  return (
    <div className="main-component">

      <MetaTags title="Edikids" />

      <Suspense fallback="" >
        <div className="content-wrapper">
          <CookieConsent />
          { isAdminLoggedIn  && renderAdminLoggedInPages() }
          { !isAdminLoggedIn && renderLoggedOutPages() }
        </div>
      </Suspense>
    </div>
  )
}

export default MainComponent
