import React, { useEffect, useState } from 'react'
import './AddVoucherPage.scss'
import TextInput from '../../../../commons/TextInput/TextInput'
import MultipleSelectInput from '../../../../commons/MultipleSelectInput/MultipleSelectInput'
import VoucherValueInput from '../../../../commons/VoucherValueInput/VoucherValueInput'
import { Link, withRouter } from 'react-router-dom'
import ConfirmationModal from '../../../../commons/ConfirmationModal/ConfirmationModal'
import chevronLeft from '../../../../../assets/chevron_left.svg'
import CalendarInput from '../../../../commons/CalendarInput/CalendarInput'
import { default as axios } from '../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import MinOrderValueInput from '../../../../commons/MinOrderValueInput/MinOrderValueInput'
import Checkbox from '../../../../commons/Checkbox/Checkbox'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


const codeTypesMap = {
  0: '%',
  1: 'zł',
  2: '€',
}


function AddVoucherPage(props) {
  const [emailOptions, setEmailOptions] = useState([])
  const [emails, setEmails] = useState([])
  const [codeValue, setCodeValue] = useState('')
  const [codeValueType, setCodeValueType] = useState(0)
  const [codeText, setCodeText] = useState('')
  const [minOrderPrice, setMinOrderPrice] = useState('')
  const [minOrderPriceType, setMinOrderPriceType] = useState(1)
  const [validFrom, setValidFrom] = useState(null)
  const [validTo, setValidTo] = useState(null)
  const [isSingleUse, setIsSingleUse] = useState(false) 
  const [codeValueError, setCodeValueError] = useState(false)
  const [codeTextError, setCodeTextError] = useState(false)
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    axios.get(`api/users?roles=ROLE_CLIENT`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        const clientsEmails = response.data['hydra:member'].map(item => {
          return {
            value: item.id,
            label: item.email,
          }
        })

        axios.get(`api/users?roles=ROLE_ADMIN`, { 
          headers: { 
            Authorization: `bearer ${token}`,
          }
        })
          .then(response => {
            const usersEmails = response.data['hydra:member'].map(item => {
              return {
                value: item.id,
                label: item.email,
              }
            })
            const groupedEmails = [
              {
                label: t('addEmailCouponPage.usersGroupLabel'),
                options: usersEmails,
              },
              {
                label: t('addEmailCouponPage.clientsGroupLabel'),
                options: clientsEmails,
              },
            ]
            setEmailOptions(groupedEmails)
          })
      })
  }, [])

  const sendCode = () => {
    const codeValueError = !codeValue
    const codeTextError = !codeText

    if (
      !codeValueError
      && !codeTextError
    ) {
      const requestBody = {
        value: parseInt(parseFloat(codeValue).toFixed(2) * 100),
        currencyType: codeValueType,
        text: codeText,
        minTotalPrice: minOrderPrice ? parseInt(parseFloat(minOrderPrice).toFixed(2) * 100) : 0,
        minTotalPriceType: minOrderPrice ? minOrderPriceType : null,
        isSingleUse: isSingleUse,
      }
      if (validFrom) requestBody.validFrom = validFrom
      if (validTo) requestBody.validTo = validTo

      axios.post(`api/email_coupons`, requestBody, { 
        headers: { 
          Authorization: `bearer ${token}`,
        }
      })
        .then(respone => {
          setConfirmationModalShown(true)
          
          if (emails.length) {
            const couponId = respone.data.id
            axios.post(`api/send-coupons`, {
              emails: emails.map(item => item.label),
              couponId: couponId,
            }, { 
              headers: { 
                Authorization: `bearer ${token}`,
              }
            })
          }
        })
    }

    setCodeValueError(codeValueError)
    setCodeTextError(codeTextError)
  }
  
  return (
    <div className="add-voucher-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <Link className="back-link" to="/admin/email-vouchers">
            <img src={chevronLeft} alt="" />
            {t('addEmailCouponPage.backLink')}
          </Link>
          <h2>{t('addEmailCouponPage.header')}</h2>
          <div className="right" />
        </div>

        <div className="input-wrapper w400 mt48">
          <MultipleSelectInput
            label={t('addEmailCouponPage.emailsLabel')}
            options={emailOptions}
            value={emails}
            placeholder={t('addEmailCouponPage.emailsPlaceholder')}
            onChange={values => setEmails(values)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <VoucherValueInput
            label={t('addEmailCouponPage.couponValueLabel')}
            placeholder={t('addEmailCouponPage.couponValuePlaceholder')}
            value={codeValue}
            type={codeValueType}
            error={codeValueError && t('addEmailCouponPage.couponValueValidationMessage')}
            onValueChange={e => setCodeValue(e.target.value)}
            onTypeChange={value => setCodeValueType(value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <TextInput
            label={t('addEmailCouponPage.couponTextLabel')}
            placeholder={t('addEmailCouponPage.couponTextPlaceholder')}
            value={codeText}
            error={codeTextError && t('addEmailCouponPage.couponTextValidationMessage')}
            onChange={e => setCodeText(e.target.value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <MinOrderValueInput
            label={t('addEmailCouponPage.minOrderValueLabel')}
            placeholder={t('addEmailCouponPage.minOrderValuePlaceholder')}
            value={minOrderPrice}
            type={minOrderPriceType}
            onValueChange={e => setMinOrderPrice(e.target.value)}
            onTypeChange={value => setMinOrderPriceType(value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <CalendarInput
            label={t('addEmailCouponPage.validFromLabel')}
            value={validFrom}
            placeholder="DD/MM/RRRR"
            onChange={value => setValidFrom(value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <CalendarInput 
            label={t('addEmailCouponPage.validToLabel')}
            value={validTo}
            placeholder="DD/MM/RRRR"
            onChange={value => setValidTo(value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <Checkbox
            value={isSingleUse}
            onChange={() => setIsSingleUse(!isSingleUse)}
          >
            {t('addEmailCouponPage.singleUse')}
          </Checkbox>
        </div>

        <div className="button-wrapper mt48">
          <button className="button" onClick={sendCode}>{t('addEmailCouponPage.sendButton')}</button>
        </div>
      </div>

      { 
        confirmationModalShown && <ConfirmationModal
          header={t('addEmailCouponPage.confirmationModalTitle')}
          buttonText={t('addEmailCouponPage.confirmationModalButton')}
          closeModalHandler={() => {
            setConfirmationModalShown(false)
            props.history.push('/admin/email-vouchers')
          }}
        >
          {t('addEmailCouponPage.confirmationModalText1')} <strong>{codeText}</strong> {t('addEmailCouponPage.confirmationModalText2')} <strong>{codeValue}{codeTypesMap[codeValueType]}</strong> {t('addEmailCouponPage.confirmationModalText3')}{emails.length ? t('addEmailCouponPage.confirmationModalText4') : ''}.  
        </ConfirmationModal> 
      }
    </div>
  )
}

export default withRouter(AddVoucherPage)
