import React from 'react'
import './ConfirmationModal.scss'
import closeIcon from '../../../assets/close_icon.svg'


function ConfirmationModal({
  header,
  buttonText,
  closeModalHandler,
  children,
}) {
 
  return (
    <div className="confirmation-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{header}</h2>
        <div className="mt24">{children}</div>
        <div className="button-wrapper mt64">
          <button className="button auto-width" onClick={closeModalHandler}>{buttonText}</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
