import React from 'react'
import './LabeledSelect.scss'
import Select from 'react-select'


function LabeledSelect({
  selectedOptionLabel,
  options,
  value,
  placeholder,
  onChange,
}) {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB',
      boxShadow: state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB',
      height: 44,
      borderRadius: 3,
      '&:hover': {
        borderColor: '#DCDFEB',
        boxShadow: 'none',
      },
    }),
    placeholder: (provieded) => ({
      ...provieded,
      color: '#95A4AE',
    }),
    indicatorSeparator: () => null,
  }

  return (
    <div className="labeled-select">
      <Select 
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        value={options.find(option => option.value === value)}
        onChange={onChange}
      />
    </div>
  )
}

export default LabeledSelect