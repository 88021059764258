import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './Breadcrumps.scss'
import { useTranslation } from 'react-i18next'


function Breadcrumps(props) {
  const { itemName } = props
  const { t } = useTranslation()

  const pathMap = {
    'shop': t('breadcrumps.shop'),
    'orders': t('breadcrumps.orders'),
  }

  let pathParts = props.location.pathname.split('/')
  pathParts.shift()

  return (
    <div className="breadcrumps-component">
      <Link to={'/' + pathParts[0]}>{pathMap[pathParts[0]]}</Link>
      <span> &gt; </span>
      <span>{itemName}</span>
    </div>
  )
}

export default withRouter(Breadcrumps)
