export function notifyGTMConversion(transactionId, value, currency = 'PLN')
{
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-10822251752/XpTbCNTovqYDEOjpuago',
      'value': value,
      'currency': currency,
      'transaction_id': transactionId
    });
  }
}