import React from 'react'
import './SelectInput.scss'
import Select from 'react-select'


function SelectInput({
  label,
  options,
  value,
  placeholder,
  error,
  inactive,
  onChange,
}) {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: error ? '1px solid #D21C1C' : (state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB'),
      boxShadow: error ? '1px solid #D21C1C' : (state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB'),
      height: 44,
      borderRadius: 3,
      '&:hover': {
        borderColor: error ? '#D21C1C' : '#DCDFEB',
        boxShadow: 'none',
      },
      background: inactive ? 'rgba(239, 240, 246, 0.2)' : 'initial',
      pointerEvents: inactive ? 'none' : 'initial',
    }),
    placeholder: (provieded) => ({
      ...provieded,
      whiteSpace: 'nowrap',
      color: inactive ? 'rgba(149, 164, 174, 0.5)' : '#95A4AE',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: inactive ? 'rgba(149, 164, 174, 0.5)' : 'initial',
    }),
    indicatorSeparator: () => null,
  }

  return (
    <div className="select-input">
      { label && <div className="input-label">{label}</div> }
      <Select 
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        value={options.find(option => option.value === value)}
        onChange={onChange}
      />
      <div className="input-error">{error}</div>
    </div>
  )
}

export default SelectInput