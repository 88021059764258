import React, { useState, useEffect } from 'react'
import './PackagesPage.scss'
import { Link } from 'react-router-dom'
import { default as axios } from '../../../../axiosSettings'
import Pagination from '../../../commons/Pagination/Pagination'
import { ITEMS_PER_PAGE } from '../../../../consts'
import SortableHeader from '../../../commons/SortableHeader/SortableHeader'
import moment from 'moment'
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN, USER_ID} from "../../../../helper/storage/Storage";


function PackagesPage() {
  const [packages, setPackages] = useState([])
  const [sortBy, setSortBy] = useState('id')
  const [sortDirection, setSortDirection] = useState('desc')
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const token = Storage.get(TOKEN);
  const userId = Storage.get(USER_ID);
  const { t } = useTranslation()

  useEffect(() => {
    getPackages()
  }, [sortBy, sortDirection, currentPageNumber])

  const getPackages = () => {
    let url = `api/packages?printingHouse.id=${userId}&page=${currentPageNumber}&itemsPerPage=${ITEMS_PER_PAGE}&order[${sortBy}]=${sortDirection}`
    axios.get(url, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setPackages(response.data['hydra:member'])
        setTotalItemsCount(response.data['hydra:totalItems'])
      })
  }

  const handleGenerateDownload = packageId => {
    axios.post(`api/package/${packageId}/compress`, {}, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        if (response.status === 200) {
          setConfirmationModalShown(true)
        }
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">
        <SortableHeader text={t('packagesPage.idColumn')} sortByText="id" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <div>{t('packagesPage.periodColumn')}</div>
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('packagesPage.dateColumn')} sortByText="createdAt" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell" />
      <div className="table-header-cell" />
    </div>
  )

  const renderRows = () => (
    packages.map((item, i) => (
      <div key={i} className={`table-row row-${i%2 ? 'even' : 'odd'}`}>
        <div className="table-row-cell">#{item.id}</div>
        <div className="table-row-cell">{moment(item.startDate).format('DD.MM.YYYYY')} - {moment(item.endDate).format('DD.MM.YYYYY')}</div>
        <div className="table-row-cell">{moment(item.createdAt).format('DD.MM.YYYYY')}</div>
        <div className="table-row-cell" onClick={() => handleGenerateDownload(item.id)}>{t('packagesPage.generateButton')}</div>
        <div className="table-row-cell"><Link to={`/admin/packages/${item.id}`}>{t('packagesPage.detailsLink')}</Link></div>
      </div>
    )
  ))

  return (
    <div className="packages-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('packagesPage.header')}</h2>
        </div>

        <div className="table-wrapper mt32">
          <div className="table">
            { packages.length !== 0 ? renderHeaders() : <div>{t('packagesPage.noPackagesInfo')}</div> }
            { renderRows() }
          </div>
          { packages.length !== 0 && <div className="pagination-wrapper">
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE}
              totalItemsCount={totalItemsCount}
              currentPageNumber={currentPageNumber}
              paginationChangeHandler={pageNumber => setCurrentPageNumber(pageNumber)} />
          </div> }
        </div>
      </div>

      {
        confirmationModalShown && <ConfirmationModal
          header={t('packagesPage.confirmationModalHeader')}
          buttonText={t('packagesPage.confirmationModalButton')}
          closeModalHandler={() => {
            setConfirmationModalShown(false)
          }}
        >
          {t('packagesPage.confirmationModalText')}
        </ConfirmationModal>
      }
    </div>
  )
}

export default PackagesPage
