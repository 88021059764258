import React, { useState, useEffect } from 'react'
import './WwwVouchersPage.scss'
import TextInput from '../../../commons/TextInput/TextInput'
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal'
import { currencyMap } from '../../../../consts'
import PriceInput from '../../../commons/PriceInput/PriceInput'
import CalendarInput from '../../../commons/CalendarInput/CalendarInput'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import RemoveWwwVoucherModal from './RemoveWwwVoucherModal/RemoveWwwVoucherModal'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


function WwwVouchersPage() {
  const [couponId, setCouponId] = useState(null)

  const [codeValuePercent, setCodeValuePercent] = useState('')
  const [codeValuePLN, setCodeValuePLN] = useState('')
  const [codeValueEUR, setCodeValueEUR] = useState('')
  const [bannerTextPL, setBannerTextPL] = useState('')
  const [bannerTextES, setBannerTextES] = useState('')
  const [bannerTextEN, setBannerTextEN] = useState('')
  const [codeTextPL, setCodeTextPL] = useState('')
  const [codeTextES, setCodeTextES] = useState('')
  const [codeTextEN, setCodeTextEN] = useState('')
  const [minOrderPricePLN, setMinOrderPricePLN] = useState('')
  const [minOrderPriceEUR, setMinOrderPriceEUR] = useState('')
  const [validFrom, setValidFrom] = useState(null)
  const [validTo, setValidTo] = useState(null)

  const [valuesError, setValuesError] = useState(false)
  const [bannerTextPLError, setBannerTextPLError] = useState(false)
  const [bannerTextESError, setBannerTextESError] = useState(false)
  const [bannerTextENError, setBannerTextENError] = useState(false)
  const [codeTextPLError, setCodeTextPLError] = useState(false)
  const [codeTextESError, setCodeTextESError] = useState(false)
  const [codeTextENError, setCodeTextENError] = useState(false)

  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const [removeModalShown, setRemoveModalShown] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);


  useEffect(() => {
    getWwwCoupon()
  }, [])

  const getWwwCoupon = () => {
    axios.get(`api/coupons`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        const wwwCoupons = response.data['hydra:member']
        const wwwCoupon = wwwCoupons[0]

        if (wwwCoupon) {
          setCouponId(wwwCoupon.id)
          !wwwCoupon.valuePln && !wwwCoupon.valueEur && setCodeValuePercent((parseFloat(wwwCoupon.valuePercent)/100).toFixed(2))
          !wwwCoupon.valuePercent && setCodeValuePLN((parseFloat(wwwCoupon.valuePln)/100).toFixed(2))
          !wwwCoupon.valuePercent && setCodeValueEUR((parseFloat(wwwCoupon.valueEur)/100).toFixed(2))
          setBannerTextPL(wwwCoupon.bannerTextPl)
          setBannerTextES(wwwCoupon.bannerTextEs)
          setBannerTextEN(wwwCoupon.bannerTextEn)
          setCodeTextPL(wwwCoupon.textPl)
          setCodeTextES(wwwCoupon.textEs)
          setCodeTextEN(wwwCoupon.textEn)
          setMinOrderPricePLN((parseFloat(wwwCoupon.minTotalPricePln)/100).toFixed(2))
          setMinOrderPriceEUR((parseFloat(wwwCoupon.minTotalPriceEur)/100).toFixed(2))
          setValidFrom(wwwCoupon.validFrom)
          setValidTo(wwwCoupon.validTo)
        }
      })
  }

  const setCode = () => {
    const valuesError = !codeValuePercent && (!codeValuePLN || !codeValueEUR)
    const bannerTextPLError = !bannerTextPL
    const bannerTextESError = !bannerTextES
    const bannerTextENError = !bannerTextEN
    const codeTextPLError = !codeTextPL
    const codeTextESError = !codeTextES
    const codeTextENError = !codeTextEN

    if (
      !valuesError
      && !bannerTextPLError
      && !bannerTextESError
      && !bannerTextENError
      && !codeTextPLError
      && !codeTextESError
      && !codeTextENError
    ) {
      const requestBody = {
        couponType: 1,
        valuePercent: codeValuePercent ? parseInt(parseFloat(codeValuePercent).toFixed(2) * 100) : 0,
        valuePln: codeValuePLN ? parseInt(parseFloat(codeValuePLN).toFixed(2) * 100) : 0,
        valueEur: codeValueEUR ? parseInt(parseFloat(codeValueEUR).toFixed(2) * 100) : 0,
        textPl: codeTextPL,
        textEs: codeTextES,
        textEn: codeTextEN,
        bannerTextPl: bannerTextPL,
        bannerTextEs: bannerTextES,
        bannerTextEn: bannerTextEN,
        minTotalPricePln: minOrderPricePLN ? parseInt(parseFloat(minOrderPricePLN).toFixed(2) * 100) : 0,
        minTotalPriceEur: minOrderPriceEUR ? parseInt(parseFloat(minOrderPriceEUR).toFixed(2) * 100) : 0,
        validFrom: validFrom || null,
        validTo: validTo || null,
      }
      if (couponId) {
        axios.patch(`api/coupons/${couponId}`, requestBody, { 
          headers: { 
            Authorization: `bearer ${token}`,
            'Content-Type': 'application/merge-patch+json',
          }
        })
          .then(() => {
            setConfirmationModalShown(true)
          })
      } else {
        axios.post(`api/coupons`, requestBody, { 
          headers: { 
            Authorization: `bearer ${token}`,
          }
        })
          .then(() => {
            setConfirmationModalShown(true)
          })
      }
    }

    setValuesError(valuesError)
    setBannerTextPLError(bannerTextPLError)
    setBannerTextESError(bannerTextESError)
    setBannerTextENError(bannerTextENError)
    setCodeTextPLError(codeTextPLError)
    setCodeTextESError(codeTextESError)
    setCodeTextENError(codeTextENError)
  }

  const removeCode = () => {
    axios.delete(`api/coupons/${couponId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      }
    })
      .then(() => {
        setCouponId(null)
        setCodeValuePercent('')
        setCodeValuePLN('')
        setCodeValueEUR('')
        setBannerTextPL('')
        setBannerTextES('')
        setBannerTextEN('')
        setCodeTextPL('')
        setCodeTextES('')
        setCodeTextEN('')
        setMinOrderPricePLN('')
        setMinOrderPriceEUR('')
        setValidFrom(null)
        setValidTo(null)
        getWwwCoupon()
        setRemoveModalShown(false)
      })
  }
  
  return (
    <div className="www-vouchers-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('wwwCouponPage.header')}</h2>
          <div className="right" />
        </div>

        <div className={`input-wrapper w250 mt48 ${codeValuePLN || codeValueEUR ? 'disabled' : ''}`}>
          <TextInput
            label={t('wwwCouponPage.percentCouponLabel')}
            placeholder={t('wwwCouponPage.percentCouponPlaceholder')}
            value={codeValuePercent}
            error={valuesError && t('wwwCouponPage.couponValueValidationMessage')}
            onChange={e => {
              setCodeValuePercent(e.target.value)
              setCodeValuePLN('')
              setCodeValueEUR('')
              setValuesError(false)
            }}
          />
        </div>

        <div className={`input-wrapper w250 mt24 ${codeValuePercent ? 'disabled' : ''}`}>
          <TextInput
            label={t('wwwCouponPage.plnCouponLabel')}
            placeholder={t('wwwCouponPage.plnCouponPlaceholder')}
            value={codeValuePLN}
            error={valuesError && t('wwwCouponPage.couponValueValidationMessage')}
            onChange={e => {
              setCodeValuePLN(e.target.value)
              setCodeValuePercent('')
              setValuesError(false)
            }}
          />
        </div>

        <div className={`input-wrapper w250 mt24 ${codeValuePercent ? 'disabled' : ''}`}>
          <TextInput
            label={t('wwwCouponPage.eurCouponLabel')}
            placeholder={t('wwwCouponPage.eurCouponPlaceholder')}
            value={codeValueEUR}
            error={valuesError && t('wwwCouponPage.couponValueValidationMessage')}
            onChange={e => {
              setCodeValueEUR(e.target.value)
              setCodeValuePercent('')
              setValuesError(false)
            }}
          />
        </div>

        <div className="input-wrapper w400 mt24">
          <TextInput
            label={t('wwwCouponPage.plBannerTextLabel')}
            placeholder={t('wwwCouponPage.plBannerTextPlaceholder')}
            value={bannerTextPL}
            error={bannerTextPLError && t('wwwCouponPage.plBannerTextValidationMessage')}
            onChange={e => setBannerTextPL(e.target.value)}
          />
        </div>

        <div className="input-wrapper w400 mt24">
          <TextInput
            label={t('wwwCouponPage.esBannerTextLabel')}
            placeholder={t('wwwCouponPage.esBannerTextPlaceholder')}
            value={bannerTextES}
            error={bannerTextESError && t('wwwCouponPage.esBannerTextValidationMessage')}
            onChange={e => setBannerTextES(e.target.value)}
          />
        </div>

        <div className="input-wrapper w400 mt24">
          <TextInput
            label={t('wwwCouponPage.enBannerTextLabel')}
            placeholder={t('wwwCouponPage.enBannerTextPlaceholder')}
            value={bannerTextEN}
            error={bannerTextENError && t('wwwCouponPage.enBannerTextValidationMessage')}
            onChange={e => setBannerTextEN(e.target.value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <TextInput
            label={t('wwwCouponPage.plCodeTextLabel')}
            placeholder={t('wwwCouponPage.plCodeTextPlaceholder')}
            value={codeTextPL}
            error={codeTextPLError && t('wwwCouponPage.plCodeTextValidationMessage')}
            onChange={e => setCodeTextPL(e.target.value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <TextInput
            label={t('wwwCouponPage.esCodeTextLabel')}
            placeholder={t('wwwCouponPage.esCodeTextPlaceholder')}
            value={codeTextES}
            error={codeTextESError && t('wwwCouponPage.esCodeTextValidationMessage')}
            onChange={e => setCodeTextES(e.target.value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <TextInput
            label={t('wwwCouponPage.enCodeTextLabel')}
            placeholder={t('wwwCouponPage.enCodeTextPlaceholder')}
            value={codeTextEN}
            error={codeTextENError && t('wwwCouponPage.enCodeTextValidationMessage')}
            onChange={e => setCodeTextEN(e.target.value)}
          />
        </div>
        
        <div className="input-wrapper w250 mt24">
          <PriceInput
            label={t('wwwCouponPage.plnMinOrderValueLabel')}
            value={minOrderPricePLN}
            placeholder={t('wwwCouponPage.plnMinOrderValuePlaceholder')}
            currency={currencyMap['pl']}
            onChange={e => setMinOrderPricePLN(e.target.value)}
          />
        </div>
        
        <div className="input-wrapper w250 mt24">
          <PriceInput
            label={t('wwwCouponPage.eurMinOrderValueLabel')}
            value={minOrderPriceEUR}
            placeholder={t('wwwCouponPage.eurMinOrderValuePlaceholder')}
            currency={currencyMap['en']}
            onChange={e => setMinOrderPriceEUR(e.target.value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <CalendarInput 
            label={t('wwwCouponPage.validFromLabel')}
            value={validFrom}
            placeholder="DD/MM/RRRR"
            onChange={value => setValidFrom(value)}
          />
        </div>

        <div className="input-wrapper w250 mt24">
          <CalendarInput 
            label={t('wwwCouponPage.validToLabel')}
            value={validTo}
            placeholder="DD/MM/RRRR"
            onChange={value => setValidTo(value)}
          />
        </div>

        <div className="button-wrapper mt48">
          <button className="button" onClick={setCode}>{t('wwwCouponPage.setCouponButton')}</button>
        </div>

        { couponId && <div className="button-wrapper mt24">
          <button className="button button-secondary" onClick={() => setRemoveModalShown(true)}>{t('wwwCouponPage.removeCouponButton')}</button>
        </div> }
      </div>

      { 
        confirmationModalShown && <ConfirmationModal 
          header={t('wwwCouponPage.confirmationModalTitle')}
          buttonText={t('wwwCouponPage.confirmationModalButton')}
          closeModalHandler={() => {
            setConfirmationModalShown(false)
            getWwwCoupon()
          }}
        >
          {t('wwwCouponPage.confirmationModalText')}
        </ConfirmationModal>
      }

      { 
        removeModalShown && <RemoveWwwVoucherModal 
          handleRemoveCoupon={removeCode}
          closeModalHandler={() => {
            setRemoveModalShown(false)
          }} />
      }
    </div>
  )
}

export default WwwVouchersPage
