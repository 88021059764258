import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import facebookIcon from '../../../../assets/facebook_icon.svg'
import instagramIcon from '../../../../assets/instagram_icon.svg'
import youtubeIcon from '../../../../assets/youtube_icon.svg'
import privacyPolicyPL from '../../../../assets/files/privacy_policy_pl.pdf'
import privacyPolicyES from '../../../../assets/files/privacy_policy_es.pdf'
import termsOfUsePL from '../../../../assets/files/terms_of_use_pl.pdf'
import termsOfUseES from '../../../../assets/files/terms_of_use_es.pdf'
import termsOfUseEN from '../../../../assets/files/terms_of_use_en.pdf'
import { useTranslation } from 'react-i18next'
import classNames from "classnames";
import css from './ShopFooter.module.scss';

function ShopFooter(props) {
  const [isError404Page, setIsError404Page] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setIsError404Page(document.querySelector('.error-404-page'))
  }, [props.location])

  useEffect(() => {
    setIsAdmin(window.location.pathname.includes('admin'))
  }, [props.location])

  const getPrivacyPolicyFile = () => {
    if (i18n.language === 'en') return privacyPolicyES;
    if (i18n.language === 'es') return privacyPolicyES;
    return privacyPolicyPL;
  }

  const getTermsOfUseFile = () => {
    if (i18n.language === 'en') return termsOfUseEN;
    if (i18n.language === 'es') return termsOfUseES;
    return termsOfUsePL;
  }

  if (isError404Page) return null
  if (isAdmin) return null

  return (
    <div className={classNames(css.ShopFooter, "shop-footer")}>

      <div className={css.InnerFooter}>
        <div className={css.ImageBackground}>
          <div className={css.FooterContent}>
            <div className={css.Line1}>
              <div className={css.text}>
                {t('shopFooter.headerPart1')}<br/>
                {t('shopFooter.headerPart2')}
              </div>
              <div className={css.contactData}>
                <span>{t('shopFooter.email')}</span>
                <span>{t('shopFooter.phone')}</span>
              </div>
            </div>
            <div className={css.Line2}>
              <div className="column">
                <div><strong>{t('shopFooter.edibookHeader')}</strong></div>
                <div><Link to="/">{t('shopFooter.personalizedBooksLink')}</Link></div>
                <div><Link to="/shop">{t('shopFooter.standardBooksLink')}</Link></div>
                <div><Link to="/about">{t('shopFooter.aboutUsLink')}</Link></div>
                <div><Link to="/contact">{t('shopFooter.contactLink')}</Link></div>
              </div>
              <div className="column">
                <div><strong>{t('shopFooter.helpHeader')}</strong></div>
                <div><Link to="/faq">{t('shopFooter.faqLink')}</Link></div>
                <div><a href={getPrivacyPolicyFile()} target="_blank" rel="noopener noreferrer">{t('shopFooter.privacyPolicyLink')}</a></div>
                <div><a href={getTermsOfUseFile()} target="_blank" rel="noopener noreferrer">{t('shopFooter.termsOfUse')}</a></div>
              </div>
              <div className={classNames(css.social, "column")}>
                <div className={css.socialHeader}><strong>{t('shopFooter.socialMediaHeader')}</strong></div>
                <div className={css.socialDesktop}>
                  <a href="https://www.facebook.com/EdiKids-112590257279917" target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="facebook" className={css.icon} /></a>
                  <a href="https://www.instagram.com/edikids_polska" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="instagram" className={css.icon} /></a>
                  <a href="https://www.youtube.com/channel/UCrxj0QCDBA4tfZuaEqfxeSA" target="_blank" rel="noopener noreferrer"><img src={youtubeIcon} alt="youtube" className={css.icon} /></a>
                </div>
              </div>
            </div>
            <div className={css.socialMobile}>
                <a href="https://www.facebook.com/EdiKids-112590257279917" target="_blank" rel="noopener noreferrer" className="social-media-link"><img src={facebookIcon} alt="facebook" className={css.icon} /></a>
                <a href="https://www.instagram.com/edikids_polska" target="_blank" rel="noopener noreferrer" className="social-media-link"><img src={instagramIcon} alt="instagram" className={css.icon} /></a>
                <a href="https://www.youtube.com/channel/UCrxj0QCDBA4tfZuaEqfxeSA" target="_blank" rel="noopener noreferrer" className="social-media-link"><img src={youtubeIcon} alt="youtube" className={css.icon} /></a>
            </div>
          </div>
        </div>
      </div>

      <Link to="/"><div className={css.EdibaLogo} /></Link>
    </div>
  )
}

export default withRouter(ShopFooter)
