import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import css from './index.module.scss';
import frontBookPl from '../../assets/front_product_icon_PL.webp';

function CallToAction({bookCover, scrollTargetRef})
{
  const { t, i18n } = useTranslation()

  function scrollToBooks()
  {
    if (scrollTargetRef && scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView();
    }
  }
  const bookCoverUrl = bookCover ? `url(${bookCover})` : null;

  return (
    <>
      <div className={css.CallToAction}>

        <div className={css.ctaContent}>
          <div className={css.firstSection}>
            <div className={classNames(css.star, css.star1)} />
            <div className={classNames(css.star, css.star2)} />
            <h2 className={css.ctaLine1} dangerouslySetInnerHTML={{ __html: t('personalizedBooksPage.pageHeader1')}} />
            <p className={css.ctaLine2} dangerouslySetInnerHTML={{ __html: t('personalizedBooksPage.pageHeader2')}} />
            {scrollTargetRef && scrollTargetRef.current &&
              <div className={css.ctaLine3}>
                <button type="button" className={css.ctaButton} onClick={scrollToBooks}>
                  {t('personalizedBooksPage.ctaButton')}
                </button>
              </div>
            }
          </div>
          <div className={css.secondSection}>
            <div className={classNames(css.star, css.star3)} />
            <div className={classNames(css.star, css.star4)} />
            <div className={css.layeredContainer}>
              <div className={css.redExplosion} />
              {i18n.language === 'pl' &&
                <div className={css.bookCover} style={{backgroundImage: `url(${frontBookPl})`}}/>
              }
              {bookCover && i18n.language !== 'pl' &&
                <div className={css.bookCover} style={{backgroundImage: bookCoverUrl}}/>
              }
              <div className={css.clouds} />
              <div className={css.omg} />
            </div>
          </div>
        </div>
      </div>

      <div className={css.frills} />
    </>
  )
}

CallToAction.propTypes = {
  bookCover: PropTypes.string,
  scrollTargetRef: PropTypes.object
}

export default CallToAction;
