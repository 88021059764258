import React from 'react'
import './TextMenu.scss'
import textIcon from '../../../../../../../assets/text_icon.svg'
import RadioButtonsGroup from '../../../../../../commons/RadioButtonsGroup/RadioButtonsGroup'
import TextareaInput from '../../../../../../commons/TextareaInput/TextareaInput'
import { useTranslation } from 'react-i18next'


function TextMenu({
  pageNumber,
  isMenuOpen,
  handleMenuClick,
  position,
  boysText,
  girlsText,
  onPositionChange,
  onBoysTextChange,
  onGirlsTextChange,
}) {
  const { t } = useTranslation()

  const options = [
    { value: 1, label: t('pagesEditor.textLeftTopCorner') },
    { value: 2, label: t('pagesEditor.textLeftBottomCorner') },
    { value: 3, label: t('pagesEditor.textRightTopCorner') },
    { value: 4, label: t('pagesEditor.textRightBottomCorner') },
    { value: 0, label: t('pagesEditor.textNone') },
  ]
  
  return (
    <div className="text-menu">
      <div className="menu-header" onClick={() => handleMenuClick(isMenuOpen ? null : 'text')}>
        <img src={textIcon} alt="icon" />
        <span className="menu-header-text">{t('pagesEditor.textLabel')}</span>
      </div>
      {
        isMenuOpen && <div className="menu-options">
          { 
            position !== undefined && <div className="ml16">
              <RadioButtonsGroup
                name={`text-${pageNumber}`}
                value={position}
                options={options}
                error={null}
                verticalOptions={true}
                onChange={value => onPositionChange(value)}
              />
            </div> 
          }
          <div className="mt24">
            <TextareaInput 
              label={t('pagesEditor.textBoysLabel')}
              placeholder={t('pagesEditor.textBoysPlaceholder')}
              value={boysText}
              onChange={e => onBoysTextChange(e.target.value)}
            /> 
          </div>
          <div className="mt12">
            <TextareaInput 
              label={t('pagesEditor.textGirlsLabel')}
              placeholder={t('pagesEditor.textGirlsPlaceholder')}
              value={girlsText}
              onChange={e => onGirlsTextChange(e.target.value)}
            /> 
          </div>
        </div>
      }
    </div>
  )
}

export default TextMenu
