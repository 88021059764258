import React, { useEffect } from 'react'
import './PersonalizedBook.scss'
import BookDetails from './BookDetails/BookDetails'
import PagesEditor from './PagesEditor/PagesEditor'
import { useStateValue } from '../../../../../state/state'


function PersonalizedBook() {
  const [{ creatorPage }, dispatch] = useStateValue()

  useEffect(() => {
    dispatch({ type: 'resetBookData' })
  }, [dispatch])
  
  return (
    <div className="personalized-book">
      { creatorPage === 'bookDetails' && <BookDetails /> }
      { creatorPage === 'pagesEditor' && <PagesEditor /> }
    </div>
  )
}

export default PersonalizedBook
