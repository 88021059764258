import React, { useState, useEffect } from 'react'
import './ClientsPage.scss'
import SortableHeader from '../../../commons/SortableHeader/SortableHeader'
import { ITEMS_PER_PAGE } from '../../../../consts'
import Pagination from '../../../commons/Pagination/Pagination'
import { default as axios } from '../../../../axiosSettings'
import RemoveClientModal from './RemoveClientModal/RemoveClientModal'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import useUrlParameters from "../../../../helper/location/useUrlParameter";


function ClientsPage() {
  const [clientsData, setClientsData] = useState([])
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [clientToRemove, setClientToRemove] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const [ getUrlParameter, setUrlParameter, setUrlParameters ] = useUrlParameters();

  const currentPageNumber = getUrlParameter('page', 1, true);
  function setCurrentPageNumber(val) {
    setUrlParameter('page', parseInt(val), 1)
  }

  const sortBy = getUrlParameter('sortBy', 'id');
  function setSortBy(val) {
    setUrlParameters([['sortBy', val, 'id'], ['page',1,1]])
  }

  const sortDirection = getUrlParameter('sortDir', 'asc');
  function setSortDirection(val) {
    setUrlParameters([['sortDir', val, 'asc'], ['page',1,1]])
  }

  const countriesMap = {
    'AT': t('countries.AT'),
    'BE': t('countries.BE'),
    'BG': t('countries.BG'),
    'HR': t('countries.HR'),
    'CZ': t('countries.CZ'),
    'DK': t('countries.DK'),
    'EE': t('countries.EE'),
    'FI': t('countries.FI'),
    'FR': t('countries.FR'),
    'GR': t('countries.GR'),
    'ES': t('countries.ES'),
    'NL': t('countries.NL'),
    'IE': t('countries.IE'),
    'LT': t('countries.LT'),
    'LU': t('countries.LU'),
    'LV': t('countries.LV'),
    'MC': t('countries.MC'),
    'DE': t('countries.DE'),
    'PL': t('countries.PL'),
    'PT': t('countries.PT'),
    'RO': t('countries.RO'),
    'SK': t('countries.SK'),
    'SE': t('countries.SE'),
    'HU': t('countries.HU'),
    'GB': t('countries.GB'),
    'IT': t('countries.IT'),
  }

  useEffect(() => {
    getClients()
  }, [currentPageNumber, sortBy, sortDirection])

  const getClients = () => {
    let url = `api/users?roles=ROLE_CLIENT&page=${currentPageNumber}&itemsPerPage=${ITEMS_PER_PAGE}&order[${sortBy}]=${sortDirection}`
    axios.get(url, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setClientsData(response.data['hydra:member'])
        setTotalItemsCount(response.data['hydra:totalItems'])
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">
        <SortableHeader text={t('clientsPage.nameColumn')} sortByText="fullName" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('clientsPage.emailColumn')} sortByText="email" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <div>{t('clientsPage.orderTotalColumn')}</div>
        {/* <SortableHeader text="Suma zamówienia" sortByText="totalPrice" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} /> */}
      </div>
      <div className="table-header-cell">
        <div>{t('clientsPage.countryColumn')}</div>
        {/* <SortableHeader text="Kraj" sortByText="country" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} /> */}
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('clientsPage.consentColumn')} sortByText="marketingConsent" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell" />
    </div>
  )

  const renderTotalOrdersPrice = orders => {
    let totalPricePLN = 0
    let totalPriceEUR = 0
    orders.forEach(order => {
      if (order.currencyType === 'PLN') {
        totalPricePLN += order.totalCost
      }
    })
    orders.forEach(order => {
      if (order.currencyType === 'EUR') {
        totalPriceEUR += order.totalCost
      }
    })
    return <>
      { totalPricePLN ? <div>{(totalPricePLN/100).toFixed(2) + ' zł'}</div> : null }
      { totalPriceEUR ? <div>{(totalPriceEUR/100).toFixed(2) + ' €'}</div> : null }
      { !totalPricePLN && !totalPriceEUR && '-' }
    </>

  }

  const renderRows = () => (
    clientsData.map((client, i) => (
      <div key={i} className={`table-row row-${i%2 ? 'even' : 'odd'}`}>
        <div className="table-row-cell">{client.fullName || '-'}</div>
        <div className="table-row-cell">{client.email}</div>
        <div className="table-row-cell">{renderTotalOrdersPrice(client.orders)}</div>
        <div className="table-row-cell">{client.shipping ? countriesMap[client.shipping.country] : '-'}</div>
        <div className="table-row-cell">{client.marketingConsent ? t('clientsPage.yes') : t('clientsPage.no')}</div>
        <div className="table-row-cell" onClick={() => setClientToRemove(client)}>{t('clientsPage.removeButton')}</div>
      </div>
    ))
  )

  return (
    <div className="clients-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('clientsPage.header')}</h2>
          <div className="right" />
        </div>

        <div className="table-wrapper">
          <div className="table">
            { renderHeaders() }
            { renderRows() }
          </div>
        </div>

        <div className="pagination-wrapper">
          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            totalItemsCount={totalItemsCount}
            currentPageNumber={currentPageNumber}
            paginationChangeHandler={pageNumber => setCurrentPageNumber(pageNumber)} />
        </div>
      </div>
      { clientToRemove && <RemoveClientModal clientData={clientToRemove} closeModalHandler={() => setClientToRemove(null)} refreshClientsList={getClients} /> }
    </div>
  )
}

export default ClientsPage
