import React from "react";
import css from './SeeMore.module.scss';
import {useTranslation} from "react-i18next";
import logo1 from '../../../../assets/logo_superthings.webp';
import logo2 from '../../../../assets/logo_mojipops.webp';
import logo3 from '../../../../assets/logo_t-racers.webp';
import logo4 from '../../../../assets/logo_strzal_na_bramke.webp';
import {Link} from "react-router-dom";

function SeeMore() {
  const { i18n, t } = useTranslation()

  switch (i18n.language) {
    case 'pl':
      return renderPL();
    default:
      return null;
  }

  function renderPL() {
    return (
      <div className={css.SeeMore}>
        <h2 className={css.SeeMoreTitle}>{t('personalizedBooksPage.SeeMoreHeader')}</h2>
        <Link to="/shop">
          <div className={css.container}>
            <img className={css.image} src={logo1} alt=""/>
            <img className={css.image} src={logo2} alt=""/>
            <img className={css.image} src={logo3} alt=""/>
            <img className={css.image} src={logo4} alt=""/>
          </div>
        </Link>
      </div>
    )
  }
}

export default SeeMore;