import React from "react";
import PropTypes from "prop-types";
import css from './Feedback.module.scss';

function Feedback({score, text, signature}) {

  function renderStars(score) {
    const result = [];
    for(let i=0; i < score; i++) {
      result.push(
        <div key={i} className={css.star} />
      )
    }
    return result;
  }

  return (
      <div className={css.Feedback}>
        <div className={css.stars}>
          {renderStars(score)}
        </div>
        <div className={css.text}>
          {text}
        </div>
        <div className={css.signature}>
          {signature}
        </div>
      </div>
  )
}

Feedback.propTypes = {
  score: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired
}

export default Feedback;