import React from 'react'
import './BackgroundImageMenu.scss'
import imageIcon from '../../../../../../../assets/image_icon.svg'
import DragAndDrop from '../../../../../../commons/DragAndDrop/DragAndDrop'
import { useTranslation } from 'react-i18next'


function BackgroundImageMenu({
  dragAndDropId,
  isMenuOpen,
  handleMenuClick,
  backgroundImage,
  onChange,
}) {
  const { t } = useTranslation()

  return (
    <div className="background-image-menu">
      <div className="menu-header" onClick={() => handleMenuClick(isMenuOpen ? null : 'backgroundImage')}>
        <img src={imageIcon} alt="icon" />
        <span className="menu-header-text">{t('pagesEditor.backgroundImage')}</span>
      </div>
      {
        isMenuOpen && <div className="menu-options">
          <DragAndDrop 
            componentId={dragAndDropId}
            uploadedFile={backgroundImage} 
            setUploadedFile={onChange} 
            clearUpload={() => onChange(null)} 
          />
        </div>
      }
    </div>
  )
}

export default BackgroundImageMenu
