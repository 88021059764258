import React, { useState } from 'react'
import './BackCoverPage.scss'
import BackgroundImageMenu from '../BackgroundImageMenu/BackgroundImageMenu'
import { useStateValue } from '../../../../../../../state/state'
import { serverURL } from '../../../../../../../config'
import { useTranslation } from 'react-i18next'


function BackCoverPage({
  pageNumber,
  handleImageChange,
}) {
  const [{ bookData }] = useStateValue()
  const [openMenu, setOpenMenu] = useState(null)
  const { t } = useTranslation()
  const pageData = bookData.pages.find(page => page.number === pageNumber)

  const getImageSrc = (uploadedFile) => {
    if (uploadedFile.filePath) {
      return (serverURL + uploadedFile.filePath)
    } else {
      return window.URL.createObjectURL(uploadedFile)
    }
  }

  return (
    <div className="back-cover-page">
      <div className="page-menu">
        <div className="page-name">{t(`pageNames.${pageData.name}`)}</div>
        <div className="page-menu-item mt16">
          <BackgroundImageMenu 
            dragAndDropId={`drag-and-drop-page-${pageNumber}`}
            isMenuOpen={openMenu === 'backgroundImage'}
            handleMenuClick={setOpenMenu} 
            backgroundImage={pageData.backgroundImage}
            onChange={value => handleImageChange(value, pageData)}
          />
        </div>
      </div>
      <div>
        <div className="page-content">
          {
            pageData.backgroundImage && <img src={getImageSrc(pageData.backgroundImage)} className="background-image" alt="background image" />
          }
        </div>
      </div>
    </div>
  )
}

export default BackCoverPage
