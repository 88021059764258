import React from 'react'
import './VoucherValueInput.scss'


function VoucherValueInput({
  label,
  value,
  type,
  error,
  placeholder,
  onValueChange,
  onTypeChange,
}) {

  return (
    <div className="voucher-value-input">
      <div className="input-label">{label}</div>
      <div className="relative-wrapper">
        <input
          className={error ? 'red-border': ''}
          type="number"
          value={value}
          placeholder={placeholder}
          onChange={e => {
            if (e.target.value.length <= 6) {
               onValueChange(e)
            }
          }}
        />
        <div className="types">
          <span className={`type ${type === 0 ? 'type-active' : ''}`} onClick={() => onTypeChange(0)}>%</span>
          <span className={`type ${type === 1 ? 'type-active' : ''}`} onClick={() => onTypeChange(1)}>zł</span>
          <span className={`type ${type === 2 ? 'type-active' : ''}`} onClick={() => onTypeChange(2)}>€</span>
        </div>
      </div>
      <div className="input-error">{error}</div>
    </div>
  )
}

export default VoucherValueInput