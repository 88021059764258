import React, { useState, useEffect } from 'react'
import './ProductDetailsPage.scss'
import { useStateValue } from '../../../../../state/state'
import { default as axios } from '../../../../../axiosSettings'
import { Link, withRouter } from 'react-router-dom'
import DragAndDrop2 from '../../../../commons/DragAndDrop2/DragAndDrop2'
import TextInput from '../../../../commons/TextInput/TextInput'
import TextareaInput from '../../../../commons/TextareaInput/TextareaInput'
import SelectInput from '../../../../commons/SelectInput/SelectInput'
import PriceInput from '../../../../commons/PriceInput/PriceInput'
import { coverFontOptions, innerFontOptions } from '../../../../../consts'
import DeactivateModal from '../DeactivateModal/DeactivateModal'
import ChangesSavedModal from '../ChangesSavedModal/ChangesSavedModal'
import PublishModal from '../PublishModal/PublishModal'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


const currencyMap = {
  1: 'zł',
  2: '€',
  3: '€',
}

function ProductDetailsPage(props) {
  const [{ marketOptions, printingHouseOptions }] = useStateValue()
  const [productData, setProductData] = useState({})
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [coverFont, setCoverFont] = useState(null)
  const [innerFont, setInnerFont] = useState(null)
  const [market, setMarket] = useState(null)
  const [printingHouse, setPrintingHouse] = useState(null)
  const [price, setPrice] = useState('')
  const [isbn, setIsbn] = useState('')
  const [bookImage1, setBookImage1] = useState(null)
  const [bookImage2, setBookImage2] = useState(null)
  const [bookImage3, setBookImage3] = useState(null)
  const [bookImage4, setBookImage4] = useState(null)
  const [bookImage5, setBookImage5] = useState(null)
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [metaKeywords, setMetaKeywords] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [priceError, setPriceError] = useState(false)
  const [isbnError, setIsbnError] = useState('')
  const [bookImage1Error, setBookImage1Error] = useState(false)
  const [deactivateModalShown, setDeactivateModalShown] = useState(false)
  const [changesSavedModalShown, setChangesSavedModalShown] = useState(false)
  const [publishModalShown, setPublishModalShown] = useState(false)
  const [imagesToRemove, setImagesToRemove] = useState([])
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);
  const productId = props.match.params.id

  useEffect(() => {
    axios.get(`api/books/${productId}`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setProductData(response.data)
      })
  }, [productId])

  useEffect(() => {
    if (productData.title) setTitle(productData.title)
    if (productData.description) setDescription(productData.description)
    if (productData.coverFont) setCoverFont(productData.coverFont)
    if (productData.innerFont) setInnerFont(productData.innerFont)
    if (productData.market) setMarket(productData.market.id)
    if (productData.printingHouse) setPrintingHouse(productData.printingHouse.id)
    if (productData.price) setPrice((parseFloat(productData.price)/100).toFixed(2))
    if (productData.isbn) setIsbn(productData.isbn)
    if (productData.bookMainImage) setBookImage1(productData.bookMainImage)
    if (productData.metaTitle) setMetaTitle(productData.metaTitle)
    if (productData.metaDescription) setMetaDescription(productData.metaDescription)
    if (productData.metaKeywords) setMetaKeywords(productData.metaKeywords)
    if (productData.bookAdditionalImages && productData.bookAdditionalImages[0])setBookImage2(productData.bookAdditionalImages[0])
    if (productData.bookAdditionalImages && productData.bookAdditionalImages[1])setBookImage3(productData.bookAdditionalImages[1])
    if (productData.bookAdditionalImages && productData.bookAdditionalImages[2])setBookImage4(productData.bookAdditionalImages[2])
    if (productData.bookAdditionalImages && productData.bookAdditionalImages[3])setBookImage5(productData.bookAdditionalImages[3])
  }, [productData])

  const handleImageChange = (value, endpointName, image, changeHandler) => {
    if (!value) {
      setImagesToRemove(imagesToRemove => [...imagesToRemove, image['@id']])
      changeHandler(null)
    } else if (value && image) {
      setImagesToRemove(imagesToRemove => [...imagesToRemove, image['@id']])
      uploadNewImage(value, endpointName, changeHandler)
    } else if (value && !image) {
      uploadNewImage(value, endpointName, changeHandler)
    }
  }

  const uploadNewImage = (value, endpointName, changeHandler) => {
    const formData = new FormData()
    formData.append('file', value)
    axios.post(`api/${endpointName}`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.status === 201) {
          changeHandler(response.data)
        }
      })
  }

  const valitadeInputs = () => {
    const titleError = !title
    const descriptionError = !description
    const priceError = !price
    const isbnError = !isbn
    const bookImage1Error = !bookImage1

    setTitleError(titleError)
    setDescriptionError(descriptionError)
    setPriceError(priceError)
    setIsbnError(isbnError)
    setBookImage1Error(bookImage1Error)

    return !titleError && !descriptionError && !priceError && !isbnError && !bookImage1Error
  }

  const saveChanges = () => {
    if (!valitadeInputs()) return

    const requestBody = {
      title,
      description,
      price: parseInt(Math.round(parseFloat(price).toFixed(2) * 100)),
      isbn,
      metaTitle,
      metaDescription,
      metaKeywords,
      market: '/api/markets/' + market,
    }
    const bookAdditionalImages = []
    if (productData.isCustomizable) {
      requestBody.printingHouse = '/api/users/' + printingHouse
      requestBody.coverFont = coverFont
      requestBody.innerFont = innerFont
    }
    if (bookImage1 !== productData.bookMainImage) requestBody.bookMainImage = '/api/book_main_images/' + bookImage1.id
    if (bookImage2) bookAdditionalImages.push('/api/book_additional_images/' + bookImage2.id)
    if (bookImage3) bookAdditionalImages.push('/api/book_additional_images/' + bookImage3.id)
    if (bookImage4) bookAdditionalImages.push('/api/book_additional_images/' + bookImage4.id)
    if (bookImage5) bookAdditionalImages.push('/api/book_additional_images/' + bookImage5.id)
    if (bookAdditionalImages.length) requestBody.bookAdditionalImages = bookAdditionalImages

    axios.patch(`api/books/${productData.id}`, requestBody, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        setChangesSavedModalShown(true)
        removeUnneededImages()
      })
  }

  const removeUnneededImages = () => {
    if (!imagesToRemove.length) return
    imagesToRemove.forEach(imageIri => {
      axios.delete(imageIri, { 
        headers: { 
          Authorization: `bearer ${token}`,
        }
      })
    })
  }

  const saveChangesAndPublish = () => {
    if (!valitadeInputs()) {
      setPublishModalShown(false)
      return
    }

    const requestBody = {
      status: 1,
      title,
      description,
      price: parseInt(parseFloat(price).toFixed(2) * 100),
      isbn,
      market: '/api/markets/' + market,
    }
    const bookAdditionalImages = []
    if (productData.isCustomizable) {
      requestBody.printingHouse = '/api/users/' + printingHouse
      requestBody.coverFont = coverFont
      requestBody.innerFont = innerFont
    }
    if (bookImage1 !== productData.bookMainImage) requestBody.bookMainImage = '/api/book_main_images/' + bookImage1.id
    if (bookImage2) bookAdditionalImages.push('/api/book_additional_images/' + bookImage2.id)
    if (bookImage3) bookAdditionalImages.push('/api/book_additional_images/' + bookImage3.id)
    if (bookImage4) bookAdditionalImages.push('/api/book_additional_images/' + bookImage4.id)
    if (bookImage5) bookAdditionalImages.push('/api/book_additional_images/' + bookImage5.id)
    if (bookAdditionalImages.length) requestBody.bookAdditionalImages = bookAdditionalImages

    axios.patch(`api/books/${productData.id}`, requestBody, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
      .then(() => {
        removeUnneededImages()
        props.history.push('/admin/products')
      })
  }

  return (
    <div className="product-details-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <div className="left">
            <h2>{productData.title}</h2>
            <div className="mt8">{productData.isCustomizable ? t('productDetailsPage.personalizedItemType') : t('productDetailsPage.standardItemType')}</div>
          </div>
          <div className="right">
            { productData.status !== 0 && <div className="deactivate-button" onClick={() => setDeactivateModalShown(true)}>{t('productDetailsPage.deactivateButton')}</div> }
          </div>
        </div>

        <div className="columns">
          <div className="left">
            <div className="main-image-wrapper">
              <DragAndDrop2
                isMain={true}
                componentId="book-image-1"
                uploadedFile={bookImage1} 
                error={bookImage1Error && t('productDetailsPage.photoValidationMessage')}
                setUploadedFile={value => handleImageChange(value, 'book_main_images', bookImage1, setBookImage1)}
                clearUpload={() => handleImageChange(null, 'book_main_images', bookImage1, setBookImage1)}
              />
            </div>
            { 
              !productData.isCustomizable && <div className="additional-images-wrapper mt12">
                <DragAndDrop2
                  componentId="book-image-2"
                  uploadedFile={bookImage2} 
                  setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage2, setBookImage2)}
                  clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage2, setBookImage2)}
                />
                <DragAndDrop2
                  componentId="book-image-3"
                  uploadedFile={bookImage3} 
                  setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage3, setBookImage3)}
                  clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage3, setBookImage3)}
                />
                <DragAndDrop2
                  componentId="book-image-4"
                  uploadedFile={bookImage4} 
                  setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage4, setBookImage4)}
                  clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage4, setBookImage4)}
                />
                <DragAndDrop2
                  componentId="book-image-5"
                  uploadedFile={bookImage5} 
                  setUploadedFile={value => handleImageChange(value, 'book_additional_images', bookImage5, setBookImage5)}
                  clearUpload={() => handleImageChange(null, 'book_additional_images', bookImage5, setBookImage5)}
                />
              </div>
            }
            { 
              productData.isCustomizable && <div className="link-wrapper">
                <Link to={`/admin/creator/personalized/${productId}`} className="button">{t('productDetailsPage.goToCreatorButton')}</Link>
              </div>
            }
          </div>

          <div className="right">
            <div className="input-wrapper">
              <TextInput
                label={t('productDetailsPage.titleLabel')}
                placeholder={t('productDetailsPage.titlePlaceholder')}
                value={title}
                error={titleError && t('productDetailsPage.titleValidationMessage')}
                onChange={e => setTitle(e.target.value)}
              />
            </div>
            <div className="input-wrapper mt24">
              <TextareaInput 
                label={t('productDetailsPage.descriptionLabel')}
                placeholder={t('productDetailsPage.descriptionPlaceholder')}
                value={description}
                error={descriptionError && t('productDetailsPage.descriptionValidationMessage')}
                maxCharacters={900}
                onChange={e => setDescription(e.target.value)}
              />
            </div>

            {
              productData.isCustomizable && <div className="columns-2 mt24">
                <div>
                  <div className="input-wrapper">
                    <SelectInput 
                      label={t('productDetailsPage.coverFontLabel')}
                      options={coverFontOptions}
                      value={coverFont}
                      error={null}
                      placeholder={t('productDetailsPage.selectPlaceholder')}
                      onChange={e => setCoverFont(e.value)}
                    />
                  </div>
                </div>
                <div>
                  <div className="input-wrapper">
                    <SelectInput 
                      label={t('productDetailsPage.innerFontLabel')}
                      options={innerFontOptions}
                      value={innerFont}
                      error={null}
                      placeholder={t('productDetailsPage.selectPlaceholder')}
                      onChange={e => setInnerFont(e.value)}
                    />
                  </div>
                </div>
              </div>
            }

            <div className="columns-2 mt24">
              <div>
                <div className="input-wrapper">
                  <SelectInput 
                    label={t('productDetailsPage.versionLabel')}
                    options={marketOptions.map(item => ({
                      value: item.value,
                      label: t(`productDetailsPage.${item.label}`),
                    }))}
                    value={market}
                    error={null}
                    placeholder={t('productDetailsPage.selectPlaceholder')}
                    onChange={e => setMarket(e.value)}
                  />
                </div>
              </div>
              <div>
                <div className="input-wrapper">
                  {
                    productData.isCustomizable
                      ? <SelectInput
                        label={t('productDetailsPage.printingHouseLabel')}
                        options={printingHouseOptions}
                        value={printingHouse}
                        error={null}
                        placeholder={t('productDetailsPage.selectPlaceholder')}
                        onChange={e => setPrintingHouse(e.value)}
                    />
                      : <PriceInput
                        label={t('productDetailsPage.priceLabel')}
                        value={price}
                        error={priceError && t('productDetailsPage.priceValidationMessage')}
                        placeholder={t('productDetailsPage.pricePlaceholder')}
                        currency={currencyMap[market]}
                        onChange={e => setPrice(e.target.value)}
                    />
                  }
                </div>
              </div>
            </div>

            {
              productData.isCustomizable && <div className="columns-2 mt24">
                <div>
                  <div className="input-wrapper">
                    <PriceInput
                      label={t('productDetailsPage.priceLabel')}
                      value={price}
                      error={priceError && t('productDetailsPage.priceValidationMessage')}
                      placeholder={t('productDetailsPage.pricePlaceholder')}
                      currency={currencyMap[market]}
                      onChange={e => setPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            }

            <div className="input-wrapper mt24">
              <TextInput
                label={t('productDetailsPage.isbnLabel')}
                placeholder={t('productDetailsPage.isbnPlaceholder')}
                value={isbn}
                error={isbnError && t('productDetailsPage.isbnValidationMessage')}
                onChange={e => setIsbn(e.target.value)}
              />
            </div>

            <div className="seo-fields-wrapper">
              <h4 className="title">{t('addStandardBookPage.seoSectionTitle')}</h4>
              <div className="input-wrapper">
                <TextInput
                  label={t('addStandardBookPage.metaTitleLabel')}
                  placeholder={t('addStandardBookPage.metaTitlePlaceholder')}
                  value={metaTitle}
                  maxCharacters={250}
                  onChange={e => setMetaTitle(e.target.value)}
                />
              </div>
              <div className="input-wrapper mt24">
                <TextareaInput
                  label={t('addStandardBookPage.metaDescriptionLabel')}
                  placeholder={t('addStandardBookPage.metaDescriptionPlaceholder')}
                  value={metaDescription}
                  maxCharacters={250}
                  onChange={e => setMetaDescription(e.target.value)}
                />
              </div>
              <div className="input-wrapper">
                <TextInput
                  label={t('addStandardBookPage.metaKeywordsLabel')}
                  placeholder={t('addStandardBookPage.metaKeywordsPlaceholder')}
                  value={metaKeywords}
                  maxCharacters={250}
                  onChange={e => setMetaKeywords(e.target.value)}
                />
              </div>
            </div>


            <div className="button-wrapper mt24">
              <button className="button" onClick={saveChanges}>{t('productDetailsPage.saveButton')}</button>
            </div>
            { 
              productData.status === 0 && 
                <div className="button-wrapper mt24">
                  <button className="button button-secondary" onClick={() => setPublishModalShown(true)}>{t('productDetailsPage.saveAndPublishButton')}</button>
                </div>
            }
          </div>
        </div>

        {/* {
          productData.status !== 0 && <div className="history-table-wrapper">
            <hr />
            <div className="table-title">Historia zamówień</div>
            <ProductOrdersTable />
          </div>
        } */}

        { deactivateModalShown && <DeactivateModal productId={productData.id} closeModalHandler={() => setDeactivateModalShown(false)} /> }

        { changesSavedModalShown && <ChangesSavedModal closeModalHandler={() => setChangesSavedModalShown(false)} /> }

        { publishModalShown && <PublishModal saveAndPublishHandler={saveChangesAndPublish} closeModalHandler={() => setPublishModalShown(false)} /> }

      </div>
    </div>
  )
}

export default withRouter(ProductDetailsPage)
