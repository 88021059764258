import React, { useState } from 'react'
import './PublishBookModal.scss'
import closeIcon from '../../../../../../assets/close_icon.svg'
import confetti from '../../../../../../assets/confetti.svg'
import { default as axios } from '../../../../../../axiosSettings'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../../helper/storage/Storage";


function PublishBookModal(props) {
  const { closeModalHandler, bookData } = props
  const [bookPublished, setBookPublished] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const handlePublishBook = () => {
    axios.post(`api/books`, bookData, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(() => {
        setBookPublished(true)
      })
  }

  const handleCloseModal = () => {
    if (bookPublished) {
      closeModalHandler()
      props.history.push('/admin/products')
    } else {
      closeModalHandler()
    }
  }
 
  return (
    <div className="publish-book-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={handleCloseModal} />
        {
          !bookPublished && <React.Fragment>
            <h2>{t('addStandardBookPage.publishConfirmationModalTitle')}</h2>
            <p className="text mt24">{t('addStandardBookPage.publishConfirmationModalText1')} <strong>{t('addStandardBookPage.publishConfirmationModalText2')}</strong> {t('addStandardBookPage.publishConfirmationModalText3')}</p>
            <div className="button-wrapper mt64">
              <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('addStandardBookPage.publishConfirmationModalCancelButton')}</button>
              <button className="button auto-width" onClick={handlePublishBook}>{t('addStandardBookPage.publishConfirmationModalConfirmButton')}</button>
            </div>
          </React.Fragment>
        }

        {
          bookPublished && <React.Fragment>
            <img src={confetti} alt="icon" />
            <h2 className="mt24">{t('addStandardBookPage.publishConfirmationSuccessModalTitle')}</h2>
            <p className="text mt24">{t('addStandardBookPage.publishConfirmationSuccessModalText1')} <strong>{t('addStandardBookPage.publishConfirmationSuccessModalText2')}</strong></p>
          </React.Fragment>
        }
        
      </div>
    </div>
  )
}

export default withRouter(PublishBookModal)
