import React, { useState } from 'react'
import './NewPasswordPage.scss'
import { withRouter } from 'react-router-dom'
import LanguageSelect from '../../../commons/LanguageSelect/LanguageSelect'
import PasswordInput from '../../../commons/PasswordInput/PasswordInput'
import { default as axios } from '../../../../axiosSettings'
import { validatePassword, getQueryVariable } from '../../../../utils'
import { useTranslation } from 'react-i18next'


function NewPasswordPage(props) {
  const [password1, setPassword1] = useState('')
  const [password1Error, setPassword1Error] = useState('')
  const [password2, setPassword2] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [passwordsError, setPasswordsError] = useState('')
  const { t } = useTranslation()

  const handleSetNewPassword = () => {
    const password1Error = !validatePassword(password1)
    const password2Error = !validatePassword(password2)
    const passwordsError = password1 !== password2 && t('newPasswordPage.differentPasswordsValidationMessage')

    if (
      !password1Error
      && !password2Error
      && !passwordsError
    ) {
      axios.post(`api/set-new-password`, {
        token: getQueryVariable('token'),
        plainPassword: password1,
      })
        .then(() => {
          props.history.push(window.location.pathname.includes('admin') ? '/admin/login' : '/login')
        })
        .catch(() => {
          setPasswordsError(t('newPasswordPage.changeFailedMessage'))
        })
    }

    setPassword1Error(password1Error)
    setPassword2Error(password2Error)
    setPasswordsError(passwordsError)
  }

  return (
    <div className="new-password-page">
      <div className="passwords-box">
        { window.location.pathname.includes('admin') && <div className="language-dropdown-wrapper">
          <LanguageSelect />
        </div> }

        <h1>{t('newPasswordPage.header')}</h1>
        <div className="password-hint">{t('newPasswordPage.passwordRules')}</div>

        <div className="input-wrapper password-input-wrapper">
          <PasswordInput
            label={t('newPasswordPage.password1Label')}
            value={password1}
            error={password1Error && t('newPasswordPage.passwordValidationMessage')}
            placeholder={t('newPasswordPage.passwordPlaceholder')}
            onChange={e => setPassword1(e.target.value)}
          />
        </div>

        <div className="input-wrapper password-input-wrapper">
          <PasswordInput
            label={t('newPasswordPage.password2Label')}
            value={password2}
            error={password2Error && t('newPasswordPage.passwordValidationMessage')}
            placeholder={t('newPasswordPage.passwordPlaceholder')}
            onChange={e => setPassword2(e.target.value)}
          />
        </div>

        { !password1Error && !password2Error && passwordsError && <div className="passwords-error">{passwordsError}</div> }

        <div className="button-wrapper">
          <button className="button" onClick={handleSetNewPassword}>{t('newPasswordPage.button')}</button>
        </div>
      </div>

    </div>
  )
}

export default withRouter(NewPasswordPage)
