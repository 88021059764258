import React from 'react'
import './PrivacyPolicyPage.scss'


function PrivacyPolicyPage() {

  return (
    <div className="privacy-policy-page">
    </div>
  )
}

export default PrivacyPolicyPage
