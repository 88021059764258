const LANGUAGE_PL = 'pl';
const LANGUAGE_EN = 'en';
const LANGUAGE_ES = 'es';

const DOMAINS_PL = [ '.pl' ];
const DOMAINS_EN = [ '.eu' ];
const DOMAINS_ES = [ '.es' ];

export const DEFAULT_LANGUAGE = LANGUAGE_PL;

export const SUPPORTED_LANGUAGES =  [LANGUAGE_PL, LANGUAGE_EN, LANGUAGE_ES];

export const SUPPORTED_DOMAINS = {
  [LANGUAGE_PL]: DOMAINS_PL,
  [LANGUAGE_EN]: DOMAINS_EN,
  [LANGUAGE_ES]: DOMAINS_ES,
}

const DomainLanguageDetector = {
  name: 'domainLanguageDetector',

  lookup() {
    return detectDomainLanguage() ?? DEFAULT_LANGUAGE;
  },

  cacheUserLanguage(lng,options) {
  }
}

export const detectDomainLanguage = () => {
  const hostname = document && document.location && typeof document.location.hostname === 'string' && document.location.hostname.toLowerCase();
  if (hostname) {
    for (let language in SUPPORTED_DOMAINS) {
      for (let i = 0; i <  SUPPORTED_DOMAINS[language].length; i++ ) {
        if (hostname.endsWith(SUPPORTED_DOMAINS[language][i])) {
          return language;
        }
      }
    }
  }
  return null;
}

export default DomainLanguageDetector;