import {get, remove, set} from "./driver_cookies";

export const USER_ID = 'userId';
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const AUTO_DETECTED_LANGUAGE = 'autoDetectedLanguage';
export const PAGE_LANGUAGE = 'pageLanguage';
export const CLIENT_ID = 'clientId';

export const Storage = {

  setToken: function (token) {
    const tokenClaims = JSON.parse(atob(token.split('.')[1]));
    const tokenExpirationDate = new Date(tokenClaims.exp * 1000);
    return set(TOKEN, token, { expires: tokenExpirationDate});
  },

  set: function(name, value) {
    return set(name, value);
  },

  get: function(name) {
    return get(name)
  },

  remove: function(name) {
    return remove(name)
  }

}