import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import css from './index.module.scss';
import Toggle from "react-toggle";
import "react-toggle/style.css";
import CookieConsentStorage from "../../helper/storage/CookieConsentStorage";

export default function CookieConsent()
{
    const [isVisible, setIsVisible] = useState(false);
    const [isTrackingConsent, setTrackingConsent] = useState(true);
    const [showDescription, setShowDescription] = useState(false);
    const { t, i18n } = useTranslation()

    useEffect(() => {
        const consents = CookieConsentStorage.getCookieConsent();

        if (!consents.COOKIE_CONSENT_TECHNICAL) {
            setIsVisible(true);
        } else {
            updateGTMConsent(consents.COOKIE_CONSENT_TRACKING);
        }
    }, []);

    const updateGTMConsent = (hasConsent) => {
        if (window.gtag) {
            window.gtag('consent', 'update', {
                'functionality_storage': 'granted',
                'security_storage': 'granted',
                'personalization_storage': 'granted',
                'ad_storage': hasConsent ? 'granted' : 'denied',
                'analytics_storage': hasConsent ? 'granted' : 'denied',
                'ad_user_data': hasConsent ? 'granted' : 'denied',
                'ad_personalization': hasConsent ? 'granted' : 'denied'
            });
        }
    };

    const handleAccept = (tracking) => {
        CookieConsentStorage.setCookieConsentTechnical(true);
        CookieConsentStorage.setCookieConsentTracking(isTrackingConsent);
        setIsVisible(false);
        updateGTMConsent(Boolean(tracking));
    };

    const handleDecline = () => {
        CookieConsentStorage.setCookieConsentTechnical(false);
        CookieConsentStorage.setCookieConsentTracking(false);
        setIsVisible(false);
        updateGTMConsent(false);
    };

    if (!isVisible) return null;

    return (
        <div className={css.cookieConsent}>
            <p>{t('cookieConsent.textExcerpt')}</p>
            <p
                className={classNames(css.moreButton, {[css.hidden]: showDescription})}
                onClick={()=>setShowDescription(true)}
            >
                {t('cookieConsent.button')}
            </p>
            <ul className={css.types}>
                <li>
                    <label className={css.labelka}>
                        <span>{t('cookieConsent.buttonTechnical')}</span>
                        <Toggle checked={true} disabled={true}/>
                        <span
                            className={classNames(css.description, {[css.hidden]: !showDescription})}
                            dangerouslySetInnerHTML={{__html: t('cookieConsent.textTechnical')}}
                        />
                    </label>
                </li>
                <li>
                    <label className={css.labelka}>
                        <span>{t('cookieConsent.buttonTracking')}</span>
                        <Toggle checked={isTrackingConsent} onChange={(e) => setTrackingConsent(e.target.checked)}/>
                        <span
                            className={classNames(css.description, {[css.hidden]: !showDescription})}
                            dangerouslySetInnerHTML={{__html: t('cookieConsent.textTracking')}}
                        />
                    </label>
                </li>
            </ul>
            <div className={css.buttons}>
                <button onClick={handleAccept} className={css.saveButton}>{t('cookieConsent.buttonSave')}</button>
                <button onClick={handleDecline} className={css.declineButton}>{t('cookieConsent.buttonDecline')}</button>
            </div>
        </div>
    );
}