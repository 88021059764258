import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useStateValue } from '../../../../state/state'
import { validateEmail, validatePassword } from '../../../../utils'
import PasswordInput from '../../../commons/PasswordInput/PasswordInput'
import TextInput from '../../../commons/TextInput/TextInput'
import './ShopSignUpPage.scss'
import { default as axios } from '../../../../axiosSettings'
import Checkbox from '../../../commons/Checkbox/Checkbox'
import { useTranslation } from 'react-i18next'
import privacyPolicyPL from '../../../../assets/files/privacy_policy_pl.pdf'
import privacyPolicyES from '../../../../assets/files/privacy_policy_es.pdf'
import termsOfUsePL from '../../../../assets/files/terms_of_use_pl.pdf'
import termsOfUseES from '../../../../assets/files/terms_of_use_es.pdf'
import termsOfUseEN from '../../../../assets/files/terms_of_use_en.pdf'
import {CLIENT_ID, REFRESH_TOKEN, Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";


function ShopSignUpPage(props) {
  const [, dispatch] = useStateValue()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rodoConsent, setRodoConsent] = useState(false)
  const [marketingConsent, setMarketingConsent] = useState(false)
  const [fullNameError, setFullNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [rodoConsentError, setRodoConsentError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { t, i18n } = useTranslation()

  const handleSignUp = () => {
    const fullNameError = !fullName
    const emailError = !validateEmail(email)
    const passwordError = !validatePassword(password)
    const rodoConsentError = !rodoConsent

    if (
      !fullNameError
      && !emailError
      && !passwordError
      && !rodoConsentError
    ) {
      axios.post(`api/register-client`, {
        fullName: fullName,
        email: email,
        plainPassword: password,
        rodoConsent: rodoConsent,
        marketingConsent: marketingConsent,
        language: 'pl',
      })
        .then(() => {
          axios.post(`api/login`, {
            email: email,
            password: password,
          })
            .then(response => {
              if (response.status === 200) {
                Storage.setToken(response.data.token);
                Storage.set(REFRESH_TOKEN, response.data.refresh_token);
                Storage.set(CLIENT_ID, response.data.data.id);
                dispatch({ type: 'setData', field: 'isClientLoggedIn', data: true })
                if (props.location.state && props.location.state.prevPath === '/cart') {
                  props.history.push('/order-data')
                } else {
                  props.history.push('/')
                }
              }
            })
        })
        .catch(() => {
          setErrorMessage(t('registerPage.registerErrorMessage'))
        })
    }

    setFullNameError(fullNameError)
    setEmailError(emailError)
    setPasswordError(passwordError)
    setRodoConsentError(rodoConsentError)
  }

  const getPrivacyPolicyFile = () => {
    if (i18n.language === 'en') return privacyPolicyES;
    if (i18n.language === 'es') return privacyPolicyES;
    return privacyPolicyPL;
  }

  const getTermsOfUseFile = () => {
    if (i18n.language === 'en') return termsOfUseEN;
    if (i18n.language === 'es') return termsOfUseES;
    return termsOfUsePL;
  }

  return (
    <div className="shop-sign-up-page">

      <MetaTags title="Edikids - stwórz konto" />

      <div className="page-content-wrapper">
        <h1>{t('registerPage.header')}</h1>
        <div className="login-link mt8">{t('registerPage.haveAccountText')} <Link to="/login">{t('registerPage.loginLink')}</Link></div>

        <div className="input-wrapper mt32">
          <TextInput
            label={t('registerPage.nameLabel')}
            value={fullName}
            error={fullNameError && t('registerPage.nameValidationMessage')}
            placeholder={t('registerPage.namePlaceholder')}
            onChange={e => setFullName(e.target.value)}
          />
        </div>
        <div className="input-wrapper mt24">
          <TextInput
            label={t('registerPage.emailLabel')}
            value={email}
            error={emailError && t('registerPage.emailValidationMessage')}
            placeholder={t('registerPage.emailPlaceholder')}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="input-wrapper password-input-wrapper mt24">
          <PasswordInput
            label={t('registerPage.passwordLabel')}
            value={password}
            error={passwordError && t('registerPage.passwordValidationMessage')}
            placeholder={t('registerPage.passwordPlaceholder')}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <div className="password-rules">{t('newPasswordPage.passwordRules')}</div>

        <div className="input-wrapper mt32">
          <Checkbox
            value={rodoConsent}
            onChange={setRodoConsent}
            error={rodoConsentError && t('registerPage.consent1ValidationMessage')}
          >
            {t('myProfilePage.consent1Part1')} <a onClick={() => window.open(getTermsOfUseFile())} href={getTermsOfUseFile()} target="_blank" rel="noopener noreferrer">{t('myProfilePage.consent1Part2')}</a> <Link to='/'>{t('myProfilePage.consent1Part3')}</Link> {t('myProfilePage.consent1Part4')}
          </Checkbox>
        </div>

        <div className="input-wrapper mt16">
          <Checkbox
            value={marketingConsent}
            onChange={setMarketingConsent}
            error={null}
          >
            {t('registerPage.consent2')}
          </Checkbox>
        </div>

        <div className="text-smaller mt24">
          {t('registerPage.dataAdministrationInfoPart1')} <a href={getPrivacyPolicyFile()} target="_blank" rel="noopener noreferrer">{t('registerPage.dataAdministrationInfoPart2')}</a>
        </div>

        { errorMessage && <div className="error-message">{errorMessage}</div> }

        <div className="button-wrapper">
          <button className="button" onClick={handleSignUp}>{t('registerPage.registerButton')}</button>
        </div>

        <div className="link-wrapper">
          <Link to="/reset-password">{t('registerPage.forgotPasswordLink')}</Link>
        </div>

      </div>
    </div>
  )
}

export default withRouter(ShopSignUpPage)
