import React, { useState, useEffect } from 'react'
import './UsersPage.scss'
import Pagination from '../../../commons/Pagination/Pagination'
import { ITEMS_PER_PAGE } from '../../../../consts'
import SortableHeader from '../../../commons/SortableHeader/SortableHeader'
import AddUserModal from './AddUserModal/AddUserModal'
import RemoveUserModal from './RemoveUserModal/RemoveUserModal'
import EditUserModal from './EditUserModal/EditUserModal'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN, USER_ID} from "../../../../helper/storage/Storage";
import useUrlParameters from "../../../../helper/location/useUrlParameter";


function UsersPage() {
  const [usersData, setUsersData] = useState([])
  const [sortBy, setSortBy] = useState('email')
  const [sortDirection, setSortDirection] = useState('asc')
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [addUserModalOpen, setAddUserModalOpen] = useState(false)
  const [userToRemove, setUserToRemove] = useState(null)
  const [userToEdit, setUserToEdit] = useState(null)
  const token = Storage.get(TOKEN);
  const currentUserId =  Storage.get(USER_ID);
  const { t } = useTranslation()

  const [ getUrlParameter, setUrlParameter ] = useUrlParameters();

  const currentPageNumber = getUrlParameter('page', 1, true);
  function setCurrentPageNumber(val) {
    setUrlParameter('page', parseInt(val), 1)
  }

  const rolesMap = {
    'ROLE_ADMIN': t('manageUserModals.roleSuperAdmin'),
    'ROLE_CREATOR': t('manageUserModals.roleCreator'),
    'ROLE_PRINTINGHOUSE': t('manageUserModals.rolePrintingHouse'),
  }

  useEffect(() => {
    getUsers()
  }, [currentPageNumber, sortBy, sortDirection])

  const getUsers = () => {
    let url = `api/users?page=${currentPageNumber}&itemsPerPage=${ITEMS_PER_PAGE}&order[${sortBy}]=${sortDirection}&except_roles=ROLE_CLIENT`
    axios.get(url, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setUsersData(response.data['hydra:member'])
        setTotalItemsCount(response.data['hydra:totalItems'])
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">
        <SortableHeader text={t('usersPage.emailColumn')} sortByText="email" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <SortableHeader text={t('usersPage.organizationColumns')} sortByText="organization" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} />
      </div>
      <div className="table-header-cell">
        <div>{t('usersPage.roleColumn')}</div>
        {/* <SortableHeader text="Uprawnienia" sortByText="roles" currentSortBy={sortBy} currenctSortDirection={sortDirection} changeSortByHandler={setSortBy} changeSortDirectionHandler={setSortDirection} /> */}
      </div>
      <div className="table-header-cell" />
    </div>
  )

  const renderRows = () => (
    usersData.map((user, i) => (
      <div key={i} className={`table-row row-${i%2 ? 'even' : 'odd'}`}>
        <div className="table-row-cell">{user.email}</div>
        <div className="table-row-cell">{user.organization}</div>
        <div className="table-row-cell">{rolesMap[user.roles[0]]}</div>
        <div className="table-row-cell options-cell">
          <span className="edit-option" onClick={() => setUserToEdit(user)}>{t('usersPage.editButton')}</span>
          <span className={`remove-option ${currentUserId == user.id ? 'option-inactive' : ''}`} onClick={() => setUserToRemove(user)}>{t('usersPage.removeButton')}</span>
        </div>
      </div>
    ))
  )

  return (
    <div className="users-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('usersPage.header')}</h2>
          <button className="button button-secondary auto-width" onClick={() => setAddUserModalOpen(true)}>{t('usersPage.addUserButton')}</button>
        </div>

        <div className="table-wrapper">
          <div className="table">
            { renderHeaders() }
            { renderRows() }
          </div>
        </div>
        <div className="pagination-wrapper">
          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            totalItemsCount={totalItemsCount}
            currentPageNumber={currentPageNumber}
            paginationChangeHandler={pageNumber => setCurrentPageNumber(pageNumber)} />
        </div>
      </div>

      { addUserModalOpen && <AddUserModal closeModalHandler={() => setAddUserModalOpen(false)} refreshUsersList={getUsers} /> }
      { userToEdit && <EditUserModal userData={userToEdit} closeModalHandler={() => setUserToEdit(null)} refreshUsersList={getUsers} /> }
      { userToRemove && <RemoveUserModal userData={userToRemove} closeModalHandler={() => setUserToRemove(null)} refreshUsersList={getUsers} /> }
    </div>
  )
}

export default UsersPage
