import React, { useState, useEffect } from 'react'
import './EmailVouchersPage.scss'
import { default as axios } from '../../../../axiosSettings'
import moment from 'moment'
import RemoveCouponModal from './RemoveCouponModal/RemoveCouponModal'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../commons/Pagination/Pagination'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


const currencyMap = {
  0: '%',
  1: 'zł',
  2: '€',
}

const ITEMS_PER_PAGE = 30

function EmailVouchersPage() {
  const [coupons, setCoupons] = useState([])
  const [couponToRemove, setCouponToRemove] = useState(null)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    getCoupons()
  }, [currentPageNumber])

  const getCoupons = () => {
    axios.get(`api/email_coupons?page=${currentPageNumber}&itemsPerPage=${ITEMS_PER_PAGE}`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        setCoupons(response.data['hydra:member'])
        setTotalItemsCount(response.data['hydra:totalItems'])
      })
  }

  const handleCouponRemove = () => {
    axios.delete(`api/email_coupons/${couponToRemove.id}`, {
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(() => {
        getCoupons()
        setCouponToRemove(null)
      })
  }

  const renderHeaders = () => (
    <div className="table-header">
      <div className="table-header-cell">{t('emailCouponsPage.couponColumn')}</div>
      <div className="table-header-cell">{t('emailCouponsPage.textColumn')}</div>
      <div className="table-header-cell">{t('emailCouponsPage.minValueColumn')}</div>
      <div className="table-header-cell">{t('emailCouponsPage.validPeriodColumn')}</div>
      <div className="table-header-cell">{t('emailCouponsPage.singleUse')}</div>
      <div className="table-header-cell">{t('emailCouponsPage.usageCount')}</div>
      <div className="table-header-cell" />
    </div>
  )

  const renderCouponValidity = coupon => {
    const from = coupon.validFrom ? moment(coupon.validFrom).format('DD.MM.YYYY') : null
    const to = coupon.validTo ? moment(coupon.validTo).format('DD.MM.YYYY') : null
    if (from && to) {
      return `${from} - ${to}`
    }
    else if (!from && to) {
      return `${t('emailCouponsPage.to')} ${to}`
    }
    else if (from && !to) {
      return `${t('emailCouponsPage.from')} ${from}`
    }
    else {
      return t('emailCouponsPage.indefinitely')
    }
  }

  const renderTable = () => (
    coupons.map((coupon, i) => (
      <div key={i} className="table-row">
        <div className="table-row-cell">{(parseFloat(coupon.value)/100).toFixed(2)}{currencyMap[coupon.currencyType]}</div>
        <div className="table-row-cell">{coupon.text}</div>
        <div className="table-row-cell">{coupon.minTotalPrice ? `${(parseFloat(coupon.minTotalPrice)/100).toFixed(2)} ${currencyMap[coupon.minTotalPriceType]}` : '-'}</div>
        <div className="table-row-cell">{renderCouponValidity(coupon)}</div>
        <div className="table-row-cell">{coupon.isSingleUse ? t('clientsPage.yes') : t('clientsPage.no')}</div>
        <div className="table-row-cell">{coupon.usageCount}</div>
        <div className="table-row-cell" onClick={() => setCouponToRemove(coupon)}>{t('emailCouponsPage.removeButton')}</div>
      </div>
    ))
  )

  return (
    <div className="email-vouchers-page">
      <div className="content-wrapper-inner">
        <div className="columns">
          <div className="left">
            <h2>{t('emailCouponsPage.header')}</h2>
          </div>
          <div className="right">
            <Link to="/admin/email-vouchers/add">
              <button className="button button-secondary">{t('emailCouponsPage.addNewCodeButton')}</button>
            </Link>
          </div>
        </div>
        <div className="table-wrapper">
          { coupons.length ? renderHeaders() : <div>{t('emailCouponsPage.noCouponsText')}</div> }
          { renderTable() }
        </div>
        <div className="pagination-wrapper">
          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            totalItemsCount={totalItemsCount}
            currentPageNumber={currentPageNumber}
            paginationChangeHandler={pageNumber => setCurrentPageNumber(pageNumber)} />
        </div>
      </div>

      { couponToRemove && <RemoveCouponModal coupon={couponToRemove} closeModalHandler={() => setCouponToRemove(null)} removeCouponHandler={handleCouponRemove} /> }
    </div>
  )
}

export default EmailVouchersPage
