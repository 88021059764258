import React, { useEffect, useState } from 'react'
import './OrderDataPage.scss'
import { useStateValue } from '../../../../state/state'
import { serverURL, CLIENT_ID_PLN, CLIENT_ID_EUR } from '../../../../config'
import payULogo from '../../../../assets/payU_logo.svg'
import redsysLogo from '../../../../assets/redsys_logo.svg'
import pencilIcon from '../../../../assets/pencil_icon.svg'
import TextInput from '../../../commons/TextInput/TextInput'
import SelectInput from '../../../commons/SelectInput/SelectInput'
import { plZoneA, plZoneB, esZoneA, esZoneB, euZoneA, euZoneB } from '../../../../consts'
import { validateEmail } from '../../../../utils'
import { default as axios } from '../../../../axiosSettings'
import RadioButtonsGroup from '../../../commons/RadioButtonsGroup/RadioButtonsGroup'
import { useTranslation } from 'react-i18next'
import termsOfUsePL from '../../../../assets/files/terms_of_use_pl.pdf'
import termsOfUseES from '../../../../assets/files/terms_of_use_es.pdf'
import termsOfUseEN from '../../../../assets/files/terms_of_use_en.pdf'
import payuTermsOfUseFile from '../../../../assets/files/regulamin-payu.pdf'
import DiscountInput from '../CartPage/DiscountInput/DiscountInput'
import { withRouter } from 'react-router-dom'
import TransaltionAcceptedModal from './TransaltionAcceptedModal'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";
import MetaTags from "../../../commons/MetaTags/MetaTags";
import classNames from "classnames";


const currencyMap = {
  0: '%',
  1: 'zł',
  2: '€',
}

function OrderDataPage(props) {
  const [{ cart, coupon, clientData, noRegistrationName, noRegistrationEmail }, dispatch] = useStateValue()
  const [shippingCost, setShippingCost] = useState(0)
  const { i18n, t } = useTranslation()

  const [fullName, setFullName] = useState(noRegistrationName || '')
  const [email, setEmail] = useState(noRegistrationEmail || '')
  const [phone, setPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')

  const [invoiceType, setInvoiceType] = useState(0)
  const [invoiceFullName, setInvoiceFullName] = useState('')
  const [invoiceCompanyName, setInvoiceCompanyName] = useState('')
  const [invoiceTaxNumber, setInvoiceTaxNumber] = useState('')
  const [invoiceCountry, setInvoiceCountry] = useState('')
  const [invoiceRegion, setInvoiceRegion] = useState('')
  const [invoiceAddress, setInvoiceAddress] = useState('')
  const [invoiceAddress2, setInvoiceAddress2] = useState('')
  const [invoiceZipCode, setInvoiceZipCode] = useState('')
  const [invoiceCity, setInvoiceCity] = useState('')

  const [fullNameError, setFullNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [countryError, setCountryError] = useState(false)
  const [regionError, setRegionError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [cityError, setCityError] = useState(false)

  const [invoiceFullNameError, setInvoiceFullNameError] = useState(false)
  const [invoiceCompanyNameError, setInvoiceCompanyNameError] = useState(false)
  const [invoiceTaxNumberError, setInvoiceTaxNumberError] = useState(false)
  const [invoiceCountryError, setInvoiceCountryError] = useState(false)
  const [invoiceRegionError, setInvoiceRegionError] = useState(false)
  const [invoiceAddressError, setInvoiceAddressError] = useState(false)
  const [invoiceZipCodeError, setInvoiceZipCodeError] = useState(false)
  const [invoiceCityError, setInvoiceCityError] = useState(false)

  const [isShippingFormSaved, setIsShippingFormSaved] = useState(false)
  const [isShippingFormEditModeActive, setIsShippingFormEditModeActive] = useState(true)
  const [isInvoiceFormSaved, setIsInvoiceFormSaved] = useState(false)
  const [isInvoiceFormEditModeActive, setIsInvoiceFormEditModeActive] = useState(true)

  const [couponText, setCouponText] = useState('')
  const [discountValue, setDiscountValue] = useState(null)
  const [discountCurrency, setDiscountCurrency] = useState(null)
  const [discountMinOrderValue, setDiscountMinOrderValue] = useState(null)
  const [couponError, setCouponError] = useState('')

  const [lastCreatedOrder, setLastCreatedOrder] = useState(null)

  const [isTransactionAcceptedModalShown, setIsTransactionAcceptedModalShown] = useState(false)

  const standardProducts = cart.filter(item => !item.productData.isCustomizable)
  const personalizedProducts = cart.filter(item => item.productData.isCustomizable)
  const token = Storage.get(TOKEN);

  const countriesData = [
    { value: 'AT', label: t('countries.AT') },
    { value: 'BE', label: t('countries.BE') },
    { value: 'BG', label: t('countries.BG') },
    { value: 'HR', label: t('countries.HR') },
    { value: 'CZ', label: t('countries.CZ') },
    { value: 'DK', label: t('countries.DK') },
    { value: 'EE', label: t('countries.EE') },
    { value: 'FI', label: t('countries.FI') },
    { value: 'FR', label: t('countries.FR') },
    { value: 'GR', label: t('countries.GR') },
    { value: 'ES', label: t('countries.ES') },
    { value: 'NL', label: t('countries.NL') },
    { value: 'IE', label: t('countries.IE') },
    { value: 'LT', label: t('countries.LT') },
    { value: 'LU', label: t('countries.LU') },
    { value: 'LV', label: t('countries.LV') },
    { value: 'MC', label: t('countries.MC') },
    { value: 'DE', label: t('countries.DE') },
    { value: 'PL', label: t('countries.PL') },
    { value: 'PT', label: t('countries.PT') },
    { value: 'RO', label: t('countries.RO') },
    { value: 'SK', label: t('countries.SK') },
    { value: 'SE', label: t('countries.SE') },
    { value: 'HU', label: t('countries.HU') },
    { value: 'GB', label: t('countries.GB') },
    { value: 'IT', label: t('countries.IT') },
  ]

  const countriesMap = {
    'AT': t('countries.AT'),
    'BE': t('countries.BE'),
    'BG': t('countries.BG'),
    'HR': t('countries.HR'),
    'CZ': t('countries.CZ'),
    'DK': t('countries.DK'),
    'EE': t('countries.EE'),
    'FI': t('countries.FI'),
    'FR': t('countries.FR'),
    'GR': t('countries.GR'),
    'ES': t('countries.ES'),
    'NL': t('countries.NL'),
    'IE': t('countries.IE'),
    'LT': t('countries.LT'),
    'LU': t('countries.LU'),
    'LV': t('countries.LV'),
    'MC': t('countries.MC'),
    'DE': t('countries.DE'),
    'PL': t('countries.PL'),
    'PT': t('countries.PT'),
    'RO': t('countries.RO'),
    'SK': t('countries.SK'),
    'SE': t('countries.SE'),
    'HU': t('countries.HU'),
    'GB': t('countries.GB'),
    'IT': t('countries.IT'),
  }

  const gendersMap = {
    'boy': t('orderDataPage.boy'),
    'girl': t('orderDataPage.girl'),
  }

  const invoiceTypeOptions = [
    { value: 0, label: t('orderDataPage.invoiceType1') },
    { value: 1, label: t('orderDataPage.invoiceType2') },
  ]

  const getTermsOfUseFile = () => {
    if (i18n.language === 'en') return termsOfUseEN;
    if (i18n.language === 'es') return termsOfUseES;
    return termsOfUsePL;
  }

  useEffect(() => {
    axios.get(`api/shipping_costs`)
      .then(response => {
        const allShippingCosts = response.data['hydra:member']
        let zone = null
        if (i18n.language === 'pl') {
          if (plZoneA.indexOf(country) !== -1) zone = 'plZoneA'
          if (plZoneB.indexOf(country) !== -1) zone = 'plZoneB'
        }
        else if (i18n.language === 'es') {
          if (esZoneA.indexOf(country) !== -1) zone = 'esZoneA'
          if (esZoneB.indexOf(country) !== -1) zone = 'esZoneB'
        }
        else if (i18n.language === 'en') {
          if (euZoneA.indexOf(country) !== -1) zone = 'euZoneA'
          if (euZoneB.indexOf(country) !== -1) zone = 'euZoneB'
        }
        const filteredShippingCosts = allShippingCosts.filter(item => item.origin === i18n.language && item.destination === zone)
        if (filteredShippingCosts.length) setShippingCost(filteredShippingCosts[0].cost)
      })
  }, [i18n.language, country])

  useEffect(() => {
    if (clientData.shipping) {
      axios.get(clientData.shipping['@id'], {
        headers: {
          Authorization: `bearer ${token}`,
        }
      })
        .then(response => {
          setFullName(response.data.fullName)
          setEmail(response.data.email)
          setPhone(response.data.phone)
          setCompanyName(response.data.companyName)
          setCountry(response.data.country)
          setRegion(response.data.state)
          setAddress(response.data.address1)
          setAddress2(response.data.address2)
          setZipCode(response.data.zipCode)
          setCity(response.data.city)
        })
    }
  }, [clientData.shipping])

  useEffect(() => {
    if (clientData.invoice) {
      axios.get(clientData.invoice, {
        headers: {
          Authorization: `bearer ${token}`,
        }
      })
        .then(response => {
          setInvoiceFullName(response.data.fullName)
          setInvoiceCompanyName(response.data.companyName)
          setInvoiceTaxNumber(response.data.taxNumber)
          setInvoiceCountry(response.data.country)
          setInvoiceRegion(response.data.state)
          setInvoiceAddress(response.data.address1)
          setInvoiceAddress2(response.data.address2)
          setInvoiceZipCode(response.data.zipCode)
          setInvoiceCity(response.data.city)
        })
    }
  }, [clientData.invoice])

  useEffect(() => {
    if (isShippingFormSaved) {
      document.getElementById('invoiceFormBox').scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
    }
  }, [isShippingFormSaved]);

  useEffect(() => {
    if (isInvoiceFormSaved) {
      document.getElementById('paymentMethodFormBox').scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
    }
  }, [isInvoiceFormSaved]);

  const saveShippingData = () => {
    const fullNameError = !fullName
    const emailError = !validateEmail(email)
    const phoneError = !phone
    const countryError = !country
    const regionError = !region
    const addressError = !address
    const zipCodeError = !zipCode
    const cityError = !city

    if (
      !fullNameError
      && !emailError
      && !phoneError
      && !countryError
      && !regionError
      && !addressError
      && !zipCodeError
      && !cityError
    ) {
      setIsShippingFormSaved(true)
      setIsShippingFormEditModeActive(false)
    }

    setFullNameError(fullNameError)
    setEmailError(emailError)
    setPhoneError(phoneError)
    setCountryError(countryError)
    setRegionError(regionError)
    setAddressError(addressError)
    setZipCodeError(zipCodeError)
    setCityError(cityError)
  }

  const saveInvoiceData = () => {
    const invoiceFullNameError = !invoiceFullName
    const invoiceCompanyNameError = invoiceType === 1 && !invoiceCompanyName
    const invoiceTaxNumberError = invoiceType === 1 && !invoiceTaxNumber
    const invoiceCountryError = !invoiceCountry
    const invoiceRegionError = !invoiceRegion
    const invoiceAddressError = !invoiceAddress
    const invoiceZipCodeError = !invoiceZipCode
    const invoiceCityError = !invoiceCity

    if (
      !invoiceFullNameError
      && !invoiceCompanyNameError
      && !invoiceTaxNumberError
      && !invoiceCountryError
      && !invoiceRegionError
      && !invoiceAddressError
      && !invoiceZipCodeError
      && !invoiceCityError
    ) {
      setIsInvoiceFormSaved(true)
      setIsInvoiceFormEditModeActive(false)
    }

    setInvoiceFullNameError(invoiceFullNameError)
    setInvoiceCompanyNameError(invoiceCompanyNameError)
    setInvoiceTaxNumberError(invoiceTaxNumberError)
    setInvoiceCountryError(invoiceCountryError)
    setInvoiceRegionError(invoiceRegionError)
    setInvoiceAddressError(invoiceAddressError)
    setInvoiceZipCodeError(invoiceZipCodeError)
    setInvoiceCityError(invoiceCityError)
  }

  const getTotalPrice = () => {
    let totalPrice = 0
    cart.forEach(cartItem => {
      totalPrice += cartItem.count * cartItem.productData.price
    })
    return (parseFloat(totalPrice)/100).toFixed(2)
  }
  const totalPrice = getTotalPrice()

  const renderStandardProducts = () => <section className="standard-product-section">
    {
      cart.map((item, i) => {
        if (item.productData.isCustomizable) return null
        return <div key={i} className="standard-item-box">
          <div>
            <img src={serverURL + item.productData.bookMainImage.filePath} alt="cover" />
            <div className="product-data">
              <div>
                <div className="product-title">{item.productData.title}</div>
                <div className="product-type">{t('orderDataPage.standardProductType')}</div>
              </div>
            </div>
            <div className="product-price medium-and-big-screens">{!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
            <div className="items-counter-wrapper medium-and-big-screens">
              {item.count} {t('orderDataPage.count')}
            </div>
            <div className="total-product-price medium-and-big-screens">{!!item.productData.price && `${(parseFloat(item.productData.price * item.count)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
          </div>

          <div className="item-data-mobile small-screens">
            <div>{!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
            <div>{item.count} {t('orderDataPage.count')}</div>
            <div>{!!item.productData.price && `${(parseFloat(item.productData.price * item.count)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
          </div>
        </div>
      })
    }
  </section>

  const renderPersonalizedProducts = () => <section className="personalized-products-section">
    {
      cart.map((item, i) => {
        if (!item.productData.isCustomizable) return null
        return <div key={i} className="personalized-item-box">
          <div className="box-columns">
            <img src={serverURL + item.productData.bookMainImage.filePath} alt="cover" />
            <div className="product-data">
              <div>
                <div className="product-title">{item.productData.title}</div>
                <div className="product-type">{t('orderDataPage.personalizedProductType')}</div>
              </div>
            </div>
            <div className="product-price medium-and-big-screens">{!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
            <div className="items-counter-wrapper medium-and-big-screens">
              {item.count} {t('orderDataPage.count')}
            </div>
            <div className="total-product-price medium-and-big-screens">{!!item.productData.price && `${(parseFloat(item.productData.price * item.count)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
          </div>
          <div className="personalization-details">
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('orderDataPage.name')}</span>
              <span className="personalization-details-line-text">{item.kidName}</span>
            </div>
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('orderDataPage.gender')}</span>
              <span className="personalization-details-line-text">{gendersMap[item.kidGender]}</span>
            </div>
            <div className="personalization-details-line">
              <span className="personalization-details-line-label">{t('orderDataPage.dedication')}</span>
              <span className="personalization-details-line-text">{item.dedication}</span>
            </div>
          </div>

          <div className="item-data-mobile small-screens">
            <div>{!!item.productData.price && `${(parseFloat(item.productData.price)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
            <div>{item.count} {t('orderDataPage.count')}</div>
            <div>{!!item.productData.price && `${(parseFloat(item.productData.price * item.count)/100).toFixed(2)}`} {i18n.language === 'pl' ? 'zł' : '€'}</div>
          </div>
        </div>
      })
    }
  </section>

  const copyShippingData = () => {
    setInvoiceFullName(fullName)
    setInvoiceCompanyName(companyName)
    setInvoiceCountry(country)
    setInvoiceRegion(region)
    setInvoiceAddress(address)
    setInvoiceAddress2(address2)
    setInvoiceZipCode(zipCode)
    setInvoiceCity(city)
  }

  const renderShippingDataForm = () => <>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.shippingNameLabel')}
        placeholder={t('orderDataPage.shippingNamePlaceholder')}
        value={fullName}
        error={fullNameError && t('orderDataPage.shippingNameValidationMessage')}
        onChange={e => setFullName(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.shippingEmailLabel')}
        placeholder={t('orderDataPage.shippingEmailPlaceholder')}
        value={email}
        error={emailError && t('orderDataPage.shippingEmailValidationMessage')}
        onChange={e => setEmail(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.shippingPhoneLabel')}
        placeholder={t('orderDataPage.shippingPhonePlaceholder')}
        value={phone}
        error={phoneError && t('orderDataPage.shippingPhoneValidationMessage')}
        onChange={e => setPhone(e.target.value)}
      />
      <TextInput
        label={t('orderDataPage.shippingCompanyNameLabel')}
        placeholder={t('orderDataPage.shippingCompanyNamePlaceholder')}
        value={companyName}
        error={null}
        onChange={e => setCompanyName(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <SelectInput
        label={t('orderDataPage.shippingCountryLabel')}
        options={countriesData}
        value={country}
        error={countryError && t('orderDataPage.shippingCountryValidationMessage')}
        placeholder={t('orderDataPage.shippingCountryPlaceholder')}
        onChange={e => setCountry(e.value)}
      />
      <TextInput
        label={t('orderDataPage.shippingRegionLabel')}
        placeholder={t('orderDataPage.shippingRegionPlaceholder')}
        value={region}
        error={regionError && t('orderDataPage.shippingRegionValidationMessage')}
        onChange={e => setRegion(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.shippingAddress1Label')}
        placeholder={t('orderDataPage.shippingAddress1Placeholder')}
        value={address}
        error={addressError && t('orderDataPage.shippingAddress1ValidationMessage')}
        onChange={e => setAddress(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.shippingAddress2Label')}
        placeholder={t('orderDataPage.shippingAddress2Placeholder')}
        value={address2}
        error={null}
        onChange={e => setAddress2(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.shippingZipCodeLabel')}
        placeholder={t('orderDataPage.shippingZipCodePlaceholder')}
        value={zipCode}
        error={zipCodeError && t('orderDataPage.shippingZipCodeValidationMessage')}
        onChange={e => setZipCode(e.target.value)}
      />
      <TextInput
        label={t('orderDataPage.shippingCityLabel')}
        placeholder={t('orderDataPage.shippingCityPlaceholder')}
        value={city}
        error={cityError && t('orderDataPage.shippingCityValidationMessage')}
        onChange={e => setCity(e.target.value)}
      />
    </div>

    <div className="button-wrapper">
      <button className="button auto-width" onClick={saveShippingData}>{t('orderDataPage.shippingSave')}</button>
    </div>
  </>

  const renderShippingDataSummary = () => <div className="form-summary">
    <div className="left">
      <div>{fullName}</div>
      <div>{companyName}</div>
      <div>{email}</div>
      <div>{phone}</div>
      <div>{address}</div>
      <div>{address2}</div>
      <div>{zipCode} {city}</div>
      <div>{region}, {countriesMap[country]}</div>
    </div>
    <div className="right" onClick={() => setIsShippingFormEditModeActive(true)}>
      <img src={pencilIcon} alt="edit" />
      <span>{t('orderDataPage.shippingEdit')}</span>
    </div>
  </div>

  const renderInvoiceDataForm = () => <div className="invoice-form">
    <div className="copy-data-button" onClick={copyShippingData}>{t('orderDataPage.invoiceCopyButton')}</div>
    <div className="mb32">
      <RadioButtonsGroup
        name="invoiceType"
        value={invoiceType}
        options={invoiceTypeOptions}
        onChange={value => setInvoiceType(value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.invoiceNameLabel')}
        placeholder={t('orderDataPage.invoiceNamePlaceholder')}
        value={invoiceFullName}
        error={invoiceFullNameError && t('orderDataPage.invoiceNameValidationMessage')}
        onChange={e => setInvoiceFullName(e.target.value)}
      />
    </div>
    {
      invoiceType === 1 && <div className="input-wrapper">
        <TextInput
          label={t('orderDataPage.invoiceCompanyNameLabel')}
          placeholder={t('orderDataPage.invoiceCompanyNamePlaceholder')}
          value={invoiceCompanyName}
          error={invoiceCompanyNameError && t('orderDataPage.invoiceCompanyNameValidationMessage')}
          onChange={e => setInvoiceCompanyName(e.target.value)}
        />
        <TextInput
          label={t('orderDataPage.invoiceTaxNumberLabel')}
          placeholder={t('orderDataPage.invoiceTaxNumberPlaceholder')}
          value={invoiceTaxNumber}
          error={invoiceTaxNumberError && t('orderDataPage.invoiceTaxNumberValidationMessage')}
          onChange={e => setInvoiceTaxNumber(e.target.value)}
        />
      </div>
    }
    <div className="input-wrapper">
      <SelectInput
        label={t('orderDataPage.invoiceCountryLabel')}
        options={countriesData}
        value={invoiceCountry}
        error={invoiceCountryError && t('orderDataPage.invoiceCountryValidationMessage')}
        placeholder={t('orderDataPage.invoiceCountryPlaceholder')}
        onChange={e => setInvoiceCountry(e.value)}
      />
      <TextInput
        label={t('orderDataPage.invoiceRegionLabel')}
        placeholder={t('orderDataPage.invoiceRegionPlaceholder')}
        value={invoiceRegion}
        error={invoiceRegionError && t('orderDataPage.invoiceRegionValidationMessage')}
        onChange={e => setInvoiceRegion(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.invoiceAddress1Label')}
        placeholder={t('orderDataPage.invoiceAddress1Placeholder')}
        value={invoiceAddress}
        error={invoiceAddressError && t('orderDataPage.invoiceAddress1ValidationMessage')}
        onChange={e => setInvoiceAddress(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.invoiceAddress2Label')}
        placeholder={t('orderDataPage.invoiceAddress2Placeholder')}
        value={invoiceAddress2}
        error={null}
        onChange={e => setInvoiceAddress2(e.target.value)}
      />
    </div>
    <div className="input-wrapper">
      <TextInput
        label={t('orderDataPage.invoiceZipCodeLabel')}
        placeholder={t('orderDataPage.invoiceZipCodePlaceholder')}
        value={invoiceZipCode}
        error={invoiceZipCodeError && t('orderDataPage.invoiceZipCodeValidationMessage')}
        onChange={e => setInvoiceZipCode(e.target.value)}
      />
      <TextInput
        label={t('orderDataPage.invoiceCityLabel')}
        placeholder={t('orderDataPage.invoiceCityPlaceholder')}
        value={invoiceCity}
        error={invoiceCityError && t('orderDataPage.invoiceCityValidationMessage')}
        onChange={e => setInvoiceCity(e.target.value)}
      />
    </div>

    <div className="button-wrapper">
      <button className="button auto-width" onClick={saveInvoiceData}>{t('orderDataPage.invoiceSave')}</button>
    </div>
  </div>

  const renderInvoiceDataSummary = () => <div className="form-summary">
    <div className="left">
      <div>{invoiceFullName}</div>
      { invoiceType === 1 && <div>{invoiceCompanyName}</div> }
      { invoiceType === 1 && <div>{invoiceTaxNumber}</div> }
      <div>{invoiceAddress}</div>
      <div>{invoiceAddress2}</div>
      <div>{invoiceZipCode} {invoiceCity}</div>
      <div>{invoiceRegion}, {countriesMap[invoiceCountry]}</div>
    </div>
    <div className="right" onClick={() => setIsInvoiceFormEditModeActive(true)}>
      <img src={pencilIcon} alt="edit" />
      <span>{t('orderDataPage.invoiceEdit')}</span>
    </div>
  </div>

const calculateFinalPrice = () => {
  let price = parseFloat(totalPrice) + parseFloat(shippingCost/100)
  if (coupon && price >= (coupon.minTotalPrice/100)) {
    if (coupon.currencyType === 0) { // %
      price *= 1-(coupon.value/10000)
    }
    else if (coupon.currencyType === 1) { // pln
      price -= parseFloat(coupon.value/100)
    }
    else if (coupon.currencyType === 2) { // eur
      price -= parseFloat(coupon.value/100)
    }
  }
  return `${(price).toFixed(2)} ${i18n.language === 'pl' ? 'zł' : '€'}`
}

const handlePayment = () => {
  let orderTotalPrice = 0
  cart.forEach(cartItem => {
    orderTotalPrice += cartItem.count * cartItem.productData.price
  })
  let orderFinalPrice = orderTotalPrice + shippingCost
  if (coupon && orderFinalPrice >= coupon.minTotalPrice) {
    if (coupon.currencyType === 0) { // %
      orderFinalPrice *= 1-(coupon.value/10000)
      orderFinalPrice = parseInt(Math.round(orderFinalPrice))
    }
    else if (coupon.currencyType === 1) { // pln
      orderFinalPrice -= parseFloat(coupon.value)
    }
    else if (coupon.currencyType === 2) { // eur
      orderFinalPrice -= parseFloat(coupon.value)
    }
  }

  const orderData = {
    email: clientData.email || noRegistrationEmail,
    fullName: clientData.fullName || noRegistrationName,
    price: orderTotalPrice,
    shippingCost: shippingCost,
    subOrders: [],
    totalCost: orderFinalPrice,
    currencyType: i18n.language === 'pl' ? "PLN" : 'EUR',
    language: i18n.language,
    couponName: coupon ? coupon.couponText : null,
  }
  if (standardProducts.length) {
    orderData.subOrders.push({
      orderPositions: standardProducts.map(item => {
        return {
          book: item.productData['@id'],
          amount: item.count,
        }
      }),
      type: 0, // standard books
      status: 0,
    })
  }
  if (personalizedProducts.length) {
    orderData.subOrders.push({
      orderPositions: personalizedProducts.map(item => {
        return {
          book: item.productData['@id'],
          amount: item.count,
          childName: item.kidName,
          childGender: item.kidGender === 'boy' ? 0 : 1,
          dedication: item.dedication,
          customPhoto: item.kidEditedPhoto['@id'],
        }
      }),
      type: 1, // personalized books
      status: 0,
    })
  }
  if (clientData.id) {
    orderData.user = clientData[`@id`]
  }
  if (coupon) {
    orderData.couponValue = coupon.value
    orderData.couponType = coupon.currencyType
  }

  const paymentRequestData = {
    customerIp: "127.0.0.1",
    merchantPosId: i18n.language === 'pl' ? CLIENT_ID_PLN : CLIENT_ID_EUR,
    description: "Ediba - zamówienie",
    currencyCode: i18n.language === 'pl' ? "PLN" : 'EUR',
    totalAmount: orderFinalPrice,
    notifyUrl: `${serverURL + '/api/payment/notify/payu'}`,
    buyer: {
      email: email,
      phone: phone,
      firstName: fullName.split(' ')[0],
      lastName: fullName.split(' ').length > 1 ? fullName.split(' ')[1] : '',
      language: i18n.language === 'pl' ? "pl" : 'en',
    },
    products: cart.map(item => { return {
      name: item.productData.title,
      unitPrice: item.productData.price,
      quantity: item.count,
    }})
  }

  const shippingRequestBody = {
    fullName: fullName,
    phone: phone,
    email: email,
    companyName: companyName,
    country: country,
    state: region,
    address1: address,
    address2: address2,
    zipCode: zipCode,
    city: city,
  }
  const invoiceRequestBody = {
    fullName: invoiceFullName,
    type: invoiceType,
    country: invoiceCountry,
    state: invoiceRegion,
    address1: invoiceAddress,
    address2: invoiceAddress2,
    zipCode: invoiceZipCode,
    city: invoiceCity
  }
  if (invoiceType === 1) {
    invoiceRequestBody.companyName = invoiceCompanyName
    invoiceRequestBody.taxNumber = invoiceTaxNumber
  }
  if (clientData.shipping) orderData.shipping = clientData.shipping['@id']
  if (clientData.invoice) orderData.invoice = clientData.invoice

  const postOrder = () => {
    axios.post(`api/orders`, orderData)
      .then(response => {
        const createdOrder = response.data
        setLastCreatedOrder(createdOrder)
        paymentRequestData.extOrderId = `${createdOrder.id}-${new Date().getTime()}`

        if (orderData.totalCost > 0) {
          processPayment(createdOrder)
        } else {
          setIsTransactionAcceptedModalShown(true)
        }
      })
  }

  const processPayment = createdOrder => {
    if (i18n.language === 'es') { // RedSys
      axios.post(`api/payment/redsys-payment`, {
        currencyCode : "EUR",
        totalAmount : parseFloat(paymentRequestData.totalAmount/100).toFixed(2),
        language : "ES",
        fullName : fullName,
        description : paymentRequestData.extOrderId,
        extOrderId : createdOrder.id,
        orderId: `${createdOrder.id}${new Date().getTime()}`.substr(0, 12)
    })
      .then(response => {
        dispatch({ type: 'setData', field: 'cart', data: [] })
        sessionStorage.setItem('cart', [])
        document.write(response.data)
      })
    } else { // payU
      axios.post(`api/payment/validate/payu`, paymentRequestData)
        .then(response => {
          window.location.replace(response.data.redirectUri)
          dispatch({ type: 'setData', field: 'cart', data: [] })
          sessionStorage.setItem('cart', [])
        })
    }
  }

  const updateShippingObject = () => {
    axios.patch(clientData.shipping['@id'], shippingRequestBody, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
  }

  const updateInvoiceObject = () => {
    axios.patch(clientData.invoice, invoiceRequestBody, {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/merge-patch+json',
      }
    })
  }

  const createNewShippingObject = () => {
    shippingRequestBody.user = clientData['@id']
    axios.post(`api/shippings`, shippingRequestBody, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
  }

  const createNewInvoiceObject = () => {
    invoiceRequestBody.user = clientData['@id']
    axios.post(`api/invoices`, invoiceRequestBody, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
  }

  axios.post(`api/shippings`, shippingRequestBody)
    .then(shippingResponse => {
      axios.post(`api/invoices`, invoiceRequestBody)
        .then(invoiceResponse => {
          orderData.shipping = shippingResponse.data['@id']
          orderData.invoice = invoiceResponse.data['@id']
          postOrder()

          if (clientData.id) {
            if (clientData.shipping) {
              updateShippingObject()
            } else {
              createNewShippingObject()
            }

            if (clientData.invoice) {
              updateInvoiceObject()
            } else {
              createNewInvoiceObject()
            }
          }
        })
    })
  }

  const checkDiscount = () => {
    axios.post('api/coupon-validation', {
      couponName: couponText,
      language: i18n.language,
    })
      .then(response => {
        if (
          response.data.coupon
          && response.data.coupon.value
          && response.data.coupon.minTotalPrice/100 <= parseFloat(totalPrice) + parseFloat(shippingCost/100)
        ) {
          setDiscountValue(response.data.coupon.value)
          setDiscountCurrency(response.data.coupon.currencyType)
          setDiscountMinOrderValue(response.data.coupon.minTotalPrice)
          dispatch({ type: 'setData', field: 'coupon', data: {...response.data.coupon, couponText: couponText} })
          setCouponError('')
        } else {
          setCouponError(t('cartPage.couponValidationMessage'))
        }
      })
  }

  return (
    <div className="order-data-page">

      <MetaTags title="Edikids - szczegóły zamówienia" />

      <div className="page-content-wrapper">
        <div className="columns">
          <div className="left">

            <div className="box" id="shippingFormBox">
              <div className={classNames("boxHeader", {"expanded":isShippingFormEditModeActive})}>
                <h2>{t('orderDataPage.shippingHeader')}</h2>
                <div className="mt8">{t('orderDataPage.shippingDescription')}</div>
              </div>
              { isShippingFormEditModeActive && renderShippingDataForm() }
              { !isShippingFormEditModeActive && renderShippingDataSummary() }
            </div>

            <div className="box" id="invoiceFormBox">
              <div className={classNames("boxHeader", {"expanded":isInvoiceFormEditModeActive})}>
                <h2>{t('orderDataPage.invoiceHeader')}</h2>
                <div className="mt8">{t('orderDataPage.invoiceDescription')}</div>
              </div>
              { isShippingFormSaved && isInvoiceFormEditModeActive && renderInvoiceDataForm() }
              { isShippingFormSaved && !isInvoiceFormEditModeActive && renderInvoiceDataSummary() }
            </div>

            <div className="box" id="paymentMethodFormBox">
              <h2>{t('orderDataPage.paymentHeader')}</h2>
              <div className="payment-box mt24">
                <img src={i18n.language !== 'es' ? payULogo : redsysLogo} alt="payment system" />
                <div>
                  <div className="payment-box-title">{t('orderDataPage.paymentTitle')}</div>
                  <p className="mt8">{t('orderDataPage.paymentDescription')}</p>
                </div>
              </div>
            </div>

            <div className="box">
              <h2>{t('orderDataPage.myOrderHeader')} ({cart.length})</h2>
              { standardProducts.length ? renderStandardProducts() : null }
              { personalizedProducts.length ? renderPersonalizedProducts() : null }
            </div>

          </div>
          <div className="right">
            <div className="summary-box">
              <h2>{t('orderDataPage.summaryHeader')}</h2>
              <div className="line">
                <div className="line-label">{t('orderDataPage.totalValue')}</div>
                <div className="line-value">{totalPrice} {i18n.language === 'pl' ? 'zł' : '€'}</div>
              </div>
              <div className="line">
                <div className="line-label">{t('orderDataPage.shippingCost')}</div>
                <div className="line-value">{(shippingCost/100).toFixed(2)} {i18n.language === 'pl' ? 'zł' : '€'}</div>
              </div>
              <div className="line discount-input-wrapper">
                { !discountValue && <DiscountInput
                  value={couponText}
                  placeholder={t('cartPage.couponPlaceholder')}
                  error={null}
                  disabled={!isShippingFormSaved}
                  onChange={setCouponText}
                  onButtonClick={checkDiscount}
                /> }
                { couponError && <div className="error-line">{couponError}</div> }
              </div>
              {
                coupon && (parseFloat(totalPrice) + shippingCost/100) >= coupon.minTotalPrice/100 && <div className="line">
                  <div className="line-label">{t('orderDataPage.coupon')}</div>
                  <div className="line-value value-green">-{(parseFloat(coupon.value)/100).toFixed(2)} {currencyMap[coupon.currencyType]}</div>
                </div>
              }
              <hr/>
              <div className="line line-bold">
                <div className="line-label">{t('orderDataPage.paymentAmount')}</div>
                <div className="line-value">{calculateFinalPrice()}</div>
              </div>
              <button className={`button ${(!isShippingFormSaved || !isInvoiceFormSaved || isShippingFormEditModeActive || isInvoiceFormEditModeActive) ? 'button-inactive' : ''}`} onClick={handlePayment}>{t('orderDataPage.paymentButton')}</button>
            </div>
            <div className="payment-info">{t('orderDataPage.paymentTerms1')} <a href={getTermsOfUseFile()} target="_blank" rel="noopener noreferrer">{t('orderDataPage.paymentTerms2')}</a>{t('orderDataPage.paymentTerms3')} <a href={payuTermsOfUseFile} target="_blank" rel="noopener noreferrer">{t('orderDataPage.paymentTerms4')}</a></div>
          </div>
        </div>
      </div>

      { isTransactionAcceptedModalShown && <TransaltionAcceptedModal
        okButtonHandler={() => {
          dispatch({ type: 'setData', field: 'cart', data: [] })
          sessionStorage.setItem('cart', [])
          dispatch({ type: 'setData', field: 'coupon', data: null })
          setIsTransactionAcceptedModalShown(false)
          props.history.push('/')
        }}
        transactionId={lastCreatedOrder}
      /> }
    </div>
  )
}

export default withRouter(OrderDataPage)
