import React, { useState, useEffect } from 'react'
import './BannerPage.scss'
import TextareaInput from '../../../commons/TextareaInput/TextareaInput'
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


function BannerPage() {
  const [bannerTextPl, setBannerTextPl] = useState('')
  const [bannerTextEs, setBannerTextEs] = useState('')
  const [bannerTextEn, setBannerTextEn] = useState('')
  const [confirmationModalShown, setConfirmationModalShown] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  useEffect(() => {
    axios.get(`api/banners`)
      .then(response => {
        setBannerTextPl(response.data['hydra:member'] && response.data['hydra:member'][0] && response.data['hydra:member'][0].textPl)
        setBannerTextEs(response.data['hydra:member'] && response.data['hydra:member'][0] && response.data['hydra:member'][0].textEs)
        setBannerTextEn(response.data['hydra:member'] && response.data['hydra:member'][0] && response.data['hydra:member'][0].textEn)
      })
  }, [])

  const saveBannerTexts = () => {
    axios.post(`api/banners`, {
      textPl: bannerTextPl,
      textEs: bannerTextEs,
      textEn: bannerTextEn,
    }, { 
      headers: { 
        Authorization: `bearer ${token}`,
      }
    })
      .then(() => {
        setConfirmationModalShown(true)
      })
  }
  
  return (
    <div className="banner-page">
      <div className="content-wrapper-inner">
        <div className="first-line">
          <h2>{t('bannerPage.header')}</h2>
          <div className="right" />
        </div>

        <div className="input-wrapper mt24">
          <TextareaInput
            label={t('bannerPage.bannerTextLabelPl')}
            placeholder={t('bannerPage.bannerTextPlaceholderPl')}
            value={bannerTextPl}
            onChange={e => {
              setBannerTextPl(e.target.value)
            }}
          />
        </div>

        <div className="input-wrapper mt24">
          <TextareaInput
            label={t('bannerPage.bannerTextLabelEs')}
            placeholder={t('bannerPage.bannerTextPlaceholderEs')}
            value={bannerTextEs}
            onChange={e => {
              setBannerTextEs(e.target.value)
            }}
          />
        </div>

        <div className="input-wrapper mt24">
          <TextareaInput
            label={t('bannerPage.bannerTextLabelEn')}
            placeholder={t('bannerPage.bannerTextPlaceholderEn')}
            value={bannerTextEn}
            onChange={e => {
              setBannerTextEn(e.target.value)
            }}
          />
        </div>

        <div className="button-wrapper mt48">
          <button className="button" onClick={saveBannerTexts}>{t('bannerPage.button')}</button>
        </div>
      </div>

      { 
        confirmationModalShown && <ConfirmationModal
          header={t('bannerPage.confirmationModalTitle')}
          buttonText={t('bannerPage.confirmationModalButton')}
          closeModalHandler={() => setConfirmationModalShown(false)}
        >
          {t('bannerPage.confirmationModalText')}
        </ConfirmationModal> 
      }
    </div>
  )
}

export default BannerPage
