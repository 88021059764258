import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import DomainLanguageDetector, {DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES} from "./i18n-language-detector";

const languageDetector = new I18nextBrowserLanguageDetector(null, {
  order: [ 'domainLanguageDetector' ]
});
languageDetector.addDetector(DomainLanguageDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false, // disables console output

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
