export const marketsMap = {
  'pl': 'marketPl',
  'es': 'marketEs',
  'en': 'marketEn',
}

export const currencyMap = {
  'pl': 'zł', 
  'es': '€',
  'en': '€',
}

export const rolesMap = {
  'ROLE_ADMIN': 'SuperAdmin',
  'ROLE_CREATOR': 'Kreator',
  'ROLE_PRINTINGHOUSE': 'Drukarnia',
}

export const userRoles = [
  { value: 'ROLE_ADMIN', label: 'SuperAdmin' },
  { value: 'ROLE_CREATOR', label: 'Kreator' },
  { value: 'ROLE_PRINTINGHOUSE', label: 'Drukarnia' },
]

export const orderStatusOptions = [
  { value: 'all', label: 'Wszystkie' },
  { value: 'paid', label: 'Opłacone' },
  { value: 'not_paid', label: 'Nieopłacone' },
  { value: 'in_preparation', label: 'W przygotowaniu' },
  { value: 'printed', label: 'Wydrukowane' },
  { value: 'sent', label: 'Wysłane' },
]

export const ITEMS_PER_PAGE = 10

export const coverFontOptions = [
  { value: 'coiny-regular', label: 'Coiny Regular' },
  { value: 'pol-komikax', label: 'Pol Komikax' },
]

export const innerFontOptions = [
  { value: 'linotte', label: 'Linotte' },
  { value: 'pol-wonder-comic', label: 'Pol Wonder Comic' },
]

export const productStatusOptions = [
  { value: 'all', label: 'Wszystkie' },
  { value: 0, label: 'Nieaktywna' },
  { value: 1, label: 'Aktywna' },
]

export const productStatusMap = {
  0: 'Nieaktywna',
  1: 'Aktywna',
}

export const productTypeOptions = [
  { value: 'all', label: 'Wszystkie' },
  { value: 'standard', label: 'Produkt gotowy' },
  { value: 'personalized', label: 'Do personalizacji' },
]

export const productMarketOptions = [
  { value: 'all', label: 'Wszystkie' },
  { value: 'pl', label: 'Polska' },
  { value: 'es', label: 'Hiszpania' },
  { value: 'en', label: 'Europa' },
]

export const pageNamesMap = {
  1: 'page1Name',
  3: 'page3Name',
  4: 'page4Name',
  5: 'page5Name',
  6: 'page6Name',
  7: 'page7Name',
  8: 'page8Name',
  9: 'page9Name',
  10: 'page10Name',
  11: 'page11Name',
  12: 'page12Name',
  13: 'page13Name',
  14: 'page14Name',
  15: 'page15Name',
  16: 'page16Name',
  17: 'page17Name',
  18: 'page18Name',
  19: 'page19Name',
  20: 'page20Name',
  21: 'page21Name',
  22: 'page22Name',
  23: 'page23Name',
  24: 'page24Name',
  25: 'page25Name',
  26: 'page26Name',
  27: 'page27Name',
  28: 'page28Name',
  29: 'page29Name',
  30: 'page30Name',
  31: 'page31Name',
  32: 'page32Name',
  34: 'page34Name',
}


export const countriesDataPL = [
  { value: 'AT', label: 'Austria' },
  { value: 'BE', label: 'Belgia' },
  { value: 'BG', label: 'Bułgaria' },
  { value: 'HR', label: 'Chorwacja' },
  { value: 'CZ', label: 'Czechy' },
  { value: 'DK', label: 'Dania ' },
  { value: 'EE', label: 'Estonia' },
  { value: 'FI', label: 'Finlandia' },
  { value: 'FR', label: 'Francja' },
  { value: 'GR', label: 'Grecja' },
  { value: 'ES', label: 'Hiszpania' },
  { value: 'NL', label: 'Holandia' },
  { value: 'IE', label: 'Irlandia' },
  { value: 'LT', label: 'Litwa' },
  { value: 'LU', label: 'Luksemburg' },
  { value: 'LV', label: 'Łotwa ' },
  { value: 'MC', label: 'Monako' },
  { value: 'DE', label: 'Niemcy' },
  { value: 'PL', label: 'Polska' },
  { value: 'PT', label: 'Portugalia' },
  { value: 'RO', label: 'Rumunia' },
  { value: 'SK', label: 'Słowacja' },
  { value: 'SE', label: 'Szwecja' },
  { value: 'HU', label: 'Węgry' },
  { value: 'GB', label: 'Wielka Brytania' },
  { value: 'IT', label: 'Włochy' },
]


export const plZoneA = [
  'PL',
]

export const plZoneB = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'GR',
  'ES',
  'NL',
  'IE',
  'LT',
  'LU',
  'LV',
  'MC',
  'DE',
  'PT',
  'RO',
  'SK',
  'SE',
  'HU',
  'GB',
  'IT',
]

export const esZoneA = [
  'ES',
]

export const esZoneB = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'GR',
  'NL',
  'IE',
  'LT',
  'LU',
  'LV',
  'MC',
  'DE',
  'PL',
  'PT',
  'RO',
  'SK',
  'SE',
  'HU',
  'GB',
  'IT',
]

export const euZoneA = [
  'DE',
  'CZ',
  'SK',
  'PL',
]

export const euZoneB = [
  'AT',
  'BE',
  'BG',
  'HR',
  'DK',
  'EE',
  'FI',
  'FR',
  'GR',
  'ES',
  'NL',
  'IE',
  'LT',
  'LU',
  'LV',
  'MC',
  'PT',
  'RO',
  'SE',
  'HU',
  'GB',
  'IT',
]
