import React from 'react'
import './LanguageSelect.scss'
import flagPL from '../../../assets/flag_pl.svg'
import flagEN from '../../../assets/flag_en.svg'
import flagES from '../../../assets/flag_es.svg'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import {useHistory} from "react-router-dom";
import {setLanguage} from "../../../helper/i18n/setLanguage";


const { Option, SingleValue } = components

const OptionWithIcon = (props) => (
  <Option {...props} className="react-select-option">
    <img src={props.data.icon} alt="flag" />
    <span>{props.data.label}</span>
  </Option>
)

const SingleValueWithIcon = (props) => (
  <SingleValue {...props} className="react-select-value">
    <img src={props.data.icon} alt="flag" />
    <span>{props.data.label}</span>
  </SingleValue>
)

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 100,
  }),
  indicatorSeparator: () => null,
}

const options = [
  { value: 'pl', label: 'PL', icon: flagPL },
  { value: 'en', label: 'EN', icon: flagEN },
  { value: 'es', label: 'ES', icon: flagES }
]

function LanguageSelect() {
  const { i18n } = useTranslation()
  const history = useHistory();

  return (
    <Select
      options={options}
      components={{
        Option: OptionWithIcon,
        SingleValue: SingleValueWithIcon,
      }}
      styles={customStyles}
      value={options.find(option => option.value === i18n.language)}
      onChange={e => {
        setLanguage(e.value, history)
      }}
    />
  )
}

export default LanguageSelect
