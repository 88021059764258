import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { validateEmail } from '../../../../utils'
import TextInput from '../../../commons/TextInput/TextInput'
import './BuyWithoutRegistrationPage.scss'
import { useStateValue } from '../../../../state/state'
import { useTranslation } from 'react-i18next'
import MetaTags from "../../../commons/MetaTags/MetaTags";


function BuyWithoutRegistrationPage(props) {
  const [{ noRegistrationName, noRegistrationEmail }, dispatch] = useStateValue()
  const [fullNameError, setFullNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const { t } = useTranslation()

  const validateInputs = () => {
    const fullNameError = !noRegistrationName
    const emailError = !validateEmail(noRegistrationEmail)

    if (
      !fullNameError
      && !emailError
    ) {
      props.history.push('/order-data')
    }

    setFullNameError(fullNameError)
    setEmailError(emailError)
  }

  return (
    <div className="buy-without-registration-page">

      <MetaTags title="Edikids - kup bez rejestracji" />

      <div className="page-content-wrapper">
        <h1>{t('buyWithoutRegistrationPage.header')}</h1>
        <div className="mt8">{t('buyWithoutRegistrationPage.subtitle')}</div>
        <div className="register-link mt4"><Link to="/sign-up">{t('buyWithoutRegistrationPage.createAccountLink')}</Link></div>

        <div className="input-wrapper mt32">
          <TextInput
            label={t('buyWithoutRegistrationPage.nameLabel')}
            value={noRegistrationName}
            error={fullNameError && t('buyWithoutRegistrationPage.nameValidationMessage')}
            placeholder={t('buyWithoutRegistrationPage.namePlaceholder')}
            onChange={e => dispatch({ type: 'setData', field: 'noRegistrationName', data: e.target.value })}
          />
        </div>
        <div className="input-wrapper mt24">
          <TextInput
            label={t('buyWithoutRegistrationPage.emailLabel')}
            value={noRegistrationEmail}
            error={emailError && t('buyWithoutRegistrationPage.emailValidationMessage')}
            placeholder={t('buyWithoutRegistrationPage.emailPlaceholder')}
            onChange={e => dispatch({ type: 'setData', field: 'noRegistrationEmail', data: e.target.value })}
          />
        </div>

        <div className="button-wrapper">
          <button className="button" onClick={validateInputs}>{t('buyWithoutRegistrationPage.button')}</button>
        </div>

      </div>
    </div>
  )
}

export default BuyWithoutRegistrationPage
