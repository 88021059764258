import TagManager from "react-gtm-module";
import {GOOGLE_TAG_MANAGER_DATA_LAYER} from "../../config";

export function notifyGoogleTagManagerEvent(page, details = null)
{
  const config = {
    dataLayer: {
      page: page
    },
    dataLayerName: GOOGLE_TAG_MANAGER_DATA_LAYER
  };
  if (details) {
    config.dataLayer.details = details;
  }
  TagManager.dataLayer(config);
}
