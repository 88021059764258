import {useHistory, useLocation} from "react-router-dom";

export default function useUrlParameters() {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  function getUrlParameter(paramName, defaultValue, isInteger = false) {
    const paramVal = urlSearchParams.get(paramName);
    if (isInteger) {
      return paramVal!==null && parseInt(paramVal) !== defaultValue
        ? parseInt(paramVal)
        : defaultValue
    }
    else {
      return paramVal!==null && paramVal!=='' && paramVal !== defaultValue
        ? paramVal
        : defaultValue
    }
  }

  function setUrlParameters(parameters = []) {
    for(let i=0; i<parameters.length; i++) {
      const paramName = parameters[i][0];
      const newValue = parameters[i][1];
      const defaultValue = parameters[i][2];
      if (newValue!==null && newValue!=='' && newValue !== defaultValue) {
        urlSearchParams.set(paramName, newValue);
      } else {
        urlSearchParams.delete(paramName);
      }
    }
    const currentParamsStr = urlSearchParams.toString();
    history.push({
      pathname: location.pathname,
      search: currentParamsStr ? currentParamsStr : ''
    })
  }

  function setUrlParameter(paramName, newValue, defaultValue) {
    if (newValue!==null && newValue!=='' && newValue !== defaultValue) {
      urlSearchParams.set(paramName, newValue);
    } else {
      urlSearchParams.delete(paramName);
    }
    const currentParamsStr = urlSearchParams.toString();
    history.push({
      pathname: location.pathname,
      search: currentParamsStr ? currentParamsStr : ''
    })
  }

  return [
    getUrlParameter,
    setUrlParameter,
    setUrlParameters
  ]
}
