import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import {SwiperSlide} from "swiper/react";
import classNames from "classnames";
import Feedback from "./Feedback";
import 'swiper/css';
import css from './FeedbackList.module.scss';

function FeedbackList() {
  const { t } = useTranslation()
  const [ feedbacksList, setFeedbacksList ] = useState({});
  const [ slidesPerView, setSlidesPerView ] = useState(3);
  const [ spaceBetweenSlides, setSpaceBetweenSlides ] = useState(40);

  useEffect(()=>{
    if (t) {
      setFeedbacksList(t('personalizedBooksPage.Feedbacks', {returnObjects: true}));
    }
  }, [t])

  useEffect(() => {
    function resizeListener() {
      setTimeout(doneResizing, 500);
    }
    window.addEventListener("resize",resizeListener)
    function doneResizing(){
      setSlidesPerView(Math.floor(window.innerWidth/400));
      setSpaceBetweenSlides(Math.floor(window.innerWidth/15));
    }
    doneResizing();
    return () => {
      window.removeEventListener("resize",resizeListener)
    }
  }, [])

  const hasFeedbacks = Object.values(feedbacksList).length > 0;
  if (!hasFeedbacks) {
    return null;
  }

  return (
      <div className={css.FeedbackList}>
        <h2 className={css.FeedbackListTitle}>{t('personalizedBooksPage.FeedbackHeader')}</h2>
        <Swiper
          spaceBetween={spaceBetweenSlides}
          slidesPerView={slidesPerView}
          modules={[Navigation]}
          navigation={{
            nextEl: `.${css.SwiperButtonNext}`,
            prevEl: `.${css.SwiperButtonPrev}`
          }}
          loop={true}
        >
          {Object.values(feedbacksList).map((item, itemIndex) => (
            <SwiperSlide key={itemIndex} className={css.Slide}>
              <Feedback score={item.score} signature={item.signature} text={item.text} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classNames(css.SwiperButton, css.SwiperButtonPrev)} />
        <div className={classNames(css.SwiperButton, css.SwiperButtonNext)} />
      </div>
  )
}

export default FeedbackList;