import React, { useState } from 'react'
import './PagesEditor.scss'
import editIcon from '../../../../../../assets/edit_icon.svg'
import { useStateValue } from '../../../../../../state/state'
import BookDetailsModal from '../BookDetailsModal/BookDetailsModal'
import SaveDraftModal from '../SaveDraftModal/SaveDraftModal'
import PublishBookModal from '../PublishBookModal/PublishBookModal'
import StandardPage from './StandardPage/StandardPage'
import CoverPage from './CoverPage/CoverPage'
import OnlyBackgroundPage from './OnlyBackgroundPage/OnlyBackgroundPage'
import DedicationPage from './DedicationPage/DedicationPage'
import ThanksPage from './ThanksPage/ThanksPage'
import { default as axios } from '../../../../../../axiosSettings'
import BackCoverPage from './BackCoverPage/BackCoverPage'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../../helper/storage/Storage";


function PagesEditor() {
  const [{ bookData }, dispatch] = useStateValue()
  const [bookDetailsModalOpen, setBookDetailsModalOpen] = useState(false)
  const [saveDraftModalOpen, setSaveDraftModalOpen] = useState(false)
  const [publishBookModalOpen, setPublishBookModalOpen] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const handleImageChange = (value, pageData) => {
    if (!value) {
      removeExistingImage(pageData.uploadedImage.id, pageData.number)
    } else if (value && pageData.uploadedImage) {
      replaceImage(pageData.uploadedImage.id, value, pageData.number)
    } else if (value && !pageData.uploadedImage) {
      uploadNewImage(value, pageData.number)
    }
  }

  const uploadNewImage = (value, pageNumber) => {
    const formData = new FormData()
    formData.append('file', value)
    axios.post(`api/page_background_images`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      } 
    })
      .then(response => {
        if (response.status === 201) {
          dispatch({ type: 'setBookPageData', number: pageNumber, field: 'backgroundImage', data: value})
          dispatch({ type: 'setBookPageData', number: pageNumber, field: 'uploadedImage', data: response.data})
        }
      })
  }

  const removeExistingImage = (imageId, pageNumber) => {
    axios.delete(`api/page_background_images/${imageId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        if (response.status === 204) {
          dispatch({ type: 'setBookPageData', number: pageNumber, field: 'backgroundImage', data: null})
          dispatch({ type: 'setBookPageData', number: pageNumber, field: 'uploadedImage', data: null})
        }
      })
  }

  const replaceImage = (imageId, value, pageNumber) => {
    axios.delete(`api/page_background_images/${imageId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
    uploadNewImage(value, pageNumber)
  }

  return (
    <div className="pages-editor">

      <div className="top-bar">
        <div className="top-bar-inner">
          <div className="left">
            <h2>{bookData.title}</h2>
            <img src={editIcon} alt="edit icon" onClick={() => setBookDetailsModalOpen(true)} />
          </div>
          <div className="right">
            <button className="button auto-width button-secondary mr16" onClick={() => setSaveDraftModalOpen(true)}>{t('pagesEditor.saveButton')}</button>
            <button className="button auto-width" onClick={() => setPublishBookModalOpen(true)}>{t('pagesEditor.savaAndAddPriceButton')}</button>
          </div>
        </div>
      </div>

      <div className={`pages ${
        bookData.converFont === 'coiny-regular' ? 'cover-font-coiny-regular' : ''
      } ${
        bookData.converFont === 'pol-komikax' ? 'cover-font-pol-komikax' : ''
      } ${
        bookData.innerFont === 'linotte' ? 'inner-font-linotte' : ''
      } ${
        bookData.innerFont === 'pol-wonder-comic' ? 'inner-font-pol-wonder-comic' : ''
      }`}>
        {
          bookData.pages.map((page, i) => <div className="page-wrapper" key={i}>
            { page.type === 0 && <CoverPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 1 && <OnlyBackgroundPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 2 && <DedicationPage pageNumber={page.number} handleImageChange={handleImageChange} /> } 
            { page.type === 3 && <StandardPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 4 && <ThanksPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            { page.type === 5 && <BackCoverPage pageNumber={page.number} handleImageChange={handleImageChange} /> }
            <div className="separator">
              <div className="separator-line"/>
              <div className="separator-text">{t(`pageNames.${page.name}`)}</div>
              <div className="separator-line"/>
            </div>
          </div>)
        }
      </div>

      { bookDetailsModalOpen && <BookDetailsModal closeModalHandler={() => setBookDetailsModalOpen(false)} /> }
      { saveDraftModalOpen && <SaveDraftModal closeModalHandler={() => {
        setSaveDraftModalOpen(false)
      }} /> }
      { publishBookModalOpen && <PublishBookModal closeModalHandler={() => setPublishBookModalOpen(false)} /> }
    </div>
  )
}

export default withRouter(PagesEditor)
