import React from 'react'
import './RemoveUserModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { getPritingHouses } from '../../../../../utils'
import { default as axios } from '../../../../../axiosSettings'
import { useStateValue } from '../../../../../state/state'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../helper/storage/Storage";


function RemoveUserModal({
  userData,
  closeModalHandler,
  refreshUsersList,
}) {
  const [, dispatch] = useStateValue()
  const token = Storage.get(TOKEN);
  const { t } = useTranslation()

  const handleRemoveUser = () => {
    axios.delete(`api/users/${userData.id}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
      .then(response => {
        if (response.status === 204) {
          closeModalHandler()
          refreshUsersList()
          getPritingHouses(token, dispatch)
        }
      })
  }
 
  return (
    <div className="remove-user-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2>{t('usersPage.removeModalTitle')} {userData.email}</h2>

        <div className="mt24">{t('usersPage.removeModalText')}</div>

        <div className="button-wrapper mt64">
          <button className="button auto-width button-secondary mr16" onClick={closeModalHandler}>{t('usersPage.removeModalCancelButton')}</button>
          <button className="button auto-width" onClick={handleRemoveUser}>{t('usersPage.removeModalConfirmationButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default RemoveUserModal
