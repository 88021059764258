import React from 'react'
import './SortableHeader.scss'
import sortArrowUp from '../../../assets/sort_arrow_up.svg'
import sortArrowDown from '../../../assets/sort_arrow_down.svg'


function SortableHeader({ text, sortByText, currentSortBy, currenctSortDirection, changeSortByHandler, changeSortDirectionHandler }) {
  return (
    <div className="sortable-header" onClick={() => {
      currentSortBy === sortByText
        ? changeSortDirectionHandler(currenctSortDirection === 'asc' ? 'desc' : 'asc')
        : changeSortDirectionHandler('asc')
      changeSortByHandler(sortByText)
    }}>
        <div className="header-text">{text}</div>
        <div className="header-arrows">
          <img src={sortArrowUp} alt="" className={`sort-arrow ${currentSortBy === sortByText && currenctSortDirection === 'desc' ? 'sort-arrow-selected' : ''}`} />
          <img src={sortArrowDown} alt="" className={`sort-arrow ${currentSortBy === sortByText && currenctSortDirection === 'asc' ? 'sort-arrow-selected' : ''}`} />
        </div>
    </div>
  )
}

export default SortableHeader