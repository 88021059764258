import React, {useEffect, useState} from 'react';
import Modal from "../Modal";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ReactCrop from "react-image-crop";
import css from './CropperModal.module.scss';

function CropperModal({isOpen, onClose, onSave, uploadedFile}) {

  const [reactCropSource, setReactCropSource] = useState(null)
  const [sourceDimensions, setSourceDimensions] = useState({width: 0, height: 0})
  const [percentCrop, setPercentCrop] = useState(null);

  const isCropperModalOpen = !!isOpen && !!uploadedFile;

  const { t } = useTranslation();

  function updateCrop(pxCrop, percentCrop) {
    setPercentCrop(percentCrop);
  }

  function onActionSave() {
    getCroppedImageUrl().then((croppedUrl)=>{
      setReactCropSource(null);
      setSourceDimensions({width: 0, height: 0});
      onSave(croppedUrl)
      onClose();
    })
  }

  function getCroppedImageUrl() {
    return new Promise((resolve,reject)=>{
      if (typeof reactCropSource === 'string') {

        const destinationDimensions = {
          x: sourceDimensions.width * (percentCrop.x / 100),
          y: sourceDimensions.height * (percentCrop.y / 100),
          height: sourceDimensions.height * (percentCrop.height / 100),
          width: sourceDimensions.width * (percentCrop.width / 100),
        };
        const canvas = document.createElement('canvas');
        canvas.width = destinationDimensions.width;
        canvas.height = destinationDimensions.height;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(
            image,
            destinationDimensions.x,
            destinationDimensions.y,
            destinationDimensions.width,
            destinationDimensions.height,
            0,
            0,
            destinationDimensions.width,
            destinationDimensions.height,
          )
          resolve(canvas.toDataURL('image/jpeg'));
        }
        image.src = reactCropSource;

      } else {
        reject();
      }
    })
  }

  // Initialize image data (once per load)
  useEffect(()=> {
    if (isCropperModalOpen) {
      const sourceImageUrl = window.URL.createObjectURL(uploadedFile);
      const source = new Image();
      source.onload = () => {
        setSourceDimensions({width: source.width, height: source.height})
        setPercentCrop({
          aspect: 1.0,
          unit: '%',
          x: 5,
          y: 5,
          width: 90
        });
        setReactCropSource(sourceImageUrl);
      };
      source.src = sourceImageUrl;
    }
  }, [isCropperModalOpen, uploadedFile])

  const previewAspectRatio = sourceDimensions.width > 0 && sourceDimensions.height > 0
    ? `${sourceDimensions.width} / ${sourceDimensions.height}`
    : '1 / 1';

  return (
    <Modal
      isOpen={isCropperModalOpen}
      onClose={onClose}
      skipOutsideClosing={true}
      onAction={onActionSave}
      title={t('personalizePage.cropModalTitle')}
      actions={[t('personalizePage.cropModalButton')]}
    >
      <div className={css.CropperModal} style={{aspectRatio: previewAspectRatio}}>
        {reactCropSource &&
          <ReactCrop
            src={reactCropSource}
            crop={percentCrop}

            keepSelection={true}
            onChange={updateCrop}
          />
        }
      </div>
    </Modal>
  )
}

CropperModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  uploadedFile: PropTypes.object
}

export default CropperModal;