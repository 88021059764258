import React, { useState, useEffect } from 'react'
import './ShippingPage.scss'
import EditShippingDataModal from './EditShippingDataModal/EditShippingDataModal'
import { default as axios } from '../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../helper/storage/Storage";


function ShippingPage() {
  const [shippingData, setShippingData] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const originsMap = {
    'pl': t('shippingCostsPage.versionPl'),
    'es': t('shippingCostsPage.versionEs'),
    'en': t('shippingCostsPage.versionEn'),
  }

  const destinationsMap = {
    'plZoneA': t('shippingCostsPage.europeA'),
    'plZoneB': t('shippingCostsPage.europeB'),
    'esZoneA': t('shippingCostsPage.europeA'),
    'esZoneB': t('shippingCostsPage.europeB'),
    'euZoneA': t('shippingCostsPage.europeA'),
    'euZoneB': t('shippingCostsPage.europeB'),
  }

  useEffect(() => {
    getShippingData()
  }, [])

  const getShippingData = () => {
    axios.get(`api/shipping_costs`, {
      headers: {
        Authorization: `bearer ${token}`,
      }
    })
      .then(response => {
        setShippingData(response.data['hydra:member'])
      })
  }

  const renderRows = () => (
    shippingData.map((item, i) => (
      <div key={i} className="table-row">
        <div className="table-row-cell">{originsMap[item.origin]}</div>
        <div className="table-row-cell">{destinationsMap[item.destination]}</div>
        <div className="table-row-cell">{`${(parseFloat(item.cost)/100).toFixed(2)} ${item.origin === 'pl' ? 'zł' : '€'}`}</div>
        <div className="table-row-cell link-cell" onClick={() => setSelectedItem(item)}>{t('shippingCostsPage.editButton')}</div>
      </div>
    ))
  )

  return (
    <div className="shipping-page">
      <div className="content-wrapper-inner">
        <h2>{t('shippingCostsPage.header')}</h2>

        <div className="table">
          <div className="table-header">
            <div className="table-header-cell">{t('shippingCostsPage.versionColumn')}</div>
            <div className="table-header-cell">{t('shippingCostsPage.countryColumn')}</div>
            <div className="table-header-cell">{t('shippingCostsPage.costColumn')}</div>
            <div className="table-header-cell"></div>
          </div>
          { renderRows() }
        </div>
      </div>

      { selectedItem && <EditShippingDataModal closeModalHandler={() => setSelectedItem(null)} itemData={selectedItem} refreshData={getShippingData} /> }
    </div>
  )
}

export default ShippingPage
