import React, { useState } from 'react'
import './ResetPasswordPage.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LanguageSelect from '../../../commons/LanguageSelect/LanguageSelect'
import TextInput from '../../../commons/TextInput/TextInput'
import { validateEmail } from '../../../../utils'
import { default as axios } from '../../../../axiosSettings'
import MetaTags from "../../../commons/MetaTags/MetaTags";


function ResetPasswordPage() {
  const [page, setPage] = useState('enterEmail') // 'enterEmail', 'confirmation'
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const { t } = useTranslation()

  const handleResetPassword = () => {
    const emailError = !validateEmail(email)
    if (!emailError) {
      axios.post(`api/reset-password`, {
        email: email,
      })
        .then(response => {
          if (response.status === 200) {
            setPage('confirmation')
          }
          else {
            setEmailError(t('resetPassword.resetFailedMessage'))
          }
        })
        .catch(() => {
          setEmailError(t('resetPassword.resetFailedMessage'))
        })
    }
    setEmailError(emailError && t('resetPassword.emailValidationMessage'))
  }

  const renderEnterEmailPage = () => <React.Fragment>
    <h1>{t('resetPassword.enterEmailHeader')}</h1>
    <div className="mt24">{t('resetPassword.enterEmailDescription')}</div>
    <div className="input-wrapper">
      <TextInput 
        label={t('resetPassword.emailLabel')}
        value={email}
        error={emailError}
        placeholder={t('resetPassword.emailPlaceholder')}
        onChange={e => setEmail(e.target.value)}
      />
    </div>
    <div className="button-wrapper">
      <button className="button" onClick={handleResetPassword}>{t('resetPassword.resetButton')}</button>
    </div>
  </React.Fragment>

  const renderConfirmationPage = () => <React.Fragment>
    <h1>{t('resetPassword.resetPasswordHeader')}</h1>
    <div className="mt24">{t('resetPassword.resetPasswordDescription')}</div>
    <Link to={window.location.pathname.includes('admin') ? "/admin/login" : "/login"}>{t('resetPassword.loginPageLink')}</Link>
  </React.Fragment>

  return (
    <div className="reset-password-page">

      <MetaTags title="Edikids - reset hasła"/>

      <div className="reset-password-box">
        { window.location.pathname.includes('admin') && <div className="language-dropdown-wrapper">
          <LanguageSelect />
        </div> }
        { page === 'enterEmail' && renderEnterEmailPage() }
        { page === 'confirmation' && renderConfirmationPage() }
      </div>
    </div>
  )
}

export default ResetPasswordPage
