import React, { useState } from 'react'
import './BookDetailsModal.scss'
import closeIcon from '../../../../../../assets/close_icon.svg'
import TextInput from '../../../../../commons/TextInput/TextInput'
import TextareaInput from '../../../../../commons/TextareaInput/TextareaInput'
import SelectInput from '../../../../../commons/SelectInput/SelectInput'
import { useStateValue } from '../../../../../../state/state'
import { coverFontOptions, innerFontOptions } from '../../../../../../consts'
import DragAndDrop from '../../../../../commons/DragAndDrop/DragAndDrop'
import { default as axios } from '../../../../../../axiosSettings'
import { useTranslation } from 'react-i18next'
import {Storage, TOKEN} from "../../../../../../helper/storage/Storage";


function BookDetailsModal({
  closeModalHandler
}) {
  const [{ bookData, marketOptions, printingHouseOptions }, dispatch] = useStateValue()
  const [title, setTitle] = useState(bookData.title)
  const [description, setDescription] = useState(bookData.description)
  const [converFont, setCoverFont] = useState(bookData.converFont)
  const [innerFont, setInnerFont] = useState(bookData.innerFont)
  const [market, setMarket] = useState(bookData.market)
  const [printingHouse, setPrintingHouse] = useState(bookData.printingHouse)
  const [isbn, setIsbn] = useState(bookData.isbn)
  const [mainImage, setMainImage] = useState(bookData.mainImage)
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [printingHouseError, setPrintingHouseError] = useState(false)
  const [isbnError, setIsbnError] = useState(false)
  const [mainImageError, setMainImageError] = useState(false)
  const { t } = useTranslation()
  const token = Storage.get(TOKEN);

  const validateInputs = () => {
    const titleError = !title
    const descriptionError = !description
    const printingHouseError = !printingHouse
    const isbnError = !isbn
    const mainImageError = !mainImage

    if (
      !titleError
      && !descriptionError
      && !printingHouseError
      && !isbnError
      && !mainImageError
    ) {
      dispatch({ type: 'setBookData', field: 'title', data: title })
      dispatch({ type: 'setBookData', field: 'description', data: description })
      dispatch({ type: 'setBookData', field: 'converFont', data: converFont })
      dispatch({ type: 'setBookData', field: 'innerFont', data: innerFont })
      dispatch({ type: 'setBookData', field: 'market', data: market })
      dispatch({ type: 'setBookData', field: 'printingHouse', data: printingHouse })
      dispatch({ type: 'setBookData', field: 'isbn', data: isbn })
      replaceImage(bookData.mainImage.id, mainImage)
      closeModalHandler()
    }
  
    setTitleError(titleError)
    setDescriptionError(descriptionError)
    setPrintingHouseError(printingHouseError)
    setIsbnError(isbnError)
    setMainImageError(mainImageError)
  }

  const replaceImage = (imageId, value) => {
    axios.delete(`api/book_main_images/${imageId}`, { 
      headers: { 
        Authorization: `bearer ${token}`,
      } 
    })
    const formData = new FormData()
    formData.append('file', value)
    axios.post(`api/book_main_images`, formData, { 
      headers: { 
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      } 
    })
      .then(response => {
        if (response.status === 201) {
          dispatch({ type: 'setBookData', field: 'mainImage', data: response.data })
        }
      })
  }
  
  return (
    <div className="book-details-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModalHandler} />
        <h2><center>{t('pagesEditor.editBookDataModalTitle')}</center></h2>

        <div className="input-wrapper">
          <TextInput 
            label={t('addPersopnalizedBookPage.titleLabel')}
            placeholder={t('addPersopnalizedBookPage.titlePlaceholder')}
            value={title}
            error={titleError && t('addPersopnalizedBookPage.titleValidationMessage')}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <TextareaInput 
            label={t('addPersopnalizedBookPage.descriptionLabel')}
            placeholder={t('addPersopnalizedBookPage.descriptionPlaceholder')}
            value={description}
            error={descriptionError && t('addPersopnalizedBookPage.descriptionValidationMessage')}
            maxCharacters={900}
            onChange={e => setDescription(e.target.value)}
          />
        </div>

        <div className="columns-2">
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.coverFontLabel')}
                options={coverFontOptions}
                value={converFont}
                error={null}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => setCoverFont(e.value)}
              />
            </div>
          </div>
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.innerFontLabel')}
                options={innerFontOptions}
                value={innerFont}
                error={null}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => setInnerFont(e.value)}
              />
            </div>
          </div>
        </div>

        <div className="columns-2">
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.verionLabel')}
                options={marketOptions.map(item => ({
                  value: item.value,
                  label: t(`productDetailsPage.${item.label}`),
                }))}
                value={market}
                error={null}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => setMarket(e.value)}
              />
            </div>
          </div>
          <div>
            <div className="input-wrapper">
              <SelectInput 
                label={t('addPersopnalizedBookPage.printingHouseLabel')}
                options={printingHouseOptions}
                value={printingHouse}
                error={printingHouseError && t('addPersopnalizedBookPage.printingHouseValidationMessage')}
                placeholder={t('addPersopnalizedBookPage.selectPlaceholder')}
                onChange={e => setPrintingHouse(e.value)}
              />
            </div>
          </div>
        </div>

        <div className="input-wrapper">
          <TextInput
            label={t('addPersopnalizedBookPage.isbnLabel')}
            placeholder={t('addPersopnalizedBookPage.isbnPlaceholder')}
            value={isbn}
            error={isbnError && t('addPersopnalizedBookPage.isbnValidationMessage')}
            onChange={e => setIsbn(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <DragAndDrop
            label={t('addPersopnalizedBookPage.photosLabel')}
            componentId="book-main-image"
            uploadedFile={mainImage} 
            error={mainImageError && t('addPersopnalizedBookPage.photoValidationMessage')}
            setUploadedFile={setMainImage}
            clearUpload={() => setMainImage(null)}
          />
        </div>

        <div className="button-wrapper mt48">
          <button className="button" onClick={validateInputs}>{t('pagesEditor.editBookDataModalSaveButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default BookDetailsModal
