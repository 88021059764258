import React, { useState, useEffect } from 'react'
import './ShopPage.scss'
import headerImage from '../../../../assets/standard_book_header_image.webp'
import handIcon from '../../../../assets/hand_icon.svg'
import { Link } from 'react-router-dom'
import { default as axios } from '../../../../axiosSettings'
import { serverURL } from '../../../../config'
import { useTranslation } from 'react-i18next'
import TextTruncate from 'react-text-truncate'
import Banner from '../../../commons/Banner/Banner'
import MetaTags from "../../../commons/MetaTags/MetaTags";
import {notifyGTMPageOpened} from "../../../../helper/tracking/notifyGTMPageOpened";
import {notifyGoogleTagManagerEvent} from "../../../../helper/tracking/notifyGoogleTagManagerEvent";


function ShopPage() {
  const [products, setProducts] = useState([])
  const { i18n, t } = useTranslation()

  useEffect(() => {
    getProducts()
  }, [i18n.language])

  const getProducts = () => {
    let url = `api/books?isCustomizable=false&itemsPerPage=99999&status=1&order[id]=desc&market.countryCode=${i18n.language}`
    axios.get(url)
      .then(response => {
        setProducts(response.data['hydra:member'])
      })
  }

  useEffect(()=>{
    notifyGTMPageOpened(i18n.language);
    notifyGoogleTagManagerEvent('sklep');
  }, []);

  return (
    <div className="shop-page">
    <MetaTags
      title="Edikids - sklep"
    />

      <Banner />

      <div className="banner">
        <div className="banner-inner">
          <h1>{t('standardBooksPage.header')}</h1>
        </div>
        <img src={headerImage} alt="header" />
      </div>

      <div className="page-content">
        <div className="products-grid">
          {
            products.map((product, i) => <Link to={`/shop/${product.id}`} className="product" key={i}>
              <div className="left">
                <img src={serverURL + product.bookMainImage.filePath} alt="cover" />
              </div>
              <div className="right">
                <div>
                  <div className="product-title">{product.title}</div>
                  <div className="product-description">
                    <TextTruncate
                      line={5}
                      element="span"
                      truncateText="…"
                      text={product.description}
                    />
                  </div>
                </div>
                <div className="product-price-line">
                  <span className="proudct-price">{(parseFloat(product.price)/100).toFixed(2)} {i18n.language === 'pl' ? 'zł' : '€'}</span>
                  <button className="button rounded">
                    <img src={handIcon} alt="hand" />
                    {t('standardBooksPage.buyButton')}
                  </button>
                </div>
              </div>
            </Link>)
          }
        </div>
      </div>
    </div>
  )
}

export default ShopPage
