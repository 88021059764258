import React from 'react'
import './ChangesSavedModal.scss'
import closeIcon from '../../../../../assets/close_icon.svg'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


function ChangesSavedModal(props) {
  const { closeModalHandler } = props
  const { t } = useTranslation()

  const closeModal = () => {
    closeModalHandler()
    props.history.push('/admin/products')
  }

  return (
    <div className="changes-saved-modal">
      <div className="modal-box">
        <img className="close-icon" src={closeIcon} alt="close" onClick={closeModal} />
        <h2>{t('productDetailsPage.saveModalTitle')}</h2>

        <div className="mt24">{t('productDetailsPage.saveModalText')}</div>

        <div className="button-wrapper mt64">
          <button className="button auto-width" onClick={closeModal}>{t('productDetailsPage.saveModalButton')}</button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ChangesSavedModal)
