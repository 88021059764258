import React, { useState, useEffect, useRef } from 'react'
import './DragAndDrop2.scss'
import { MAX_FILE_SIZE, serverURL } from '../../../config'
import closeIcon from '../../../assets/close_circle_icon.svg'
import plusCircleIcon from '../../../assets/plus_circle_icon.svg'
import { useTranslation } from 'react-i18next'


function DragAndDrop2({
  isMain,
  label,
  componentId,
  uploadedFile,
  setUploadedFile,
  clearUpload,
  error,
}) {
  const [dragActive, setDragActive] = useState(false)
  const [fileUploadWarning, setFileUploadWarning] = useState(null)
  const dropRef = useRef(null)
  let dragCounter = 0
  const { t } = useTranslation()

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragActive(true)
    }
  }
  
  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter--
    if (dragCounter === 0) {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleUpload(e.dataTransfer.files)
      e.dataTransfer.clearData()
      dragCounter = 0    
    }
  }
  
  const handleUpload = (files) => {
    if (files[0] && files[0].name) {
      if (files[0].size > MAX_FILE_SIZE) { // validate image size
        setFileUploadWarning(t('productDetailsPage.photoUploadError'))
      } 
      else if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') { // validate image type
        setFileUploadWarning(t('productDetailsPage.photoUploadError'))
      }
      else {
        const imageToUpload = files[0]
        setUploadedFile(imageToUpload)
        setFileUploadWarning(null)
      }

      if (document.querySelector('#file')) {
        document.querySelector('#file').value = null
      }
    }
  }

  useEffect(() => {
    dropRef.current.addEventListener('dragenter', handleDragIn)
    dropRef.current.addEventListener('dragleave', handleDragOut)
    dropRef.current.addEventListener('dragover', handleDrag)
    dropRef.current.addEventListener('drop', handleDrop)
  
    return () => {
      dropRef.current.removeEventListener('dragenter', handleDragIn)
      dropRef.current.removeEventListener('dragleave', handleDragOut)
      dropRef.current.removeEventListener('dragover', handleDrag)
      dropRef.current.removeEventListener('drop', handleDrop)
    }
  }, [])

  const getImageSrc = () => {
    if (uploadedFile.filePath) {
      return (serverURL + uploadedFile.filePath)
    } else {
      return window.URL.createObjectURL(uploadedFile)
    }
  }

  return (
    <div className="drag-and-drop-2">
      { label && <div className="input-label">{label}</div>}
      <div className={`drag-and-drop-input ${error && 'red-border'}`} ref={dropRef}>
        {
          dragActive && <div className="drop-active-overlay">{t('productDetailsPage.dragAndDropOverlayText')}</div>
        }
        {
          !uploadedFile && <span className="browse-button">
            <input type="file" name={componentId} id={componentId} className="inputfile" accept="image/png, image/jpg, image/jpeg" onChange={e => handleUpload(e.target.files)} />
            <label htmlFor={componentId}>
              { 
                isMain
                  ? <div>{t('productDetailsPage.mainPhotoPlaceholder')}<span className="color-red">*</span></div> 
                  : <img src={plusCircleIcon} alt="plus icon" />
              }
            </label>
          </span>
        }
        {
          uploadedFile && <div className="after-upload-features">
            <img src={getImageSrc()} className="file-preview mr8" alt="file preview" />
            <span className="browse-button">
              <input type="file" name={componentId} id={componentId} className="inputfile" accept="image/png, image/jpg, image/jpeg" onChange={e => handleUpload(e.target.files)} />
            </span>
            <img src={closeIcon} className="close-icon" alt="close icon" onClick={() => {
              clearUpload()
              setFileUploadWarning(null)
              }} />
          </div>
        }
      </div>

      { fileUploadWarning && <div className="input-error">{fileUploadWarning}</div>}
      { error && <div className="input-error">{error}</div>}
    </div>
  )
}

export default DragAndDrop2
