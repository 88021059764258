export function notifyGTMPageOpened(language = null)
{
  if (window.gtag) {
    window.gtag("config", "AW-10822251752", {
      page_title: document.location.pathname,
      page_path: document.location.pathname,
    })

    let googleTag = null;
    switch (language) {
      case 'es':
        googleTag = "G-YB9RL4SGKY";
        break;
      default:
        googleTag = "G-N7LC5G62LB";
    }

    window.gtag("config", googleTag, {
      page_title: document.location.pathname,
      page_path: document.location.pathname,
    })
    
  }
}
