import React, { useState } from 'react'
import './LoginPage.scss'
import { withRouter, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageSelect from '../../../commons/LanguageSelect/LanguageSelect'
import TextInput from '../../../commons/TextInput/TextInput'
import PasswordInput from '../../../commons/PasswordInput/PasswordInput'
import { default as axios } from '../../../../axiosSettings'
import { useStateValue } from '../../../../state/state'
import { REFRESH_TOKEN, Storage, USER_ID} from "../../../../helper/storage/Storage";


function LoginPage(props) {
  const [, dispatch] = useStateValue()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const { t } = useTranslation()

  const handleLogin = () => {
    axios.post(`api/login`, {
      email: email,
      password: password,
    })
      .then(response => {
        if (response.status === 200) {
          Storage.setToken(response.data.token);
          Storage.set(REFRESH_TOKEN, response.data.refresh_token);
          Storage.set(USER_ID, response.data.data.id);
          dispatch({ type: 'setData', field: 'isAdminLoggedIn', data: true })
          if (response.data.data.roles.indexOf('ROLE_ADMIN') !== -1) {
            props.history.push('/admin/orders')
          }
          else if (response.data.data.roles.indexOf('ROLE_PRINTINGHOUSE') !== -1) {
            props.history.push('/admin/packages')
          }
          else if (response.data.data.roles.indexOf('ROLE_CREATOR') !== -1) {
            props.history.push('/admin/products')
          } 
          else {
            props.history.push('/')
          }
        }
        else {
          setLoginError(t('login.loginValidationMessage'))
        }
      })
      .catch(() => {
        setLoginError(t('login.loginValidationMessage'))
      })
  }

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="language-dropdown-wrapper">
          <LanguageSelect />
        </div>

        <h1>{t('login.header')}</h1>

        <div className="input-wrapper">
          <TextInput 
            label={t('login.emailLabel')}
            value={email}
            placeholder={t('login.emailPlaceholder')}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="input-wrapper password-input-wrapper">
          <PasswordInput
            label={t('login.passwordLabel')}
            value={password}
            placeholder={t('login.passwordPlaceholder')}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <div className="link-wrapper">
          <Link to="/admin/reset-password">{t('login.forgotPasswordLink')}</Link>
        </div>

        { loginError && <div className="login-error">{loginError}</div> }

        <div className="button-wrapper">
          <button className="button" onClick={handleLogin}>{t('login.loginButton')}</button>
        </div>
      </div>

    </div>
  )
}

export default withRouter(LoginPage)
