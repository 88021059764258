import React from "react";
import css from './KidsPhotos.module.scss';
import classNames from "classnames";

function KidsPhotos() {
  return (
    <div className={classNames(css.KidsPhotos, css.Grid)}>
      <div className={classNames(css.GridItem, css.Kid, css.Kid1)} />
      <div className={classNames(css.GridItem, css.Kid, css.Kid2)} />
      <div className={classNames(css.GridItem, css.Kid, css.Kid3)} />
      <div className={classNames(css.GridItem, css.Kid, css.Kid4)} />
    </div>
  )
}

export default KidsPhotos;