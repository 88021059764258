import React from "react";
import css from './HowItWorks.module.scss';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

function HowItWorks() {
  const { t } = useTranslation()

  return (
    <div className={css.HowItWorks}>
      <h2 className={css.HowItWorksTitle}>{t('personalizedBooksPage.howItWorksHeader')}</h2>
      <div className={css.Grid}>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.cool)} />
          <div className={css.Description}>{t('personalizedBooksPage.howItWorksStep1')}</div>
        </div>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.wow)} />
          <div className={css.Description}>{t('personalizedBooksPage.howItWorksStep2')}</div>
        </div>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.omg)} />
          <div className={css.Description}>{t('personalizedBooksPage.howItWorksStep3')}</div>
        </div>
        <div className={css.GridItem}>
          <div className={classNames(css.Icon, css.och)} />
          <div className={css.Description}>{t('personalizedBooksPage.howItWorksStep4')}</div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks;