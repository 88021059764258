import {
  detectDomainLanguage,
  SUPPORTED_DOMAINS,
} from "../../i18n-language-detector";
import i18n from "../../i18n";


export function setLanguage(candidate, browserHistory = null) {

  const currentDomainLanguage = detectDomainLanguage();

  if (currentDomainLanguage && candidate && currentDomainLanguage !== candidate) {
    const location = document.location;

    const sourceDomainPart = SUPPORTED_DOMAINS[currentDomainLanguage].filter(part=>location.hostname.endsWith(part))[0];
    const destinationDomainPart = SUPPORTED_DOMAINS[candidate][0];

    const newHostname = location.hostname.replace(new RegExp(`${sourceDomainPart}$`), destinationDomainPart)
    location.hostname = newHostname;
  } else {
    i18n.changeLanguage(candidate);
  }
}