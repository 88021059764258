import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {serverURL} from "../../../config";
import mobileStack1Layer1 from '../../../assets/mobile_stack1_layer1.svg';
import mobileStack1Layer3 from '../../../assets/mobile_stack1_layer3.svg';
import mobileStack2Layer1 from '../../../assets/mobile_stack2_layer1.svg';
import mobileStack2Layer3 from '../../../assets/mobile_stack2_layer3.svg';
import mobileStack3Layer1 from '../../../assets/mobile_stack3_layer1.svg';
import mobileStack3Layer3 from '../../../assets/mobile_stack3_layer3.svg';

import css from './index.module.scss';
import classNames from "classnames";

function SingleBookTile({book, bookIdx})
{
  const { t } = useTranslation()

  const backgroundUrl = `url(${serverURL}${book?.bookMainImage?.webPath ?? book?.bookMainImage?.filePath})`;

  function getCorrectLayer(bookIndex, layers) {
    return `url(${layers[bookIndex % 3]})`;
  }

  return (
    <div className={css.SingleBookTileWrapper}>
      <div className={css.SingleBookTile}>
        <div className={css.Spacer} />
        <div className={css.CoverImage}>
          <div className={classNames(css.stackItem, css.mobileOnly)} style={{backgroundImage: getCorrectLayer(bookIdx, [mobileStack1Layer3, mobileStack2Layer3, mobileStack3Layer3])}} />
          <div className={css.stackItem} style={{backgroundImage: backgroundUrl}} />
          <div className={classNames(css.stackItem, css.mobileOnly)} style={{backgroundImage: getCorrectLayer(bookIdx, [mobileStack1Layer1, mobileStack2Layer1, mobileStack3Layer1])}} />
        </div>
        <div className={css.LinkWrapper}>
          <Link to={`/personalize/${book.id}`} className={css.ProductLink}>
            {t('personalizedBooksPage.personalizeHoverButton')}
          </Link>
        </div>
      </div>
    </div>
  )
}

SingleBookTile.propTypes = {
  book: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    bookMainImage: PropTypes.shape({
      webPath: PropTypes.string,
      filePath: PropTypes.string
    })
  }),
  bookIdx: PropTypes.number.isRequired
}

export default SingleBookTile;
