import React from 'react'
import './PageVersionSelect.scss'
import flagPL from '../../../assets/flag_pl.svg'
import flagES from '../../../assets/flag_es.svg'
import flagEU from '../../../assets/flag_eu.svg'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { useStateValue } from '../../../state/state'
import { withRouter } from 'react-router-dom'
import {PAGE_LANGUAGE, Storage} from "../../../helper/storage/Storage";
import {setLanguage} from "../../../helper/i18n/setLanguage";


const { Option, SingleValue, SelectContainer, Control, IndicatorsContainer } = components

const OptionWithIcon = (props) => (
  <Option {...props} className="react-select-option">
    <img src={props.data.icon} alt="flag" />
    <span className="big-screens">{props.data.label}</span>
  </Option>
)

const SingleValueWithIcon = (props) => (
  <SingleValue {...props} className="react-select-value">
    <img src={props.data.icon} alt="flag" />
    <span className="big-screens">{props.data.label}</span>
  </SingleValue>
)

const CustomIndicatorsContainer = (props) => (
  <div className="custom-indicators-container">
    <IndicatorsContainer {...props} />
  </div>
)

const CustomSelectContainer = (props) => (
  <div className="custom-container">
    <SelectContainer {...props}  />
  </div>
)

const CustomControl = (props) => (
  <div className="custom-control">
    <Control {...props}  />
  </div>
)

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 30,
    border: state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB',
    boxShadow: state.isFocused ? '1px solid #DCDFEB' : '1px solid #DCDFEB',
    '&:hover': {
      borderColor: '#DCDFEB',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: () => null,
}

function PageVersionSelect(props) {
  const { closeMenuHandler } = props
  const [, dispatch] = useStateValue()
  const { i18n, t } = useTranslation()
  
  const options = [
    { value: 'pl', label: t('shopNavbar.pageVersionPL'), icon: flagPL },
    { value: 'es', label: t('shopNavbar.pageVersionES'), icon: flagES },
    { value: 'en', label: t('shopNavbar.pageVersionEN'), icon: flagEU },
  ]

  return (
    <Select 
      options={options}
      components={{
        Option: OptionWithIcon,
        SingleValue: SingleValueWithIcon,
        SelectContainer: CustomSelectContainer,
        Control: CustomControl,
        IndicatorsContainer: CustomIndicatorsContainer,
      }}
      styles={customStyles}
      value={options.find(option => option.value === i18n.language)}
      onChange={e => {
        setLanguage(e.value, props.history)

        dispatch({ type: 'setData', field: 'cart', data: [] })
        sessionStorage.setItem('cart', [])
        props.history.push('/')
        closeMenuHandler()
      }}
    />
  )
}

export default withRouter(PageVersionSelect)