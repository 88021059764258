import React, { useState, useEffect } from 'react'
import './AdminNavbar.scss'
import { Link, withRouter } from 'react-router-dom'
import logoutIcon from '../../assets/logout_icon.svg'
import { useStateValue } from '../../state/state'
import { useTranslation } from 'react-i18next'
import {REFRESH_TOKEN, Storage, TOKEN, USER_ID} from "../../helper/storage/Storage";


function AdminNavbar(props) {
  const [{ userData }, dispatch] = useStateValue()
  const [isError404Page, setIsError404Page] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const { t } = useTranslation()
  const userRole = userData.roles && userData.roles[0]

  useEffect(() => {
    setIsError404Page(document.querySelector('.error-404-page'))
  }, [props.location])

  useEffect(() => {
    setIsAdmin(window.location.pathname.includes('admin'))
  }, [props.location])

  const NavbarLink = ({ to, text, underline }) => <Link 
    to={to} 
    className={underline && window.location.pathname.startsWith(to) ? 'link-active' : ''}>
    {text}
  </Link>

  const NavbarDropdown = ({ text, children }) => <div 
    className={`navbar-dropdown ${(
      window.location.pathname.startsWith('/admin/email-vouchers')
      || window.location.pathname.startsWith('/admin/www-vouchers')
      || window.location.pathname.startsWith('/admin/shipping')
      || window.location.pathname.startsWith('/admin/clients')
      ) ? 'link-active' : ''}`}>
    {text}
    <div className="navbar-dropdown-box">
      {children}
    </div>
  </div>

  const handleLogout = () => {
    Storage.remove(USER_ID);
    Storage.remove(TOKEN);
    Storage.remove(REFRESH_TOKEN);
    dispatch({ type: 'setData', field: 'isAdminLoggedIn', data: false })
    dispatch({ type: 'setData', field: 'userData', data: {} })
    props.history.push('/admin/login')
  }

  if (isError404Page) return null
  if (!isAdmin) return null
  
  return (
    <div className="admin-navbar">
      <div className="navbar-inner">
        <div className="left">
          { userRole === 'ROLE_PRINTINGHOUSE' && <Link to="/admin/packages">{t('adminNavbar.packages')}</Link> }
          { userRole === 'ROLE_ADMIN' && <NavbarLink to="/admin/orders" text={t('adminNavbar.orders')} underline /> }
          { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <NavbarLink to="/admin/products" text={t('adminNavbar.products')} underline /> }
          { userRole === 'ROLE_ADMIN' && <NavbarLink to="/admin/users" text={t('adminNavbar.users')} underline /> }
          { userRole === 'ROLE_ADMIN' && <NavbarDropdown text={t('adminNavbar.management')}>
            <NavbarLink to="/admin/email-vouchers" text={t('adminNavbar.emailCoupons')} />
            <NavbarLink to="/admin/www-vouchers" text={t('adminNavbar.wwwCoupons')} />
            <NavbarLink to="/admin/banner" text={t('adminNavbar.banner')} />
            <NavbarLink to="/admin/shipping" text={t('adminNavbar.shippingCosts')} />
            <NavbarLink to="/admin/clients" text={t('adminNavbar.clients')} />
          </NavbarDropdown> }
        </div>
        <div className="right">
          { (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_CREATOR') && <Link className="link-button" to="/admin/creator">{t('adminNavbar.addBook')}</Link> }
          <span className="mr8">{userData.email}</span>
          <img src={logoutIcon} className="logout-icon" alt="logout icon" onClick={handleLogout} />
        </div>
      </div>

    </div>
  )
}

export default withRouter(AdminNavbar)