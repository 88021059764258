import React, { useState, useEffect } from 'react'
import './Pagination.scss'
import arrowLeftIcon from '../../../assets/arrow_left.svg'
import arrowRightIcon from '../../../assets/arrow_right.svg'


function Pagination(props) {
  const itemsPerPage = props.itemsPerPage || 20
  const pagesCount = Math.ceil(props.totalItemsCount / itemsPerPage)
  const [items, setItems] = useState([])

  useEffect(() => {
    preparePaginationItems()
  }, [props.totalItemsCount, props.currentPageNumber]) // eslint-disable-line

  const preparePaginationItems = () => {
    const n = props.currentPageNumber
    const previousPagesCount = props.currentPageNumber - 1
    const nextPagesCount = Math.ceil(props.totalItemsCount / itemsPerPage) - props.currentPageNumber
    let items = [n-2, n-1, n, n+1, n+2]

    if (pagesCount <= 5) {
      items = [...Array(pagesCount).keys()].map(i => ++i)
    }
    else if (previousPagesCount === 0) {
      items.shift()
      items.shift()
      items.push(n+3, n+4)
    }
    else if (previousPagesCount === 1) {
      items.shift()
      items.push(n+3)
    }
    else if (nextPagesCount === 0) {
      items.pop()
      items.pop()
      items.unshift(n-4, n-3)
    }
    else if (nextPagesCount === 1) {
      items.pop()
      items.unshift(n-3)
    }
    
    if (pagesCount >= 6 && previousPagesCount >= 3) {
      items[0] = '...'
    }
    if (pagesCount >= 6 && nextPagesCount >= 3) {
      items[4] = '...'
    }
    return setItems(items)
  }

  return (
    <div className="pagination-component">
      <img className="pagination-arrow pagination-arrow-left" src={arrowLeftIcon} alt="" onClick={() => props.currentPageNumber > 1 && props.paginationChangeHandler(props.currentPageNumber-1)} />
      {
        items.map((item, i) => {
          const itemClasses = `pagination-item ${item === props.currentPageNumber ? 'pagination-item-selected' : ''} ${item === '...' ? 'pagination-item-inactive' : ''}`
          return <div key={i} className={itemClasses} onClick={() => item !== '...' && props.paginationChangeHandler(item)}>
            {item}
          </div>
        })
      }
      <img className="pagination-arrow pagination-arrow-right" src={arrowRightIcon} alt="" onClick={() => props.currentPageNumber < pagesCount && props.paginationChangeHandler(props.currentPageNumber+1)} />
    </div>
  )
}

export default Pagination